import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types"

class SiteLastInspections extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: []
    };
  }

  componentDidMount() {
  	this.getSiteDetails();
  }

  getSiteDetails(){
  	let site_id = this.props.site_id

  	fetch(`/sites/${site_id}/last_inspections`, {
    	headers: {
    		"Content-Type": "application/json"
    	},
    	credentials: "include"
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          result: result
        });
      },

      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  };
  
  render() {
    const { error, isLoaded, result } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="spiner-wrapper"><div className="spinner"></div></div>;
    } else {
      return (
        <ul className="media-list media-list-hover-border">
          {result.inspections.map(inspection => (
            <li key={inspection.id} className="media">
              <div className="media-left">
                <div className="p-5"><i className="fa fa-building font-size-30"></i> </div>
              </div>
              <div className="media-body">
                <h4 className="media-heading"><a href={"/sites/" + inspection.site_id+ "/inspections/" + inspection.id}>IN 00{inspection.id}</a></h4>
                <small className="date"><i className="glyphicon glyphicon-time"></i> {inspection.created_at}</small>
                <small></small>
              </div>
            </li>
          ))}
          
        </ul>
      );
    }
  }
}

SiteLastInspections.propTypes = {
  site_id: PropTypes.number
};

export default SiteLastInspections;