import React from 'react'
import PropTypes from 'prop-types';

import AssetIssuingHeader from './components/asset_issuing_header';
import AssetIssuingItems from './components/asset_issuing_items';

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assets_issuing: this.props.assets_issuing,
      issuing_items: this.props.issuing_items,
      is_issuing_editable: this.props.assets_issuing.status == "draft" ? true : false,
    };
  }

  render () {
    return (
      <div>
        <div className="panel-body">
          <AssetIssuingHeader 
            {...this.props}
            is_issuing_editable={this.state.is_issuing_editable} />

          <AssetIssuingItems
            {...this.props}
            is_issuing_editable={this.state.is_issuing_editable} />
        </div>
      </div>
   
    );

  }
}

Show.propTypes = {
  assets_issuing: PropTypes.object,
  issuing_items: PropTypes.array,
  assets: PropTypes.array
};

export default Show
