import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types"

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import {Modal, Button, Form} from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';

class ClientSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      inspection_id: this.props.inspection_id,
      client_contact_name: this.props.client_contact_name || "",
      client_contact_job_role: this.props.client_contact_job_role || "",
      client_contact_signature: this.props.client_contact_signature || null,
      client_feedback: this.props.client_feedback || "",
      signatureModelShow: false,
    };

    this.handleSignatureSubmit = this.handleSignatureSubmit.bind(this)
    this.handleSignatureRemove = this.handleSignatureRemove.bind(this)
    this.handleChange       = this.handleChange.bind(this);

  }

  componentDidMount() {
    //this.getSiteDetails();
  }

  sigPad = {}

  handleSignatureModelClose = () => this.setState({signatureModelShow: false })
  handleSignatureModelShow = () => this.setState({signatureModelShow: true })


  handleSignatureSubmit = event => {
    event.preventDefault()

    if(this.sigPad.isEmpty()) {
      toastr.error( "Please sign on the Signature Pad",
                    "Your Signature is required ")

    } else {
      const signatureDataUrl = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
      const formData  = new FormData();

      const { error, 
        isLoaded, 
        inspection_id,
        client_contact_name,
        client_contact_job_role,
        client_contact_signature,
        client_feedback,
      } = this.state;

      formData.append('inspection[client_contact_signature]', signatureDataUrl )
      formData.append('inspection[client_contact_name]', client_contact_name )
      formData.append('inspection[client_contact_job_role]', client_contact_job_role )
      formData.append('inspection[client_feedback]', client_feedback )
      
      formData.append('inspection[client_contact_signed_at]', Date.now() )

      setupCSRFToken();

      axios.put(`/inspections/${inspection_id}/update_signature_details`, formData)
      .then(response => {
        this.setState({ 
          client_contact_signature: signatureDataUrl,
           signatureModelShow: false,
         })
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })
    }

    
  }

  handleSignatureRemove = e => {
    e.preventDefault()

    this.setState({ 
      client_contact_signature: null,
     })

    this.sigPad.clear();
    
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  render() {
    const { error, 
      isLoaded, 
      inspection_id,
      client_contact_name,
      client_contact_job_role,
      client_contact_signature,
      client_feedback,
      signatureModelShow
    } = this.state;
    
    return (
      <React.Fragment>
        <h4 className="panel-title mb-10 text-success">Client Acknowledgement</h4>
        <div className="feedback-wrapper">
          <div className="row pb-20 no-gutters">
            <div className="col-md-8">
              
              <div className="row no-gutters">
                <div className="col-4">
                  <strong>Client Name:</strong>
                </div>

                <div className="col-8">
                  <strong>{client_contact_name}</strong>
                  <p>{client_contact_job_role}</p>

                  <div>{client_feedback}</div>
                </div>
              </div>  

            </div>
            
            <div className="col-md-4">
              <div className="row no-gutters">
                <div className="col-4">
                  <strong>Client Signature:</strong>
                </div>

                <div className="col-8">
                  <div>
                    {client_contact_signature
                    ? <div className="signature-wrapper">
                        <img className="signature" style={{width: '130px', height: 'auto'}}
                          src={client_contact_signature} />

                        {/*<button className="btn btn-primary btn-sm" onClick={this.handleSignatureModelShow}>Edit Details</button>*/}
                      </div>
                    
                    :
                    <Button className="btn btn-primary btn-quirk"
                          onClick={this.handleSignatureModelShow} >Click Here To Sign</Button>
                    }
                  </div>
                </div>
              </div>  
            </div>
          </div>  
        </div>

        <Modal show={signatureModelShow} onHide={this.handleSignatureModelClose}>
          
          <Modal.Header closeButton>
            <Modal.Title>Your Signature</Modal.Title>
  
          </Modal.Header>
          <form onSubmit={this.handleSignatureSubmit}>
            <Modal.Body>

              <div>
                
                
                <div className="form-group mb-5">
                  <label>Client Name (Required)</label>
                  <input type="text"
                          className="form-control"
                          placeholder="Your Name"
                          name="client_contact_name"
                          value={client_contact_name}
                          onChange={this.handleChange}
                          autoFocus={true}
                          required={true} />
                </div>

                <div className="form-group mb-30">
                  <label>Position (Required)</label>
                  <input type="text"
                          className="form-control"
                          name="client_contact_job_role"
                          value={client_contact_job_role}
                          onChange={this.handleChange}
                          placeholder="Facility Manager"
                          required={true} />
                </div>

                <div className="form-group mb-30">
                  <label>Notes</label>
                  <textarea className="form-control h-100"
                          name="client_feedback"
                          value={client_feedback}
                          onChange={this.handleChange} />
                </div>

                <div className="form-group mb-30">
                  <label>Client Signature (Required)</label>
                  <SignaturePad 
                    canvasProps={{className: 'w-p100 h-300 sigCanvas',
                                  style: {background: '#f6f8fa'} }}
                    ref={(ref) => { this.sigPad = ref }} />

                  <a className="mt-10 mb-10" href="#" onClick={this.handleSignatureRemove}>Clear Signature</a>
                </div>
              </div>

            </Modal.Body>
            
            <Modal.Footer>
              <Button className="btn btn-default btn-quirk" onClick={this.handleSignatureModelClose}>
                Cancel
              </Button>
              
              <button
                    className="btn btn-primary btn-quirk" 
                    onClick={this.handleSignatureSubmit}>Save Changes </button>
              
            </Modal.Footer>
            </form>
        
        </Modal>

      </React.Fragment>
    );

  }
}

ClientSignature.propTypes = {
  inspection_id: PropTypes.number,
  client_contact_name: PropTypes.string,
  client_contact_job_role: PropTypes.string,
  client_contact_signature: PropTypes.string,
  client_contact_signed_at: PropTypes.string,
  client_feedback: PropTypes.string
};

export default ClientSignature;