import React, { useState } from 'react';
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

import List from './_list';
import NewTasksMapping from './new'

class TasksMappings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks_template: this.props.selected_tasks_template,
      tasks_mappings: this.props.tasks_mappings,
    };

  }

  updateTasksMappings = async new_tasks_mapping => {
    const tasks_mappings = [...this.state.tasks_mappings, new_tasks_mapping]
    await this.setState({
      tasks_mappings: tasks_mappings
    })
  }

  updateTasksMappingItem = updated_tasks_mapping => {
    const itemIndex = this.state.tasks_mappings.findIndex(tm => tm.id == updated_tasks_mapping.id)
    const tasks_mappings = this.state.tasks_mappings;

    tasks_mappings[itemIndex] = updated_tasks_mapping
    
    this.setState({
      tasks_mappings: tasks_mappings,
    })

  }

  unSetTasksMappingItem = tasks_mapping => {
    const updated_tasks_mappings = this.state.tasks_mappings.filter(tt => {
      return tt.id !== tasks_mapping.id
    })

    this.setState({
      tasks_mappings: updated_tasks_mappings
    })
  }

  render(){
    const {
      site_id,
    } = this.props

    const {
      tasks_template,
      tasks_mappings,
    } = this.state

  	return(
	  	<React.Fragment>
        <div className="tasks-mappings-wrapper">
			    <h4 className="panel-title mb20">Allocated Tasks</h4>
			    
          <div className="tasks-wrapper">
            <List 
              tasks_mappings={tasks_mappings}
              updateTasksMappingItem={this.updateTasksMappingItem}
              unSetTasksMappingItem={this.unSetTasksMappingItem} />
            
            <NewTasksMapping 
              {...this.props}
              updateTasksMappings={this.updateTasksMappings} />
            
          </div>
			  </div>
      </React.Fragment>
	  )
  }
}

export default TasksMappings;


