import React, { useState, useEffect } from 'react';
import { 
  BrowserRouter as Router,
  Route,
  NavLink,
  Switch
} from 'react-router-dom'

import _ from "underscore";
import moment from "moment";
import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import shiftIconDefault from "assets/images/icons/shift-icon-default.jpeg"

import ShiftPayDetails from "../components/shift_pay_details";
import ShiftStaffDetails from "../components/shift_staff_details";

const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

const ShiftShow = (props) => {
  // let {site_id} = useParams();

  //const query_params = useState(queryString.parse(props.location.search))
  const [shift_id, setShiftId]    = useState(props.match.params.shift_id)
  const [shift, setShift]         = useState({})
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchShift();
  }, []);

  const fetchShift = () => {
    
    axios.get(`/shifts/${shift_id}.json`)
      .then(response => {
        setShift(response.data.shift)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error.message)
        setIsLoading(false)
      })
  }

 

  if(isLoading) {
    return "Loading..."
  } else {
    return (
      <React.Fragment>
        <h4 className="panel-title mb10">Shift Details</h4>

        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div className="panel h-min-500">
              <div className="panel-heading d-flex justify-content-between">
                <h4 className="panel-title">Shift Details</h4> 
                <a href={`/shifts/${shift_id}/edit`} className="btn btn-default">Edit Shift Details</a>
              </div>

              <div className="panel-body pt-0">
                <div className="row pb-3">
                  <div className="col-md-6">
                    <div className="d-flex">
                      <div>
                        <div className="text-center img-thumb-box align-middle w-80 h-80 rounded overflow-hidden mr-10">
                          <img src={shiftIconDefault} className="img-thumb d-block w-p100" />
                        </div>
                      </div>
                      <div>
                        <h2 className="mt-0 mb-10 p-0 font-weight-500">{shift.name}</h2>
                        <p className="m-0 p-0"><strong>Site: </strong> <a href="#" className="">{shift.site.name}</a></p>
                        
                        <span className="label label-default">Open</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col border-end">
                        <h4 className="font-size-6 font-weight-500">Commencement Date</h4>
                        <p>{moment(shift.commencement_date).format('Do MMMM YYYY')}</p>
                      </div>

                      <div className="col border-end">
                        <h4 className="font-size-6 font-weight-500">Total hours per week</h4>
                        <p>{shift.total_hours_per_week ? shift.total_hours_per_week : "NA"}</p>
                      </div>

                      <div className="col">
                        <h4 className="font-size-6 font-weight-500">Frequency</h4>
                        <div className="info-group">
                          <div className="tasks-item-row">
                            <div className="task-details d-flex h-20">
                              {weekDays.map( (day, index) => {
                                return <span className={`task-frequency ${_.contains(shift.recurring_days, day) ? 'active' : ''}`} key={index}>{day.substring(0, 2)}</span>
                                } )}  
                            
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>

                <section className="mt-30">
                  <h4 className="font-size-16 font-weight-600">Description</h4>
                  {shift.description == "" ? <p className="text-muted">Not Available</p> : shift.description }
                </section>

                <section className=" mt-20 mb-30">
                  <div className="mb-30">
                    <NavLink exact to={`/shifts/${shift.hashid}`} 
                            className={`btn btn-default mr-5`}
                            activeClassName="btn-primary">Shift Details</NavLink>

                    <NavLink exact to={`/shifts/${shift.hashid}/staff_details`} 
                            className={`btn btn-default mr-5`}
                            activeClassName="btn-primary">Staff Allocation</NavLink>
                  </div>

                  <Route exact path="/shifts/:shift_id" component={() => <ShiftPayDetails shift={shift} />} />
                  <Route exact path="/shifts/:shift_id/staff_details" component={ () => <ShiftStaffDetails shift={shift} /> } />
                </section>
                

              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ShiftShow