import React from 'react'

import { BounceLoader } from 'react-spinners';

import InspectableTasksMappingItem from './inspectable_tasks_mapping_item';

const InspectableTasksMappings = props => {

	const {
  		inspection,
  		query_params,
      selected_tasks_mapping_item,
  		handleSubSectionItemClick,
  		updateSelectedSubSection,
      handleTasksMappingModelShow,
      is_loading
  	} = props

  const { inspectable_tasks_mappings } = inspection


  if (is_loading){
    return <div></div>
  }

	return (
    <React.Fragment>
      <div className="panel-tasks">
        <div className="panel-heading">
          <h4>Allocated Tasks</h4>
        </div>

        <div className="panel-body">
          <div className="tasks-wrapper">

            <ul className="list-group list-tasks-mappings list-group-striped">
          
              {inspectable_tasks_mappings.map((inspectable_tasks_mapping, index) => (
                
                  <InspectableTasksMappingItem 
                    inspectable_tasks_mapping={inspectable_tasks_mapping}
                    handleTasksMappingModelShow={handleTasksMappingModelShow}
                    key={index} />
                  
                ))
              }
              

            </ul>

          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

export default InspectableTasksMappings;