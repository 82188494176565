import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

import AttachmentIcon from 'assets/images/icons/attachment_icon.png'

class DocumentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document: this.props.document,
      work_order: this.props.work_order,
      current_user_id: this.props.current_user_id,
      isAttachmentRemoved: false,
    };

    this.removeDocument = this.removeDocument.bind(this);
  }

  removeDocument = () => {

    if(window.confirm('Are you sure want to delete the item?')){
      const document = this.state.document

      setupCSRFToken();

      axios.delete(`/documents/${document.id}`,)
      .then(response => {
        this.setState({ isAttachmentRemoved: true })

        //this.props.unSetAttachment(attachment.id)

        toastr.success("The attachment has been deleted successfully!")
          
      })
      .catch(error => {
        console.log("error catched")
        console.log(error.response)

        toastr.error("An error occurred during the process")
      }) 
    }
  }


  render () {
    const {work_order, document, isAttachmentRemoved} = this.state

    if (isAttachmentRemoved) {
      return ""
    }
    else {
      return(
        <div className="comment-attachments-column col-xs-3 col-md-2">
          <div className="comment-attachment-wrapper" style={{border: '1px solid #dbdfe7'}}>
            <div>
            	<a href={`/documents/${document.id}`} target="_blank">
            		<img src={AttachmentIcon} alt="Attachmented File"/>
            	</a>
            </div>
            {work_order.status === "draft" &&
              <span className="image-remove-icon fa fa-close" onClick={this.removeDocument}></span>
            }
            <div style={Styles.documentLabel}> 
              <span>{document.name}</span>
            </div>
          </div>
        </div>
      )
    }
    
  }
}

const Styles = {
  documentLabel: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: '#f6f8fa',
    fontSize: '10px',
    textAlign: 'center',
    padding: '3px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}

export default DocumentItem
