import {
	SET_INSPECTION,
	SET_INSPECTABLE_SECTIONS,
	SET_INSPECTABLE_SUB_SECTIONS,
	SET_INSPECTABLE_TASKS_MAPPINGS,

  SET_SELECTED_INSPECTABLE_SECTION,
  REMOVE_SELECTED_INSPECTABLE_SECTION,

  SET_SELECTED_INSPECTABLE_SUB_SECTION,
  REMOVE_SELECTED_INSPECTABLE_SUB_SECTION,

  UPDATE_INSPECTABLE_TASKS_MAPPINGS

} from './constants';

const initialState = {
	inspection: {
		id: null, 
		site_id: null, 
		status: "scheduled", 
		scheduled_date: null, 
		finished_date: null, 
		inspection_type: null, 
		created_by: null, 
		note: null, 
		created_at: null, 
		updated_at: null, 
		company_id: null, 
		user_id: null, 
		current_step: null, 
		participants: {}, 
		inspector_feedback: null, 
		client_feedback: null, 
		acknowledged_by: null, 
		completed_by: null, 
		deleted_at: null, 
		slug: null, 
		started_at: null,
		inspectable_sections: [],
		inspectable_sub_sections: [],
		inspectable_tasks_mappings: [],
    selected_inspectable_section: {},
    selected_inspectable_sub_section: {}
	}

}

const inspectionReducer = ( state= initialState, action) => {
  switch(action.type){
  	case SET_INSPECTION:
  		return {
  			...state,
  			inspection: {
          ...state.inspection,
          ...action.inspection,
        }
  		}

    case SET_INSPECTABLE_SECTIONS:
      return {
      	...state,
      	inspection: {
      		...state.inspection,
      		inspectable_sections: action.inspectable_sections
      	}
      	
      }

    case SET_INSPECTABLE_SUB_SECTIONS:
    	return {
    		...state,
    		inspection: {
    			...state.inspection,
    			inspectable_sub_sections: action.inspectable_sub_sections,
    			
    		}
    	}

    case SET_INSPECTABLE_TASKS_MAPPINGS:
    	return {
    		...state,
    		inspection: {
    			...state.inspection,
    			inspectable_tasks_mappings: action.inspectable_tasks_mappings,
    			
    		}
    	}

    case SET_SELECTED_INSPECTABLE_SECTION:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          selected_inspectable_section: action.selected_inspectable_section,
          
        }
      }

    case SET_SELECTED_INSPECTABLE_SUB_SECTION:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          selected_inspectable_sub_section: action.selected_inspectable_sub_section,
          
        }
      }

    case REMOVE_SELECTED_INSPECTABLE_SECTION:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          selected_inspectable_section: {},
          
        }
      }

    case REMOVE_SELECTED_INSPECTABLE_SUB_SECTION:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          selected_inspectable_sub_section: {},
          
        }
      }

    case UPDATE_INSPECTABLE_TASKS_MAPPINGS:
      return {
        ...state,
        inspection: {
          ...state.inspection,
          inspectable_tasks_mappings: action.inspectable_tasks_mappings
        }
      }

    default:
      return state
  }
}

export default inspectionReducer;