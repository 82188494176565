import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import AssignedSites from './components/_assigned_sites'

class StaffAssignedSites extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      designation: {},
      user: {},
      site_designations: [],
    };
    
  }

  componentDidMount = async () => {
  	await this.getUserDetails();
  }

  getUserDetails = () => {
    const desig_id = this.props.match.params.desig_id
    
    if (desig_id != undefined){
      axios.get(`/staffs/${desig_id}/assigned_sites.json`)
      .then(response => {

        this.setState({
        	designation: response.data.designation,
          user: response.data.user,
          site_designations: response.data.site_designations,
          is_loading: false,
        })
        
      })
      .catch(error => {
        console.error(error.message)
        this.setState({
          is_loading: false,
        })
      })
    }

  }

  

  render() {

  	const {
  		is_loading,
  		user,
  		designation,
  		site_designations,
  	} = this.state

  	if (is_loading ) {
  		return "Loading..."
  	}
  	else {
  		return (
	  		<React.Fragment>
				
					<AssignedSites 
						{...this.props}
						site_designations={site_designations} />
						
	  		</React.Fragment>
	  	)
  	}

  }
}


export default StaffAssignedSites;
