const SET_INSPECTION = 'SET_INSPECTION';
const SET_INSPECTABLE_SECTIONS = 'SET_INSPECTABLE_SECTIONS';
const SET_INSPECTABLE_SUB_SECTIONS = 'SET_INSPECTABLE_SUB_SECTIONS';
const SET_INSPECTABLE_TASKS_MAPPINGS = 'SET_INSPECTABLE_TASKS_MAPPINGS';

const SET_SELECTED_INSPECTABLE_SECTION = 'SET_SELECTED_INSPECTABLE_SECTION';
const REMOVE_SELECTED_INSPECTABLE_SECTION = 'SET_SELECTED_INSPECTABLE_SECTION';

const SET_SELECTED_INSPECTABLE_SUB_SECTION = 'SET_SELECTED_INSPECTABLE_SUB_SECTION';
const REMOVE_SELECTED_INSPECTABLE_SUB_SECTION = 'REMOVE_SELECTED_INSPECTABLE_SUB_SECTION';

const UPDATE_INSPECTABLE_TASKS_MAPPINGS = 'UPDATE_INSPECTABLE_TASKS_MAPPINGS';

export {
	SET_INSPECTION,
	SET_INSPECTABLE_SECTIONS,
	SET_INSPECTABLE_SUB_SECTIONS,
	SET_INSPECTABLE_TASKS_MAPPINGS,

	SET_SELECTED_INSPECTABLE_SECTION,
	REMOVE_SELECTED_INSPECTABLE_SECTION,

	SET_SELECTED_INSPECTABLE_SUB_SECTION,
	REMOVE_SELECTED_INSPECTABLE_SUB_SECTION,

	UPDATE_INSPECTABLE_TASKS_MAPPINGS
}