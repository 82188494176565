import React, {useState, useEffect} from "react";

const ClientAcknowledgementFormPartial = ({
  was_client_presented,
  have_you_met_client,
  reason_not_met_client,
  client_contact_name,
  handleChange
}) => {

  // const [was_client_presented, set_was_client_presented] = useState(was_client_presented)
  // const [have_you_met_client, set_have_you_met_client] = useState(have_you_met_client)
  // const [reason_not_met_client, set_reason_not_met_client] = useState(reason_not_met_client)
  // const [client_contact_name, set_client_contact_name] = useState(client_contact_name)

  // const handleClientPresentedStatusChange = event => {

  //   handleChange({
  //     target: {
  //       name: event.target.name,
  //       value: true,
  //     },
  //   })

  // }

  // const handleHaveYouMetTheClient = event => {

  //   if(event.target.value == true) {
  //     set_have_you_met_client(true)
   
  //   } else {
  //     set_have_you_met_client(false)
  //   }
  // }

  return (
    <div className="bg-blue-grey-100 p-10 mb-30">
      <h4>Client Acknowledgement</h4>
      
      <div className="form-group mb-30" >
        <label className="form-control-label">Was the Client presented for the Audit?</label>

        <div className="form-check">
          
          <label className="form-check-label">
            <input className="form-check-input mr-10" 
                  type="radio" 
                  name="was_client_presented"
                  value={true}
                  checked={was_client_presented ? "checked" : ''}
                  onChange={(e) => {
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: true,
                              },
                            });
                          }} />
            Yes
          </label>
        </div>
        <div className="form-check">
          
          <label className="form-check-label">
            <input className="form-check-input mr-10" 
                  type="radio" 
                  value={false}
                  checked={was_client_presented ? '' : 'checked'}
                  name="was_client_presented"
                  onChange={(e) => {
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: false,
                              },
                            });
                          }} />
            No
          </label>
        </div>
      </div>

      {was_client_presented == true ? 
        <IfPresentedForm handleChange={handleChange}
                        client_contact_name={client_contact_name} /> 
        : 
        <IfNotPresentedForm have_you_met_client={have_you_met_client} 
                            handleChange={handleChange}
                            client_contact_name={client_contact_name}
                            reason_not_met_client={reason_not_met_client}/>
      }

    </div>
  )
  
}

const IfPresentedForm = ({
  handleChange,
  client_contact_name
}) => {

  return (
    <div className="form-group" >
      <label className="form-control-label">Name of the Client</label>

      <input className="form-control" 
        name="client_contact_name"
        value={client_contact_name}
        onChange={handleChange} />

    </div>
  )
}

const IfNotPresentedForm = ({
  have_you_met_client,
  handleChange,
  client_contact_name,
  reason_not_met_client
}) => {
  return (
    <>
      <div className="form-group" >
        <label className="form-control-label">Have you met the client?</label>

        <div className="form-check">
          
          <label className="form-check-label">
            <input className="form-check-input mr-10" 
                  type="radio" 
                  name="have_you_met_client"
                  value={true}
                  checked={have_you_met_client ? 'checked' : ''}
                  onChange={(e) => {
                              handleChange({
                                target: {
                                  name: e.target.name,
                                  value: true,
                                },
                              });
                            }} />
            Yes
          </label>
        </div>
        <div className="form-check">
          
          <label className="form-check-label">
            <input className="form-check-input mr-10" 
                  type="radio" 
                  name="have_you_met_client"
                  value={false}
                  checked={have_you_met_client ? '' : 'checked'}
                  onChange={(e) => {
                              handleChange({
                                target: {
                                  name: e.target.name,
                                  value: false,
                                },
                              });
                            }} />
            No
          </label>
        </div>
      </div>

      {have_you_met_client == true ?
        <div className="form-group" >
          <label className="form-control-label">Name of the Client</label>

          <input className="form-control" 
            name="client_contact_name"
            value={client_contact_name}
            onChange={handleChange} />

        </div>
      :
        <div className="form-group" >
          <label className="form-control-label">Please give a reason for not meeting the Client.</label>

          <textarea 
            className="form-control h-min-100" 
            name="reason_not_met_client"
            value={reason_not_met_client}
            onChange={handleChange}></textarea>

        </div>
      }
    </>
  )
}

export default ClientAcknowledgementFormPartial;