import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Specifications from 'pages/sites/specifications/Specifications'
import SiteSubSections from 'pages/sites/specifications/SiteSubSections'

import TasksMappings from 'pages/sites/tasks_mappings/TasksMappings'

import TasksTemplates from 'pages/tasks_templates/index'

import OHSAudits from 'pages/ohs_audits'

import WorkOrder from 'pages/work_orders'

//Inspections
import Inspection from 'pages/inspections/wip'

//Time Trackings
import SiteTimeTrackings from 'pages/sites/time_trackings/'

import Dashboard from 'pages/dashboards'

//Scheduled Jobs
import ScheduledJobs from 'pages/scheduled_jobs'

import Staffs from 'pages/users'
import AccountRegistration from 'pages/users/sign_up'


//Shifts
import ShiftNew from "pages/shifts/new"
import ShiftEdit from "pages/shifts/edit"
import ShiftShow from "pages/shifts/show"
import ShiftStaffAllocation from "pages/shifts/staff_allocation"

const RouterMain = props => (
  <main>
  	<Switch>
      <Route exact path="/sites/:site_id/sections" component={Specifications} /> 
      <Route exact path="/sections/:section_id/sub_sections" component={SiteSubSections} /> 

      <Route exact path="/sub_sections/:sub_section_id" component={TasksMappings} /> 

      //Inspections
      <Route exact path="/inspections/:inspection_id/wip" component={Inspection} /> 

      //Tasks Templates
      <Route exact path="/sites/:site_id/tasks_templates" component={TasksTemplates} /> 
      <Route path="/tasks_templates/:tasks_template_id" component={TasksTemplates} /> 

      //OHS Quality Audits
      <Route exact path="/ohs_audits/:ohs_audit_id/edit" component={OHSAudits} /> 

      //Work Orders
      <Route exact path="/work_orders/:work_order_id" component={WorkOrder} /> 

      //Ttme Trackings
      <Route path="/sites/:site_id/trackings" component={SiteTimeTrackings} /> 

      //Users
      <Route path="/staffs" component={Staffs} /> 
      <Route path="/administrator/employees" component={Staffs} /> 

      //Dashboard
      <Route exact path="/" component={Dashboard} /> 

      //Scheduled Jobs
      <Route path="/scheduled_jobs" component={ScheduledJobs} /> 
    

      //Account Registration
      <Route exact path="/sign_up" component={AccountRegistration} /> 

      //Shifts
      <Route exact path="/shifts/new" component={ShiftNew} /> 
      <Route exact path="/shifts/:shift_id/edit" component={ShiftEdit} /> 
      <Route exact path="/shifts/:shift_id" component={ShiftShow} /> 
      <Route path="/shifts/:shift_id/staff_details" component={ShiftShow} /> 

  	</Switch>
  </main>
)

export default RouterMain
