import React from 'react'
import PropTypes from 'prop-types';

class ToolboxMeetingAssignees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: this.props.site,
      client: this.props.client,
      toolbox_meeting: this.props.toolbox_meeting,
      comments: this.props.comments,
    };
  }

  render () {
    return (
      <div>
        dd
      </div>
   
    );

  }
}

export default ToolboxMeetingAssignees
