import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import Select from 'react-select'

class AssignSitePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      current_stage: this.props.current_stage,
      designation: this.props.designation,
      user: this.props.user,
    };
    
  }

  componentDidMount = () => {
  	//this.getOhsAudits();
  }

  // getOhsAudits = () => {
  //   const ohs_audit_id = this.props.match.params.ohs_audit_id
    
  //   if (ohs_audit_id != undefined){
  //     axios.get(`/ohs_audits/${ohs_audit_id}/edit.json`)
  //     .then(response => {
  //       this.setState({
  //         ohs_audit: response.data.ohs_audit,
  //         site: response.data.site,
  //         client: response.data.client,
  //         ohs_auditable_items: response.data.ohs_auditable_items,
  //         is_loading: false,
  //       })
        
  //     })
  //     .catch(error => {
  //       console.error(error.message)
  //       this.setState({
  //         is_loading: false,
  //       })
  //     })
  //   }

  // }

  render() {
    const {
      current_stage,
      user,
      designation
    } = this.state

    const options = [
      { value: 'chocolate', label: 'Chocolate<br>This is second line' },
      { value: 'strawberry', label: 'Strawberry' },
      { value: 'vanilla', label: 'Vanilla' }
    ]


    if (this.props.current_stage == "assign_sites"){
    	return (
    		<React.Fragment>
    			<hr className="m-0" />
  				<div className="panel-heading active">
  					<div className="d-flex">
  						<span className="icon-button font-weight-bold mt-5">4</span>
  						<div className="ml-10">
  							<h3 className="m-0">Assign Site</h3>
  							<p>This is optional at this stage. You can do this later</p>
  						</div>
  					</div>
  				</div>

          <div>
            <Select options={options} />
          </div>
    		</React.Fragment>
    	)
    } else {
      return(
        <React.Fragment>
          <hr className="m-0" />
          <div className="panel-heading disabled">
            <div className="d-flex">
              <span className="icon-button font-weight-bold mt-5">4</span>
              <div className="ml-10">
                <h3 className="m-0">Assign Site</h3>
                <p>This is optional at this stage. You can do this later</p>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  
    
  }
}


export default AssignSitePanel;
