import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'

import _ from 'underscore'
import Select from 'react-select'

class TasksMappingQuickForm extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	error: null,
    	sub_section_id: this.props.sub_section_id,
    	isFormSubmitEnabled: false,
    	task_name_validation: null,
    	selectedFrequencyType: null,
    	result: [],
      //site_id: parseInt(this.props.match.params.site_id),
    };

  }

  componentDidMount() {
  	
  }

  onChangeTaskName = (e) => {
  	const value = e.target.value

  	if (value.length > 0 ){
	  	this.setState({
	  		isFormSubmitEnabled: true,
	  		task_name_validation: null,
	  	})
	  }else{
	  	this.setState({
	  		isFormSubmitEnabled: false,
	  		task_name_validation: 'Task name is required'
	  	})
	  }
  }

  onTaskFrequencyTypeChange = (selectedOption) => {
  	this.setState ({
  		selectedFrequencyType: selectedOption
  	})
  }

  handleSubmit = (e) => {
  	e.preventDefault();

  	const form = e.target
  	
  	const formData = new FormData(form);
  	const token = document.querySelector('meta[name="csrf-token"]');

  	fetch(`/sub_sections/${this.state.sub_section_id}/tasks_mappings`, {
      method: 'post',
      body : formData,
     	headers: {
        'X-CSRF-TOKEN': token.content 
      },
      credentials: 'include'
    })
    .then(res => res.json())
    .then(
      (result) => {
        window.location.reload()
      },

      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  render() {
  	const { sub_section_id, is_task_template, tm} = this.props
  	const frequency_types = [
                              { value: 'daily', label: 'Daily' },
                              { value: 'weekly', label: 'Weekly' },
                              { value: 'monthly', label: 'Monthly' },
                              { value: 'termly', label: 'Termly'},
                              { value: 'as_required', label: 'As Required'}
                            ]; 

  	return(
  		<div className="task-item-row">
        <div className="new-task-form">
          <h4 style={{marginLeft: '15px'}}>New Task</h4>

          <form onSubmit={this.handleSubmit} id="tasks-mapping-quick-form">
		        
		        <input value={sub_section_id} type="hidden" name="tasks_mapping[sub_section_id]" />
            <div className="col-md-10 form-group">
              <label>Task Name</label>
              <input type="text" 
              				name="tasks_mapping[task_name]" 
              				className="form-control" 
              				value={tm.task_name}
              				onChange={this.onChangeTaskName} />
              <p className="text-danger">{this.state.task_name_validation}</p>
            </div>
            <div className="col-md-12  form-group">
              <div className="row">
                <div className="col-md-4">
                  <label>Task Frequency Type</label>
                  <Select name="tasks_mapping[frequency_type]"
                          options={frequency_types}
                          value={this.state.selectedFrequencyType}
                          onChange={this.onTaskFrequencyTypeChange}
                        />
                </div>
                <div className="col-md-8">
                  <label>Frequency</label>
                      <div className="d-flex">
                        <div>
                          <span className={`task-frequency day-select w-30 
                                            ${tm.frequencies && tm.frequencies.sunday == '1' ? 'active' : ''}`}>SU</span>
                          <input type="hidden" 
                                  name="tasks_mapping[frequencies][sunday]"
                                  value={tm.frequencies && tm.frequencies.sunday == '1' ? 1 : 0}
                                  />
                        </div>
                        <div>
                          <span className={`task-frequency day-select w-30 
                                            ${tm.frequencies && tm.frequencies.monday == '1' ? 'active' : ''}`}>MO</span>
                          <input type="hidden" 
                                  name="tasks_mapping[frequencies][monday]"
                                  value={tm.frequencies && tm.frequencies.monday == '1' ? 1 : 0}
                                  />
                        </div>
                        <div>
                          <span className={`task-frequency day-select w-30 
                                            ${tm.frequencies && tm.frequencies.tuesday == '1' ? 'active' : ''}`}>TU</span>
                          <input type="hidden" 
                                  name="tasks_mapping[frequencies][tuesday]"
                                  value={tm.frequencies && tm.frequencies.tuesday == '1' ? 1 : 0}
                                  />
                        </div>
                        <div>
                          <span className={`task-frequency day-select w-30 
                                            ${tm.frequencies && tm.frequencies.wednesday == '1' ? 'active' : ''}`}>WE</span>
                          <input type="hidden" 
                                  name="tasks_mapping[frequencies][wednesday]"
                                  value={tm.frequencies && tm.frequencies.wednesday == '1' ? 1 : 0}
                                  />
                        </div>
                        <div>
                          <span className={`task-frequency day-select w-30 
                                            ${tm.frequencies && tm.frequencies.thursday == '1' ? 'active' : ''}`}>TH</span>
                          <input type="hidden" 
                                  name="tasks_mapping[frequencies][thursday]"
                                  value={tm.frequencies && tm.frequencies.thursday == '1' ? 1 : 0}
                                  />
                        </div>
                        <div>
                          <span className={`task-frequency day-select w-30 
                                            ${tm.frequencies && tm.frequencies.friday == '1' ? 'active' : ''}`}>FR</span>
                          <input type="hidden" 
                                  name="tasks_mapping[frequencies][friday]"
                                  value={tm.frequencies && tm.frequencies.friday == '1' ? 1 : 0} 
                                  />
                        </div>
                        <div>
                          <span className={`task-frequency day-select w-30 
                                            ${tm.frequencies && tm.frequencies.saturday == '1' ? 'active' : ''}`}>SA</span>
                          <input type="hidden" 
                                  name="tasks_mapping[frequencies][saturday]"
                                  value={tm.frequencies && tm.frequencies.saturday == '1' ? 1 : 0}
                                   />
                        </div>
                      </div>
                </div>
              </div>
            </div>
            

		      </form>
        </div>
      </div>
  		
  	)
  }
}

TasksMappingQuickForm.propTypes = {
  is_tasks_template: PropTypes.bool,
  sub_section_id: PropTypes.string,
};

export default TasksMappingQuickForm