import React, { useState } from 'react';

import _ from "underscore";
import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';


import DatePicker from "react-datepicker";
import ShiftForm from "../components/shift_form"

const ShiftNew = (props) => {
  // let {site_id} = useParams();

  const query_params = useState(queryString.parse(props.location.search))
  const shift_default = {
    site_id: query_params[0].site_id,
    contract_id: "",
    name: "",
    description: "",
    commencement_date: new Date(),
    status: "open",
    recurring: {},
    total_hours_per_week: ""
  }

  const [shifts, setShifts]         = useState([])
  const [formStatus, setFormStatus] = useState("new")
  const [noOfShifts, setNoOfShifts] = useState(1)

  const submitShiftForm = (shift, slots) => {

    setupCSRFToken();
    setFormStatus("processing")
   
    let createdShifts = []

    _.times(slots, ()=> {

      axios.post(`/shifts/`, {shift: shift})
        .then(response => {
          
          createdShifts.push(response.data.shift)

          setShifts(createdShifts)

          toastr.success( "The shift has been updated successfully")

        })
        .catch(error => {
          console.log("error catched")
          console.log(error)

          toastr.error( "Please check the inputs and submit the form again.",
                        "An error occurred during the process")
     
        })
    });

    setFormStatus("submitted")

    //window.location.href = `/shifts/?site_id=${shift_default.site_id}`
    

    //self.setState({ is_submit_loading: false })
  }

  if (formStatus == "processing") {
    return (
      <div className="page-body">
        <h3>We're processing your request...</h3>
        
      </div>
    )
  } else if (formStatus == "submitted") {
    return (
      <div className="page-body">
        <h3>Following shifts have been created</h3>
        <table className="table table-default table-striped table-hover">
          <thead>
            <tr>
              <th>Shift Name </th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {shifts.map( (shiftItem, index) => <ShiftItemSummary shiftItem={shiftItem} key={index}/> )}
          </tbody>
        </table>

        <div className="mt-50">
          <a className="btn btn-default mr-5" href={`/shifts?site_id=${shift_default.site_id}`}>Go to All Shifts</a>
          <a className="btn btn-primary" href={`/shifts/new?site_id=${shift_default.site_id}`}>Create New Shift</a>
        </div>
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <ShiftForm shiftItem={shift_default}
                    site_id={shift_default.site_id}
                    isEdit={false}
                    submitShiftForm={submitShiftForm} />
      </React.Fragment>
    );
  };
}

const ShiftItemSummary = ({
  shiftItem
}) => {

  return(
    <tr>
      <td>{shiftItem.name}</td>
      <td><a href={`/shifts/${shiftItem.hashid}`} className="btn btn-sm btn-default">View More</a></td>
    </tr>
  )
}
  

export default ShiftNew