import React from 'react'
import { render } from 'react-dom'

class MyComp extends React.Component {
  render () {
    return (
      <div>
      	Hello
      </div>
    );
  }
}

export default MyComp
