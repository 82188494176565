import React from 'react'
import PropTypes from 'prop-types';

import SiteVistReportHeader from './components/site_visit_report_header'
import SiteVisitComments from './components/site_visit_comments'

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: this.props.site,
      client: this.props.client,
      site_visit: this.props.site_visit,
      comments: this.props.comments,
      auditor: this.props.auditor
    };
  }

  render () {
    return (
      <div>
        <div className="panel-body">
          <SiteVistReportHeader 
            {...this.props} />
        </div>
        <hr />

        <div className="panel-body">
          <SiteVisitComments
            {...this.props} />
        </div>
      </div>
   
    );

  }
}

Show.propTypes = {
  site: PropTypes.object,
  site_visit: PropTypes.object,
  client: PropTypes.object
};

export default Show
