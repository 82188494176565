import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

class ReportHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHeaderEditable: false,
      ohs_audit: this.props.ohs_audit,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleTitleEditable = this.toggleTitleEditable.bind(this)
    this.resetForm = this.resetForm.bind(this)
    this.baseState = this.state
  }

  resetForm = e => {
    this.setState(this.baseState)

    this.toggleTitleEditable(e)
  }

  handleChange = event => {
    this.setState({
      ohs_audit: {
        ...this.state.ohs_audit,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const ohs_audit = this.state.ohs_audit
    const self = this

    setupCSRFToken();

    axios.put(`/ohs_audits/${ohs_audit.id}`, { ohs_audit: ohs_audit })
    .then(response => {
      self.setState({
        ohs_audit: response.data.ohs_audit,
        isHeaderEditable: false,
      })

      self.baseState = this.state

      toastr.success("The report has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
    })

  }

  toggleTitleEditable = e => {
  	e.preventDefault();

    this.setState({
      isHeaderEditable: !this.state.isHeaderEditable
    })
  }

  render () {

    if (this.state.isHeaderEditable) {
      return(
      	<div className="panel-body" style={{position: 'relative'}}>
	        <form className="ohs-audit-header-form" onSubmit={this.handleSubmit} >
	          <section className="panel-report-header">
	            <div className="report-title">
	              <h4>Cleaners Room Location:</h4>
                <p className="m-0 text-muted">Please specify the Cleaners room location if there are multiple cleaners rooms.</p>
	              <div className="form-group">
	                <input className="form-control" 
	                  type="text" 
	                  value={this.state.ohs_audit.cleaner_room || ""} 
	                  onChange={this.handleChange}
	                  name="cleaner_room"
                    placeholder="Admin Building Cleaners Room" />
	              </div>
	            </div>

	            <div className="report-overview">
	              <h4>Comments</h4>
	                <div className="form-group">
	                  <textarea className="form-control w-p100 h-min-200" 
	                    name="comment"
	                    value={this.state.ohs_audit.comment || ""}
	                    onChange={this.handleChange} />
	                </div>
	            </div>

	            <div className="form-group mt-10">
	              <input type="submit"
	                className="pull-right btn btn-primary btn-quirk"
	                value="SAVE CHANGES" />
	              <button onClick={this.resetForm} className="pull-right btn btn-default btn-quirk mr-5">Cancel</button>
	            </div>
	          </section>
	        </form>
	      </div>
      )
    }

    else {
      return (
      	<div className="panel-body" style={{position: 'relative'}}>
			    <section className="panel-report-header">
			      <div className="report-title form-group">
			        <h4>Cleaner Room:</h4>
			        <div className="border-b-dotted">
			          {this.state.ohs_audit.cleaner_room || "No Contents Available"}
			        </div>
			      </div>

			      <div className="report-title">
			        <h4>Comments:</h4>
			        <div className="border-b-dotted">
			          <p className="white-space-pre">{this.state.ohs_audit.comment || "No Comments Available"}</p>
			        </div>
			      </div>
			      
			    </section>
			    <ul className="panel-options pt-10">
            <li><a href="#" onClick={this.toggleTitleEditable}>Edit Header</a></li>
          </ul>
			  </div>
      )
    }
    

  }
}

export default ReportHeader
