import React from 'react';
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from "react-google-maps";

import Oval from 'assets/images/icons/oval.png';
import {GoogleMapDarkStyles, GoogleMapLightStyles} from 'config/google_maps/styles';


import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string'

const HeatMapComponent = compose(
  withProps({
    /**
     * Note: create and replace your own key in the Google console.
     * https://console.developers.google.com/apis/dashboard
     * The key "AIzaSyBkNaAGLEVq0YLQMi-PYEMabFeREadYe1Q" can be ONLY used in this sandbox (no forked).
     */
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB_3C0hWSq9Vr1OULAmjzrdgR9iPi6VAXc&v=3.exp&libraries=geometry,drawing&&format=png&maptype=roadmap&style=feature:poi%7Celement:labels.text%7Cvisibility:off&style=feature:poi.business%7Cvisibility:off&style=feature:road%7Celement:labels.icon%7Cvisibility:off&style=feature:transit%7Cvisibility:off",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap 
    defaultZoom={17} 
    defaultCenter={props.site_location}
    defaultOptions={{ styles: GoogleMapLightStyles }} >

    <DrawCustomMarkers {...props}/>
    <Circle
      defaultCenter={props.site_location}
      radius={150}
      options={ {
        fillColor: `#0d75e0`,
        fillOpacity: 0.2,
        strokeWeight: 1,
        strokeColor: `#0d75e0`,
        strokeOpacity: 0.8,
        clickable: false,
        editable: false,
      } }
    />
  </GoogleMap>
));

const DrawCustomMarkers = props => {
  console.log(props.time_trackings)
  return props.time_trackings.map((location, index) => {
    return <Marker  key={index} 
                    id={index} 
                    position={{
                      lat: Number(location[0]),
                      lng: Number(location[1])
                    }}
                    icon={{
                      url: Oval,
                      //scaledSize: new window.google.maps.Size(20, 20),
                    }} 
                    />
  })
  
}

class TimeTrackingHeatMapPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      time_trackings: this.props.sorted_time_trackings_for_map,
      is_loading: false,
    };
  }

  componentDidMount() {
    //this.fetchTimeTrackings()
  }

  // fetchTimeTrackings = () => {
  //   const site_id = this.props.match.params.site_id
  //   const parsed = queryString.parse(location.search);
  //   const range = parsed.range
  //   const _from = parsed.from
  //   const _to = parsed.to

  //   axios.get(`/sites/${site_id}/trackings_for_heatmap.json`)
  //   .then(response => {
  //     this.setState({
  //       time_trackings: response.data.time_trackings,
  //       is_loading: false,
  //     })
  //   })
  //   .catch(error => {
  //     console.error(error.message)
  //     this.setState({
  //       is_loading: false,
  //     })
  //   })

  // }

  render() {
    const {
      is_loading,
      time_trackings
    } = this.state

    if (is_loading) {
      return "Loading..."
    }
    else{
      return (
        <React.Fragment>
          <div className="panel">
            <div className="panel-heading">
              <h4 className="panel-title">Time Tracking Heatmap</h4>
            </div>

            <div className="panel-body" style={{position: 'relative'}}>
              <div style={{height: '550px', position: 'relative'}}>
                <HeatMapComponent 
                  site_location={{ lat: Number(this.props.site.latitude), lng: Number(this.props.site.longitude)}}
                  time_trackings={time_trackings}
                  {...this.props}/>
              </div>
            </div>
          </div>
        </React.Fragment>
          
      );
    }
    
  }
}

export default TimeTrackingHeatMapPanel;