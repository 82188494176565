
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';


import DaysInWeeks from './DaysInWeeks'

const List = (props) => {
	const {result, tm, sub_section_id, showEditTaskModel } = props
  
  return (
  	<div>
	  	{result.map(tm => (

	      <div key={tm.id} className="task-item-row mb-5 d-flex justify-content-between">
	        <div className="task-name">
	          {tm.task_name}
	          <br />
	          <small>
	            <Link to="#" onClick={showEditTaskModel.bind(this, tm)} >Edit</Link> | <Link data-confirm="Are you sure you want to delete this item?" rel="nofollow" data-method="delete" to={'/tasks_mappings/' + tm.id} className="text-danger">Delete</Link>
	          </small>
	        </div>
	        <div className="task-details">
	          <span className="task-frequency-type mr-5">{tm.frequency_type}</span>
	          
	          <DaysInWeeks frequencies={tm.frequencies}/>
	        </div>
	      </div>

	    ))}
    </div>
  )
}


export default List;





