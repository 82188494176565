import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';
import _ from 'underscore';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';


import AssetIssuingItem from './asset_issuing_item'

class AssetIssuingItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assets_issuing: this.props.assets_issuing,
      issuing_items: this.props.issuing_items,
      assets: this.props.assets,
      last_barcode: "",
      showErrors: false,
      errors: {},
      is_issuing_editable: this.props.is_issuing_editable,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {

  }

  handleChange = event => {
    this.setState({
      last_barcode: event.target.value
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const last_barcode = this.state.last_barcode
    const assets_issuing = this.state.assets_issuing

    setupCSRFToken();

    axios.post(`/ams/issuings/${assets_issuing.id}/assets_issuing_items/`,{
      barcode: last_barcode
    })
    .then(response => {
      console.log(response)
      this.setState({
        showErrors: false,
        issuing_items: [
        ...this.state.issuing_items,
          response.data.asset_issuing_item,
        ],
        assets: [
        ...this.state.assets,
        response.data.asset
        ],
        last_barcode: ""
      })

      //Set autofocus
      this.nameInput.focus(); 

      toastr.success("The issuing item has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      if (error.response && error.response.status == 422){
        this.setState({
          showErrors: true,
          errors: {
            message: "Asset has already been taken."
          }
        })
      }
      else if (error.response && error.response.status == 404) {
        this.setState({
          showErrors: true,
          errors: {
            message: "The asset can't be found in the warehouse. Please try another code"
          }
        })
      }
    })

  }

  render () {

    return (
      <div className="form-group">
      {this.state.is_issuing_editable &&
        <div>
          
          <label className="form-control-label" >Add Asset</label>
          <form name="AssetsIssuingHeaderForm" className="asset-issuing-form form-inline w-p100" onSubmit={this.handleSubmit} >
            <div className="form-group mb-2">
              <input type="text" 
                name="barcode" 
                id="assets_asset_id" 
                className="form-control w-300 mr-10" 
                autoFocus 
                placeholder="Warehouse code Eg. 01101"
                value={this.state.last_barcode}
                onChange={this.handleChange}
                ref={(input) => { this.nameInput = input; }} 
              />
            </div>
            <div className="form-group mb-2">
              <input type="submit"
                className="btn btn-primary"
                value="Add Asset" />
            </div>

          </form>
          
          { this.state.showErrors &&
            <p className="text-danger">{this.state.errors.message}</p>
          }
        </div>
      }

        <div className="issuing-items-wrapper mt-30">
          
          {this.state.issuing_items.map((issuing_item, index) => {
            let asset = _.findWhere(this.state.assets, {id: issuing_item.asset_id} )
            
            return(
              <AssetIssuingItem
              issuing_item={issuing_item}
              assets_issuing={this.state.assets_issuing}
              is_issuing_editable={this.state.is_issuing_editable}
              asset={asset}
              key={index} />
            )
          })}
        </div>
      </div>

    )
    

  }
}

export default AssetIssuingItems
