import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import ListLoader from 'components/common/list_loader';

import NewTaskTemplateForm from './components/new_tasks_template_form';
import TasksTemplatesList from './components/tasks_templates_list';
import TasksTemplateItemDetails from './show';
import TasksMappingsLoadingPage from './components/_common/_tasks_mappings_loading_page';
import MarkAsCompletedForm from './components/mark_as_completed_form';

class TasksTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      is_tasks_loading: true,
      tasks_templates: [],
      selected_tasks_template: {},
      site_id: this.props.match.params.site_id,
      errors: {},
      tasks_templates_logind_issues: false,
      tasks_mappings: [],
      site: {},
    };
    
    this.handleTaskTemplateItemClick = this.handleTaskTemplateItemClick.bind(this);
    this.updateTasksTemplates = this.updateTasksTemplates.bind(this)

  }

  componentDidMount = async () => {
    await this.setSiteIdToState();
    await this.getTasksTemplates();
  }

  setSiteIdToState = async () => {
    const path = this.props.match.path

    if (path.match(/\/tasks_templates\/:tasks_template_id/gi)){
      
      const tasks_template_id = this.props.match.params.tasks_template_id
      const response = await axios.get(`/tasks_templates/${tasks_template_id}.json`)
      
      await this.setState({
              selected_tasks_template: response.data.tasks_template,
              site_id: response.data.site.id,
              site: response.data.site,
              tasks_mappings: response.data.tasks_mappings,
              is_tasks_loading: false,
            })

    } else{
      return null;
    }
  }

  getTasksTemplates = () => {
    const site_id = this.state.site_id
    
    if (site_id != undefined){
      axios.get(`/sites/${site_id}/tasks_templates.json`)
      .then(response => {
        this.setState({
          tasks_templates: response.data.tasks_templates,
          site: response.data.site,
          is_loading: false,
          is_tasks_loading: false,
        })
      })
      .catch(error => {
        console.error(error.message)
        this.setState({
          tasks_templates_logind_issues:true,
          is_loading: false,
          is_tasks_loading: false,
        })
      })
    }
    

  }

  handleTaskTemplateItemClick = async tasks_template => {
    await this.setState({ selected_tasks_template: tasks_template })

    await this.props.history.push(`/tasks_templates/${tasks_template.id}`)
    await this.setSiteIdToState()

  }

  updateTasksTemplates = async new_tasks_template => {
    const tasks_templates = [...this.state.tasks_templates, new_tasks_template]
    await this.setState({
      tasks_templates: tasks_templates
    })

    await this.handleTaskTemplateItemClick(new_tasks_template)
  }

  updateTasksTemplateItem = updated_tasks_template => {
    const itemIndex = this.state.tasks_templates.findIndex(tt => tt.id == updated_tasks_template.id)
    const tasks_templates = this.state.tasks_templates;

    tasks_templates[itemIndex] = updated_tasks_template
    
    this.setState({
     tasks_templates: tasks_templates,
     selected_tasks_template: updated_tasks_template,
    })

  }

  unSetTasksTemplate = tasks_template => {
    const updated_tasks_templates = this.state.tasks_templates.filter(tt => {
      return tt.id !== tasks_template.id
    })

    this.setState({
      tasks_templates: updated_tasks_templates
    })

    this.props.history.push(`/sites/${tasks_template.site_id}/tasks_templates/`)
  }

  updateSiteGSpecsReadyStatus = gspecs_ready => {
    this.setState({
      site: {
        ...this.state.site,
        gspecs_ready: gspecs_ready
      }
    })
  }

  render() {
    const {
      match
    } = this.props;

    const {
      is_loading,
      is_tasks_loading,
      tasks_templates,
      selected_tasks_template,
      tasks_templates_logind_issues,
      tasks_mappings,
      site_id,
      site,
    } = this.state
  
    return (
      <div className="panel panel-aside col-md-12">
        <div className="row no-gutters">
          <div className="col-md-4 panel-aside-left">
            <div className="list-wrapper">
              <div className="list-header">
                <div className="d-flex justify-content-start">
                  <div className="back-btn">
                    <a className="btn btn-default btn-sm" href={`/sites/${site_id}/specifications`}>
                      Back
                    </a>
                  </div>
                  <h4> Tasks Templates</h4>
                </div>
              </div>

              <ul className="palist-group tasks-templates-list">
              { is_loading &&
                <ListLoader times={4}/>
              }

              {tasks_templates_logind_issues &&
                <div className="gritter-item-wrapper with-icon times-circle danger">
                  <div className="gritter-item">
                    <div className="gritter-without-image">
                      <span className="gritter-title">Something went Wrong!</span>
                      <p>An Error occured while loading tasks templates. Please reload the page and try again</p>
                    </div>
                    <div style={{clear: "both"}}></div>
                  </div>
                </div>
              }

              {!is_loading &&
                <TasksTemplatesList
                  tasks_templates={tasks_templates}
                  handleTaskTemplateItemClick={this.handleTaskTemplateItemClick}
                  selected_tasks_template={selected_tasks_template}
                />

              }
              {site.id !== undefined && !site.gspecs_ready &&
                <li className="bg-grey-100">
                  <NewTaskTemplateForm 
                    {...this.props}
                    site_id={site_id}
                    updateTasksTemplates={this.updateTasksTemplates} />
                </li>
              }
              </ul>
            </div>
          </div>

          <div className="col-md-8 panel-aside-right">
            {is_tasks_loading &&
              <TasksMappingsLoadingPage />
            }
            
            <Route
              exact
              path="/tasks_templates/:id"
              component={ () =><TasksTemplateItemDetails 
                                  selected_tasks_template={selected_tasks_template}
                                  updateTasksTemplateItem={this.updateTasksTemplateItem}
                                  unSetTasksTemplate={this.unSetTasksTemplate}
                                  tasks_mappings={tasks_mappings} />} />

            {site.id !== undefined &&
              <Route
                exact
                path="/sites/:id/tasks_templates"
                component={ () => <MarkAsCompletedForm 
                                    site_id={site_id} 
                                    gspecs_ready={site.gspecs_ready}
                                    updateSiteGSpecsReadyStatus={this.updateSiteGSpecsReadyStatus} />

                } />

            
            }

          </div>
        </div>
      </div>
    )
  }
}


export default TasksTemplates;
