import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

class RecentEmployees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      employees: [],
    };

  }

  componentDidMount = () => {
  	this.getUserDetails()
  }

  getUserDetails = () => {
    axios.get(`/recent_employees.json`)
    .then(response => {
      this.setState({
        employees: response.data.employees,
        is_loading: false,
      })
    })
    .catch(error => {
      console.error(error.message)
      this.setState({
        is_loading: false,
      })
    })
  }

  render() {
    const {
      is_loading,
      employees
    } = this.state

    if (is_loading) {
      return <em>Loading...</em>
    }else {
      return (
        <div className="panel panel-primary">
          <div className="panel-heading">
            <h4 className="panel-title">Recent Employees</h4>
          </div>
          <div className="panel-body">
            <ul className="media-list user-list">
              {employees && employees.map( (emp, index) => {
                return <EmployeeListItem
                        key={index}
                        employee={emp} />
              })}

              <li className="media">
                <a href="/staffs" className="btn d-block">View All</a>
              </li>
            </ul>
          </div>
        </div>
      )
    }
  	
  }
}

const EmployeeListItem = ({employee}) => {
  return (
    <li className="media">
      <div className="media-left">
        <a href={`staffs/${employee.id}`}>
          <img className="media-object img-circle" src={employee.user.avatar_url} alt="" />
        </a>
      </div>
      <div className="media-body">
        <h4 className="media-heading"><a href={`staffs/${employee.id}`}>{employee.user.full_name}</a></h4>
        <span className="label label-default">{employee.display_name}</span>
        <span><strong>EMP: {employee.user.employee_id}</strong></span>
      </div>
    </li>
  )
}

export default RecentEmployees;
