import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

class AssetsIssuingItemNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showIssuingNoteForm: false,
    };

    this.baseState    = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)

    this.toggleNoteEditable()
  }

  toggleNoteEditable = () => {
    this.setState({
      showIssuingNoteForm: !this.state.showIssuingNoteForm
    })
  }

  render () {

    if (this.state.showIssuingNoteForm) {
      return(
        <form name="AssetsIssuingHeaderForm" className="asset-issuing-form" onSubmit={this.props.handleUpdateIssuingItem} >
          <section className="panel-report-header">

            <div className="report-overview">
              
              <div className="form-group">
                <textarea className="form-control w-p100" 
                  name="issue_note"
                  value={this.props.issuing_note || ""}
                  onChange={this.props.handleChange} />
              </div>
            </div>

            <div className="form-group mt-10 pb-50">
              <div className="d-flex justify-content-end">
                <input type="submit"
                className="btn btn-primary btn-quirk mr-5"
                value="SAVE CHANGES" />
                <button onClick={this.resetForm} className="btn btn-default btn-quirk mr-5">Cancel</button>
              </div>
            </div>
          </section>
        </form>
      )
    }

    else if (this.props.issuing_status == "draft"){
      return <span className="text-link" onClick={this.toggleNoteEditable}>Add Note</span> 
    }
    else{
      return "-"
    }
    

  }
}

export default AssetsIssuingItemNote
