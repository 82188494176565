import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';


const FinalStagePanel = ({
  user,
  designation,
  current_stage,


}) => {

	const [isLoading, setIsLoading] = useState(false)

	const handleSendInvitation = () => {
		setupCSRFToken();

    axios.post(`/staffs/${designation.id}/send_invitation`, {})
    .then(response => {
    	setIsLoading(false)

    	window.location = `/staffs/${designation.id}`
    })
    .catch(error => {
      console.log("error catched")
      setIsLoading(false)

      toastr.error( "Please check the inputs and submit the form again.",
      							"An error occurred during the process")
 
    })
	}

  const handleSendNotification = () => {
    setupCSRFToken();
    setIsLoading(true)

    axios.post(`/staffs/${designation.id}/send_notification`, {})
    .then(response => {
      setIsLoading(false)

      toastr.success( "Instructions has been sent",
                    "Action completed")
    })
    .catch(error => {
      console.log("error catched")
      setIsLoading(false)

      toastr.error( "Please check the inputs and submit the form again.",
                    "An error occurred during the process")
 
    })
  }

	if (current_stage == "finalise"){
	  return (
      <React.Fragment>
        <hr className="m-0" />
        <div className="panel-heading active">
          <div className="d-flex">
            <span className="icon-button font-weight-bold mt-5">2</span>
            <div className="ml-10">
              <h3 className="m-0">Finalise & Send Invitation</h3>
              <p>Send instructions to the new staff</p>
            </div>
          </div>
        </div>

        <div className="panel-body">
        	<div className="form-group">
        		<SendInvitationPanel
        			user={user}
        			designation={designation}
        			handleSendInvitation={handleSendInvitation}
              handleSendNotification={handleSendNotification}
              isLoading={isLoading} />
        		
        	</div>
        </div>
      </React.Fragment>
	   )
  } else {
  	return (
      <React.Fragment>
        <hr className="m-0" />
        <div className="panel-heading disabled">
          <div className="d-flex">
            <span className="icon-button font-weight-bold mt-5">2</span>
            <div className="ml-10">
              <h3 className="m-0">Finalise & Send Invitation</h3>
              <p>Send instructions to the new staff</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  
}

const SendInvitationPanel = ({
  user,
  designation,
  handleSendInvitation,
  handleSendNotification,
  isLoading
}) => {

	console.log(user)

  if (user.confirmed_at != null) {
    return (
      <div>
        <span className="text-primary d-block">This user has already confirmed his account. You can reset the password from Admin area.</span>
        <a className="btn btn-default btn-quirk" href={`/staffs/${designation.id}`}>View Profile</a>
        <button className="btn btn-primary btn-quirk ml-5" 
                onClick={() => handleSendNotification()} 
                disabled={isLoading ? "disabled" : ""}>Notify To User</button>
      </div>
    )
  }

  if(user.invitation_sent_at == null && user.invitation_token == null) {
  	return (
    	<button className="btn btn-primary btn-quirk" onClick={() => handleSendInvitation()}>Send Invitation</button>
    )
  }else {
    return (
    	<div>
	      <span className="text-primary  d-block">This user is already invited. Click here to send invitation again.</span>
	      <a className="btn btn-default btn-quirk" href={`/staffs/${designation.id}`}>View Profile</a>
	      <button className="btn btn-primary btn-quirk ml-5" onClick={() => handleSendInvitation()}>Send Invitation</button>
	    </div>
    )
  }
            
}



export default FinalStagePanel;
