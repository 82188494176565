import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

import RouterMain from 'routes'

//Redux
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore';
import thunk from 'redux-thunk';
import rootReducer from 'reducers';

const store = configureStore(
	rootReducer,
  applyMiddleware(thunk)
);

class App extends React.Component {
  render () {
    return (
      <BrowserRouter>
      	<Provider store={store}>
	    	  <RouterMain />
        </Provider>
      </BrowserRouter>
    );
  }
}

export default App;
