import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import UserPanel from './components/user_panel';
import DocumentsPanel from './components/documents_panel'
import InductionPanel from './components/inductions_panel'
import AssignSitePanel from './components/assign_site_panel'
import FinalStagePanel from './components/final_stage_panel'

class StaffSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      designation: {},
      user: {},
      user_documents: [],
      user_assessments: [],
      assessment_types: [],
      sites: [],
      document_types: [],
      current_stage: "documents",


    };
    
  }

  componentDidMount = () => {
  	this.getUserDetails();
  }

  getUserDetails = () => {
    const desig_id = this.props.match.params.desig_id
    
    if (desig_id != undefined){
      axios.get(`/staffs/${desig_id}/setup.json`)
      .then(response => {
        this.setState({
        	designation: response.data.designation,
          user: response.data.user,
          user_documents: response.data.employee_documents,
          user_assessments: response.data.assessments,
          document_types: response.data.document_types,
          assessment_types: response.data.assessment_types,
          is_loading: false,
        })
        
      })
      .catch(error => {
        console.error(error.message)
        this.setState({
          is_loading: false,
        })
      })
    }

  }

  updateUserDocuments = _document => {
  	this.setState({
  		user_documents: [
  			...this.state.user_documents,
  			_document
  		]
  	})
  }

  removeUserDocuments = _document => {
  	const updated_user_documents = this.state.user_documents.filter( d => d.id !== _document.id )
  	this.setState({
  		user_documents: updated_user_documents
  	})
  }

  updateUserAssessments = _assessment => {
  	console.log(_assessment)
  	this.setState({
  		user_assessments: [
  			...this.state.user_assessments,
  			_assessment
  		]
  	})
  }

  remoteUserAssessments = _assessment => {
  	const updated_user_assessments = this.state.user_assessments.filter( a => a.id !== _assessment.id )
  	this.setState({
  		user_assessments: updated_user_assessments
  	})
  }

  handleCurrentStageChange = _new_stage => {
  	this.setState({
  		current_stage: _new_stage
  	})
  }

  render() {

  	const {
  		is_loading,
  		user,
  		designation,
  		user_documents,
  		user_assessments,
  		assessment_types,
  		current_stage,
  		document_types
  	} = this.state

  	if (is_loading ) {
  		return "Loading..."
  	}
  	else {
  		return (
	  		<React.Fragment>
	  			<h4 className="panel-title mb10">Setup New Staff</h4>
					<div className="row">
						<div className="col-md-12 col-lg-10">

							<div className="panel steps-wrapper">
								<UserPanel 
									{...this.props}
									designation={designation}
									user={user}
									handleChange={this.handleChange}
									handleSubmit={this.handleSubmit} />

								<DocumentsPanel 
									{...this.props}
									designation={designation}
									user={user}
									user_documents={user_documents}
									current_stage={current_stage}
									document_types={document_types}
									updateUserDocuments={this.updateUserDocuments}
									removeUserDocuments={this.removeUserDocuments}
									handleCurrentStageChange={this.handleCurrentStageChange} />

								<InductionPanel 
									{...this.props}
									current_stage={current_stage}
									designation={designation}
									user={user}
									user_assessments={user_assessments}
									assessment_types={assessment_types}
									handleCurrentStageChange={this.handleCurrentStageChange}
									remoteUserAssessments={this.remoteUserAssessments}
									updateUserAssessments={this.updateUserAssessments} />

								{/**
									<AssignSitePanel 
										{...this.props}
										current_stage={current_stage}
										designation={designation}
										user={user}
										handleCurrentStageChange={this.handleCurrentStageChange} />
								*/}

								<FinalStagePanel 
									{...this.props}
									current_stage={current_stage}
									designation={designation}
									user={user}
									handleCurrentStageChange={this.handleCurrentStageChange} />

								
							</div>
						</div>
					</div>
	  		</React.Fragment>
	  	)
  	}

  }
}


export default StaffSetup;
