import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

class CommentAttachmentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attachment: this.props.attachment,
      isAttachmentRemoved: false,
    };

    this.removeAttachment = this.removeAttachment.bind(this);
  }

  removeAttachment = () => {

    if(window.confirm('Are you sure want to delete the item?')){
      const attachment = this.state.attachment

      setupCSRFToken();

      axios.delete(`/attachments/${attachment.id}`,)
      .then(response => {
        this.setState({ isAttachmentRemoved: true })

        //this.props.unSetAttachment(attachment.id)

        toastr.success("The attachment has been deleted successfully!")
          
      })
      .catch(error => {
        console.log("error catched")
        console.log(error.response)

        toastr.error("An error occurred during the process")
      }) 
    }
  }


  render () {
    const {attachment, isAttachmentRemoved} = this.state

    if (isAttachmentRemoved) {
      return ""
    }
    else {
      return(
        <div className="comment-attachments-column col-xs-3 col-md-2">
          <div className="comment-attachment-wrapper">
            <img className="w-p100" src={attachment.file.thumb_100x.url} alt="Not Found"/>
            <span className="image-remove-icon fa fa-close" onClick={this.removeAttachment}></span>
          </div>
        </div>
      )
    }
    
  }
}

export default CommentAttachmentItem
