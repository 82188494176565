import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';
import _ from 'underscore';

import { PulseLoader } from 'react-spinners';

const AssessmentsListItem = ({
	assessment_type,
	assessment_name,
	user_assessments,
	user,

	updateUserAssessments,
	remoteUserAssessments,
}) => {

	const _assessment = user_assessments.filter( assessment => assessment.assessment_type == assessment_type)
	const _is_enabled = _assessment.length > 0
	const [value, setValue] = useState(_is_enabled)
	const _is_editable = !_is_enabled || _assessment[0].status == 'draft'

	const [isLoading, setIsLoading] = useState(false)

	// console.log(_document)
	// console.log(_is_enabled)
	const handleChange = e => {

    const formData = new FormData();
    const new_value = !e.target.value

    setValue(new_value)
    setIsLoading(true)

    setupCSRFToken();

    if (new_value) {
    
    	formData.append('assessment[assessment_type]', e.target.name )
    	formData.append('assessment[user_id]', user.id )

	    axios.post(`/assessments`, formData)
	    .then(response => {
	    	setIsLoading(false)

	    	updateUserAssessments(response.data.assessment)
	    })
	    .catch(error => {
	      console.log("error catched")
	      console.log(error)

	      setValue(!new_value)

	      toastr.error( "Please check the inputs and submit the form again.",
	      							"An error occurred during the process")
	 
	    })

    } else {
    	const assessment_id = _assessment[0].id

	    axios.delete(`/assessments/${assessment_id}`)
	    .then(response => {
	    	setIsLoading(false)

	    	remoteUserAssessments(response.data.assessment)
	    })
	    .catch(error => {
	      console.log("error catched")
	      console.log(error)

	      setValue(!new_value)

	      toastr.error( "Please check the inputs and submit the form again.",
	      							"An error occurred during the process")
	 
	    })
    }

	}
	if (_is_editable) {
		return (
	  	<li className="list-group-item">
  			<label className={`toggle ${isLoading ? "danger" : ""}`}>
          <input type="checkbox" 
          				name={assessment_type} 
          				className="toggle__input checkbox" 
          				value={value}
          				checked={value}
          				onChange={(e) => {
                    handleChange({
                      target: {
                        name: e.target.name,
                        value: value,
                      },
                    })
                   }} />

          <span className="toggle__label checkbox">
            <span className="toggle__text">
            	{assessment_name}
            	
            	{_is_enabled &&
            	<small className="d-block">Status: {_assessment[0].status} <span></span></small>
            	}

            	{isLoading &&
            		<PulseLoader 
            			size={5}
				          color={"#0d75e0"}
				          loading={isLoading} />
            	}
            </span>
          </span>
        </label>
  		</li>
	  )
	}
	else {
		return (
			<li className="list-group-item">
				<label className={`toggle ${isLoading ? "danger" : ""}`}>
          <input type="checkbox" 
          				name={assessment_type} 
          				className="toggle__input checkbox" 
          				value={true}
          				checked={true}
          				onChange={(e) => {
                    return true;
                   }} />

          <span className="toggle__label checkbox">
            <span className="toggle__text">
            	{assessment_name}
            	{_is_enabled &&
            		<small className="d-block">Status: {_document[0].status} <span className="text-muted">You can't edit this field</span></small>
            	}
            </span>
          </span>
        </label>
  		</li>
		)
	}

	
}

export default AssessmentsListItem
