//AssetIssuingForm.js
import React from 'react'

import Select from 'react-select';
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

import SearchSite from '../form_partials/SearchSite'

class AssetIssuingForm extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
    }
  }


	render() {


    return (
      <div>
	      <SearchSite />
    	</div>
    )
  }
}

export default AssetIssuingForm