import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setSelectedInspectableSection,
          setSelectedInspectableSubSection,
          removeSelectedInspectableSection,
          removeSelectedInspectableSubSection } from '../duck/actions';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import InspectableSectionsContainer from './components/sections/inspectable_sections_container';
import InspectableSubSectionsContainer from './components/sub_sections/inspectable_sub_sections_container';

class Inspection extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      inspection_id: this.props.match.params.inspection_id,
      query_params: {},
      current_page: "",

    };

    this.handleSectionItemClick = this.handleSectionItemClick.bind(this)
    this.handleSubSectionItemClick = this.handleSubSectionItemClick.bind(this)
  }

  componentDidMount() {
    this._isMounted = true;
    this.setQueryStringsToState();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  setQueryStringsToState = async () => {
    const searchStrings = queryString.parse(this.props.location.search);

    await this.setState({
      query_params: searchStrings
    })

    await this.validateCurrentPage()
  }

  validateCurrentPage = () => {

    if (this.state.query_params.page == "sub_sections" ) {
       this.setState({current_page: "sub_sections"});
    }
  }

  handleSectionItemClick = async inspectable_item => {
    await this.props.setSelectedInspectableSection(inspectable_item)
    await this.props.removeSelectedInspectableSubSection()

    await this.props.history.push(`?page=sub_sections&inspectable_item_id=${inspectable_item.id}`)
    await this.setQueryStringsToState();
    await this.setState({current_page: "sub_sections"});
  }

  handleSubSectionItemClick = async inspectable_item => {
    const query_params = this.state.query_params
    
    await this.props.setSelectedInspectableSubSection(inspectable_item)
    await this.setQueryStringsToState();
    await this.setState({current_page: "sub_sections"});

    await this.props.history.push(`?page=${query_params.page}&inspectable_item_id=${query_params.inspectable_item_id}&inspectable_ssitem_id=${inspectable_item.id}`)

  }
  
  render () {
    const { inspectable_item_id } = this.state.query_params
    const { current_page} = this.state

    if (current_page == "sub_sections"){
      return <InspectableSubSectionsContainer { ...this.props }
              handleSubSectionItemClick={this.handleSubSectionItemClick}
              handleSectionItemClick={ this.handleSectionItemClick }
              page={current_page} />
    }
    else{
      return <InspectableSectionsContainer { ...this.props }
                handleSectionItemClick={ this.handleSectionItemClick }
                page={current_page} />

    }

  }
}



const mapStateToProps = state => {
  return {
    ...state.inspection,
  }
}

const mapDispatchToProps = dispatch => ({
  setSelectedInspectableSection: inspectable_section => dispatch(setSelectedInspectableSection(inspectable_section)),
  setSelectedInspectableSubSection: inspectable_sub_section => dispatch(setSelectedInspectableSubSection(inspectable_sub_section)),
  removeSelectedInspectableSubSection: () => dispatch(removeSelectedInspectableSubSection() )
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(Inspection);



//<QuickForm sub_section_id={sub_section_id} is_tasks_template={false} tm={tm}/>
