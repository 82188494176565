import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import {Button, Modal, Alert} from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';
import moment from 'moment';

class EmployeeSignaturePad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      modelShow: false,
      trimmedDataURL: this.props.assessment.emp_signature || null,
      assessment: this.props.assessment,
    };

    this.handleSignatureRemove = this.handleSignatureRemove.bind(this)

    //console.log(moment().format("DD/MM/YYYY hh:mm:ss A"))
    
  }

  sigPad = {}

  handleClose = () => this.setState({ modelShow: false});
  handleShow = () => this.setState({ modelShow: true});

  handleSubmit = () => {
    const signatureDataUrl = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    const formData  = new FormData();
    const assessment = this.state.assessment

    formData.append('assessment[emp_signature]', signatureDataUrl )
    formData.append('assessment[assessment_type]', assessment.assessment_type)
    formData.append('assessment[emp_signed_at]', moment().toDate())

    setupCSRFToken();

    if (!this.sigPad.isEmpty()) {
      
      axios.put(`/assessments/${assessment.id}`, formData)
      .then(response => {
        this.setState({ 
          trimmedDataURL: signatureDataUrl,
           modelShow: false,
         })

        this.props.updateAssessmentState(response.data.assessment)
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      }) 
    }
    else {
      toastr.error( "Please check the inputs and submit the form again.",
                      "Your signature cannot be found.")
    }
    
  }

  handleSignatureRemove = e => {
  	e.preventDefault()

    if(window.confirm('Are you sure to delete this record?')){ 
      const formData  = new FormData();
      const assessment = this.state.assessment

      formData.append('assessment[emp_signature]', "" )
      formData.append('assessment[assessment_type]', assessment.assessment_type)
      formData.append('assessment[emp_signed_at]', "")

      setupCSRFToken();

      axios.put(`/assessments/${assessment.id}`, formData)
      .then(response => {
        this.setState({ 
          trimmedDataURL: null,
         })

        this.props.updateAssessmentState(response.data.assessment)
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })
    }
  	
    

  }

  render() {

  	const { 
  		trimmedDataURL,
  		modelShow } = this.state

  	return(
  		<React.Fragment>
  			<h4>Employee Signature</h4>
  			

  			<div>
  				{trimmedDataURL
	        ? <div className="signature-wrapper">
	        		<img className="signature" style={{maxWidth: '130px', height: 'auto', maxHeight: '100px'}}
	          		src={trimmedDataURL} />

	          	<a href="#" style={{verticalAlign: "bottom"}} onClick={this.handleSignatureRemove}>Remove</a>
	        	</div>
	        
	        :
          <div>
          	<Button className="btn btn-primary btn-quirk"
                onClick={this.handleShow} >Sign Here</Button><br /><span className="text-muted"><em> Required</em></span>
          </div>
          }
  			</div>

        


			  <Modal show={modelShow} onHide={this.handleClose}>
	        
	        <Modal.Header closeButton>
	          <Modal.Title>Your Signature</Modal.Title>
	
	        </Modal.Header>

	        <Modal.Body>

    				<SignaturePad 
    					canvasProps={{className: 'w-p100 h-300 sigCanvas',
    												style: {background: '#f6f8fa'} }}
          		ref={(ref) => { this.sigPad = ref }} />

	        </Modal.Body>
	        
	        <Modal.Footer>
	        	<Button className="btn btn-default btn-quirk" onClick={this.handleClose}>
	            Cancel
	          </Button>
	          
	          <Button className="btn btn-primary btn-quirk" onClick={this.handleSubmit}>
	            Save Changes
	          </Button>
	          
	        </Modal.Footer>
	      
	      </Modal>
  		</React.Fragment>
  		
  	)
  }
}

export default EmployeeSignaturePad;