import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

class SiteVistReportHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTitleEditable: false,
      site_visit: this.props.site_visit,
      auditor: this.props.auditor
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.baseState    = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)

    this.toggleTitleEditable()
  }

  handleChange = event => {
    this.setState({
      site_visit: {
        ...this.state.site_visit,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const site_visit = this.state.site_visit

    console.log(site_visit)

    setupCSRFToken();

    axios.put(`/site_visits/${site_visit.id}`, { site_visit: site_visit })
    .then(response => {
      this.setState({
        site_visit: response.data,
        isTitleEditable: false,
        isSVChanged: true,
      })

      this.baseState = this.state

      toastr.success("The report has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
      

      // window.flash_messages.addMessage(
      //    { id: Math.floor((Math.random() * 1000)), 
      //       message: error.response.data.message, 
      //       type: 'error' });
    })

  }

  toggleTitleEditable = () => {
    this.setState({
      isTitleEditable: !this.state.isTitleEditable
    })
  }

  render () {

    const {
      title,
      overview,
      inspector_signature,
      status,
      was_client_presented,
      have_you_met_client,
      client_contact_name,
      reason_not_met_client,
      client_contact_job_role,

    } = this.state.site_visit

    if (this.state.isTitleEditable) {
      return(
        <form name="SiteVisitHeaderForm" className="site-visit-form" onSubmit={this.handleSubmit} >
          <section className="panel-report-header">
            <div className="report-title">
              <h4>Report Title</h4>
              <div className="form-group">
                <input className="form-control input-lg" 
                  type="text" 
                  value={title} 
                  onChange={this.handleChange}
                  name="title" />
              </div>
            </div>

            <div className="report-overview">
              <h4>Report Summary</h4>
                <div className="form-group">
                  <textarea className="form-control w-p100" 
                    name="overview"
                    value={overview}
                    onChange={this.handleChange} />
                </div>
            </div>

            <div className="form-group mt-10">
              <input type="submit"
                className="pull-right btn btn-primary btn-quirk"
                value="SAVE CHANGES" />
              <button onClick={this.resetForm} className="pull-right btn btn-default btn-quirk mr-5">Cancel</button>
            </div>
          </section>
        </form>
      )
    }

    else {
      return (
        <section className="panel-report-header">
          <div className="report-title">
            <h4 className="panel-title" style={{ color: "#259dab"}}>Report Title</h4>
            <div className="form-group">
              <h3>{title}</h3>
            </div>
          </div>

          <div className="report-overview">
            <h4 className="panel-title" style={{ color: "#259dab"}}>Report Summary</h4>
            <div className="form-group">
              <p>{overview}</p>
            </div>

            
            {status == "completed" &&
              <div className="form-group">
                <img src={inspector_signature} alt="Auditor" width="100" /><br />
                <p className="font-weight-bold">-- {this.state.auditor}</p>

              </div>
            }
          </div>

          
          {status == "completed" &&
            <div className="report-overview mt-30">
              <h4>Client Acknowledgement</h4>
              
              <div className="feedback-wrapper mb-30">
                <table className="table table-default table-striped">
                  <tbody>
                    <tr>
                      <td className="w-300">
                        <strong>Was the Client presented for the Audit?</strong>
                      </td>
                      <td>
                        {was_client_presented ? "YES" : "NO"}
                      </td>
                    </tr>

                    {!was_client_presented &&
                      <tr>
                        <td className="w-300">
                          <strong>Have you met the client?</strong>
                        </td>
                        <td>
                          {have_you_met_client ? "YES" : "NO"}
                        </td>
                      </tr>
                    }

                    { have_you_met_client || was_client_presented ?
                      <>
                        <tr>
                          <td class="w-300">
                            <strong>Name of the Client</strong>
                          </td>
                          <td>
                            {client_contact_name} {client_contact_job_role ? `- ${client_contact_job_role}` : ""}
                          </td>
                        </tr>
                      </>
                    :
                    ""
                    }
                    { !have_you_met_client && !was_client_presented &&
                      <tr>
                        <td class="w-300">
                          <strong>Please give a reason for not meeting the Client.</strong>
                        </td>
                        <td>
                          {reason_not_met_client}
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
                
              </div>
            </div>
          }

          <ul className="panel-options pt-10">
            <li><a href="javascript:void(0)" onClick={this.toggleTitleEditable}>Edit Title</a></li>
          </ul>
        </section>
      );
    }
    

  }
}

// Show.propTypes = {
//   site: PropTypes.object,
//   site_visit: PropTypes.object,
//   client: PropTypes.object
// };

export default SiteVistReportHeader
