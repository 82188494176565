import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setInspectableSubSections,
          setSelectedInspectableSection,
          setSelectedInspectableSubSection,
          removeSelectedInspectableSection,
          removeSelectedInspectableSubSection,
          setInspectableTasksMappings } from '../../../duck/actions';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'
import ListLoader from 'components/common/list_loader'

import InspectableSubSections from './inspectable_sub_sections'
import CompleteLoadingPage from '../common/complete_loading_page';
import MarkSectionAsCompleted from '../sections/mark_section_as_completed';

class InspectableSubSectionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //selected_inspectable_section: this.props.inspection.selected_inspectable_section,
      //selected_inspectable_sub_section: this.props.inspection.selected_inspectable_sub_section,
      inspection_id: this.props.match.params.inspection_id,
      query_params: {},
      show_tasks_mappings: false,
      show_section_audit_completion_model: false,
      is_loading: true,
      is_section_completable: false
    };

    this.getInspectableSubSections = this.getInspectableSubSections.bind(this)
    this.setQueryStringsToState = this.setQueryStringsToState.bind(this)
    this.handleSubSectionItemClick = this.handleSubSectionItemClick.bind(this)
    this.checkInspectableSectionAudited = this.checkInspectableSectionAudited.bind(this)
    this.setSelectedSubSection = this.setSelectedSubSection.bind(this)
    this.getInspectableTasksMappings = this.getInspectableTasksMappings.bind(this)
    this.checkSectionCompletable = this.checkSectionCompletable.bind(this)
    //this.handleMarkSectionAsCompletedButton = this.handleMarkSectionAsCompletedButton.bind(this)
    //this.markSectionAsCompletedElement = React.createRef();

  }

  componentDidMount = async () => {
    await this.setQueryStringsToState();
    await this.getInspectableSubSections();
    await this.checkSectionCompletable();
    
  }

  setQueryStringsToState = () => {
    const searchStrings = queryString.parse(this.props.location.search);

    this.setState({
      query_params: searchStrings
    })
  }

  getInspectableSubSections = async () => {
    const inspection_id = this.state.inspection_id
    const inspectable_section_id = this.state.query_params.inspectable_item_id //type: Section

    const response = await axios.get(`/inspections/${inspection_id}/inspectable_items?item_type=sub_sections&inspectable_item_id=${inspectable_section_id}`)

    await this.props.setInspectableSubSections(response.data.inspectable_sub_sections)
    //await this.setState({ selected_inspectable_section: response.data.inspectable_section })
    await this.props.setSelectedInspectableSection(response.data.inspectable_section)
      
    await this.setSelectedSubSection(response.data.inspectable_sub_sections);
    await this.checkInspectableSectionAudited()

    await this.setState({ is_loading: false })

  }

  handleSubSectionItemClick = async inspectable_sub_section => {
  
    await this.setState({ show_tasks_mappings: false })
  	
    await this.props.handleSubSectionItemClick(inspectable_sub_section)
  	
    await this.getInspectableTasksMappings()

    await this.setState({
  		show_tasks_mappings: true,
      is_loading: false
  		//selected_inspectable_sub_section: inspectable_sub_section,
  	})

    //await this.props.setSelectedInspectableSubSection(inspectable_sub_section)

  }

  getInspectableTasksMappings = async () => {
    const inspection_id = this.state.inspection_id
    const inspectable_sub_section_id = this.props.inspection.selected_inspectable_sub_section.id //type: Sub Section

    const response = await axios.get(`/inspections/${inspection_id}/inspectable_items?item_type=tasks_mappings&inspectable_item_id=${inspectable_sub_section_id}`)

    await this.props.setInspectableTasksMappings(response.data.inspectable_tasks_mappings)
    
  }


  setSelectedSubSection = inspectable_sub_sections => {

    const inspectable_ssitem_id = this.state.query_params.inspectable_ssitem_id

    if ( Number.isInteger(parseInt(inspectable_ssitem_id)) ){

      const selected_inspectable_sub_section = inspectable_sub_sections.filter(item => item.id == inspectable_ssitem_id )[0]
      
      this.handleSubSectionItemClick(selected_inspectable_sub_section)
      
    }
  }

  update_inspectable_sub_section_status = inspectable_sub_section => {
    const itemIndex = this.state.inspectable_sub_sections.findIndex(item => item.id == inspectable_sub_section.id)
    const inspectable_sub_sections = [...this.state.inspectable_sub_sections];

    inspectable_sub_sections[itemIndex] = inspectable_sub_section
    this.props.setSelectedInspectableSubSection(inspectable_sub_section)
    
    this.setState({
      inspectable_sub_sections: inspectable_sub_sections,
      //selected_inspectable_sub_section: inspectable_sub_section
    })
  }

  checkInspectableSectionAudited = () => {
    console.log("called")
    const inspection = this.props.inspection
    const un_audited_sub_sections = inspection
                                      .inspectable_sub_sections
                                      .filter( inspectable_sub_section => inspectable_sub_section.status != "completed" )

    console.log(un_audited_sub_sections.length)

    if (un_audited_sub_sections.length == 0){
      this.setState({ show_section_audit_completion_model: true })
    }

  }

  checkSectionCompletable = () => {
    const inspection = this.props.inspection
    const un_audited_sub_sections = inspection
                                      .inspectable_sub_sections
                                      .filter( inspectable_sub_section => inspectable_sub_section.status != "completed" )

    if (un_audited_sub_sections.length == 0){
      this.setState({ is_section_completable: true })
    }
  }

  handleMarkSectionAsCompletedButton = () => {
    this.setState({ 
      show_section_audit_completion_model: !this.state.show_section_audit_completion_model 
    })
  }

  render () {

  	const {
  		show_tasks_mappings,
  		query_params,
      inspection_id,
      is_loading,
      show_section_audit_completion_model,
      is_section_completable
  	} = this.state

    const {
      inspection
    } = this.props

    if (is_loading){
      return <CompleteLoadingPage />
    }

    return (
      <React.Fragment>

        <InspectableSubSections
          {...this.props}
          is_loading={is_loading}
          show_tasks_mappings={show_tasks_mappings}
          getInspectableSubSections = {this.getInspectableSubSections}
          handleSubSectionItemClick={this.handleSubSectionItemClick}
          handleSectionItemClick={this.props.handleSectionItemClick}
          getInspectableTasksMappings={this.getInspectableTasksMappings}
          is_section_completable={is_section_completable}
          checkSectionCompletable={this.checkSectionCompletable}
          checkInspectableSectionAudited={this.checkInspectableSectionAudited}
          handleMarkSectionAsCompletedButton={this.handleMarkSectionAsCompletedButton} />

        { inspection && 
          show_section_audit_completion_model && 
          inspection.selected_inspectable_section.status != "completed" &&

          <MarkSectionAsCompleted 
            {...this.props}
            handleMarkSectionAsCompletedButton={this.handleMarkSectionAsCompletedButton} />
        }
      </React.Fragment>
    )

  }
}

const mapStateToProps = state => {
  return {
    ...state.inspection,
  }
}

const mapDispatchToProps = dispatch => ({
  setInspectableSubSections: inspectable_items => dispatch(setInspectableSubSections(inspectable_items)),
  setInspectableTasksMappings: inspectable_items => dispatch(setInspectableTasksMappings(inspectable_items) ),
  setSelectedInspectableSection: inspectable_section => dispatch(setSelectedInspectableSection(inspectable_section)),
  setSelectedInspectableSubSection: inspectable_sub_section => dispatch(setSelectedInspectableSubSection(inspectable_sub_section))
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(InspectableSubSectionsContainer);

