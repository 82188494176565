import axios from 'axios'

// export default function() {
//   const csrfToken = document.querySelector("meta[name=csrf-token]").content
  
//   axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
// }

export default function() {
  axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  };
}