import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types';
import axios from 'axios'

import _ from 'underscore'
import { Modal, Button } from 'react-bootstrap';

import {List, 
        QuickForm, 
        } from './components'

//import TaskDateFields from './components/TaskDateFields';

class TasksMappings extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	error: null,
    	sub_section_id: this.props.match.params.sub_section_id,
      showEditTaskModel: false,
      tm: {},
    	result: [],
      //site_id: parseInt(this.props.match.params.site_id),
    };

    this.showEditTaskModel = this.showEditTaskModel.bind(this);
    this.handleEditTaskModelHide = this.handleEditTaskModelHide.bind(this);
  }

  componentDidMount() {
  	this.getTasksMappings();
  }

  getTasksMappings(){
    const { sub_section_id } = this.state

    fetch(`/sub_sections/${sub_section_id}/tasks_mappings`, {
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          result: result
        });
      },

      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  showEditTaskModel = (tm, e) => {
    e.preventDefault();
    console.log(tm)
    this.setState({
      showEditTaskModel: true,
      tm: {
        ...tm,
      },
    })
  }

  handleEditTaskModelHide = (e) => {
    this.setState({
      showEditTaskModel: false
    })
  }

  render () {
  	const { result, sub_section_id, tm } = this.state;
    
  	return (
      <div>
	      <h4 className="panel-title mb20">Allocated Tasks</h4>
	      <div className="tasks-wrapper">
          <List {...this.state} 
                showEditTaskModel={this.showEditTaskModel}
                />
	        
          <button  onClick={this.showEditTaskModel.bind(this, tm)}
                   className="btn btn-default">Add New Task</button>
	      </div>



        
				
			</div>
   
    );

  }
}

TasksMappings.propTypes = {
  is_tasks_template: PropTypes.bool,
  match: ReactRouterPropTypes.match.isRequired,
};

export default TasksMappings

//<QuickForm sub_section_id={sub_section_id} is_tasks_template={false} tm={tm}/>
