import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setSelectedInspectableSection } from '../../../duck/actions';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import {Button, Modal, Alert} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners'

class MarkSectionAsCompleted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	is_submit_loading: false,
    	audit_section_model_show: true,

    };

    this.handleSubmit = this.handleSubmit.bind(this)
    //this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }


  handleSubmit = () => {

    const self = this
    const inspectable_item = self.props.inspection.selected_inspectable_section

    console.log(inspectable_item)

    self.setState({ is_submit_loading: true })

    setupCSRFToken();

    axios.put(`/inspections/${inspectable_item.inspection_id}/item_mark_as_complete/${inspectable_item.id}`)
    .then(response => {

      const inspectable_section = response.data.inspectable_item

      self.setState({ 
      	is_submit_loading: false,
      	audit_section_model_show: false 
      })

      self.props.setSelectedInspectableSection(inspectable_section)
      //self.props.handleSubSectionItemClick(inspectable_item)

      toastr.success("The Sub Section has been audited successfully!")

    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")

      self.setState({ is_submit_loading: false })
    
    })
  }

  handleClose = () => this.setState({ audit_section_model_show: false })
  
  handleShow = () => {
  	this.setState({ 
  		audit_section_model_show: true 
  	})
  }

  render(){
  	const {	selected_inspectable_sub_section } = this.props

  	const {
  		is_submit_loading, audit_section_model_show
  	} = this.state

  	return(
	  	<React.Fragment>
	  		<Modal show={audit_section_model_show} 
          onHide={() => this.props.handleMarkSectionAsCompletedButton() } className="model-primary">
	        
	        <Modal.Header closeButton>
	          <Modal.Title>MARK AS COMPLETED</Modal.Title>
	        </Modal.Header>

	        <Modal.Body>
	        	

	        You have audited all the sub-sections in this section. Whould you like to mark this section as completed?

	        </Modal.Body>
	        

	        <Modal.Footer>
	        	<div className="d-flex justify-content-end">
	        		<BeatLoader
	        			className="m-10"
	              sizeUnit={"px"}
	              size={7}
	              color={"#0d75e0"}
	              loading={is_submit_loading}
	            />
	            <Button className="btn btn-default btn-quirk" 
                onClick={()=> this.props.handleMarkSectionAsCompletedButton()}>
		            No Thanks
		          </Button>
		          <Button className="btn btn-primary btn-quirk" onClick={this.handleSubmit}>
		            YES & SAVE
		          </Button>
	        	</div>
	          
	        </Modal.Footer>
	      
	      </Modal>

	  	</React.Fragment>
	  )
  }
}

const mapStateToProps = state => {
  return {
    ...state.inspection,
  }
}

const mapDispatchToProps = dispatch => ({
  setSelectedInspectableSection: inspectable_section => dispatch(setSelectedInspectableSection(inspectable_section))
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(MarkSectionAsCompleted);