import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

const UserPanel = ({
	user
}) => {

	
	if (user) {
		return (
			<React.Fragment>
				<div className="panel-heading disabled">
					<div className="d-flex">
						<span className="icon-button font-weight-bold mt-5">1</span>
						<div className="ml-10">
							<h3 className="m-0">Basic information</h3>
							<p>Provide the basic information such as name, email etc.</p>

						</div>
					</div>
				</div>

				<div className="selected-user media p-20 m-0" style={{background: '#0d75e0', color: '#ffffff'}}>
	        <div className="media-left">
	          <img className="media-object img-circle w-50" src="/assets/user_avatar_default_150x.png" alt="" />
	        </div>
	        <div className="media-body">
	          <h3 className="media-heading nomargin text-white">{user.first_name} {user.last_name }</h3>
	          <strong>Email: </strong>{user.email}
	        </div>
	      </div>
			</React.Fragment>
		)
	} 
	else {
		return (
			<React.Fragment>
				<div className="panel-heading disabled">
					<div className="d-flex">
						<span className="icon-button font-weight-bold mt-5">1</span>
						<div className="ml-10">
							<h3 className="m-0">Basic information</h3>
							<p>Provide the basic information such as name, email etc.</p>

						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}

}

export default UserPanel;
