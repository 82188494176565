import React from 'react'

const ValidateFrequency = date => {
  console.log(date)
  if (date == "true") {
    return "active"
  }else {
    return "";
  }
}

const InspectableTasksMappingItem = ({
    inspectable_tasks_mapping,
    handleTasksMappingModelShow,
    }) => {

  const {
    status,
    score,
    item_name,
    task_frequency,
    frequencies
  } = inspectable_tasks_mapping

  const scores = ["Poor", "Fair", "Good", "Very Good", "Excellent"]

	return (
    <React.Fragment>
    	<li className="list-group-item">
        
        <div className="d-flex justify-content-between">
    
          <div className="task-name-1 w-p100 mr-5">
            {item_name}
            
            <div className="task-frequency-details mt-10 d-flex justify-content-start">
              
              <div className="task-frequency-details small">
                <span className="task-details mr-5 label label-default">{task_frequency}</span>
                <span className={`task-frequency 
                                    ${frequencies.monday =="1" ? "active" : "" }` }>M</span>
                <span className={`task-frequency 
                                    ${frequencies.tuesday =="1" ? "active" : "" }` }>T</span>
                <span className={`task-frequency 
                                    ${frequencies.wednesday =="1" ? "active" : "" }` }>W</span>
                <span className={`task-frequency 
                                    ${frequencies.thursday =="1" ? "active" : "" }` }>T</span>
                <span className={`task-frequency 
                                    ${frequencies.friday =="1" ? "active" : "" }` }>F</span>
                <span className={`task-frequency 
                                    ${frequencies.saturday =="1" ? "active" : "" }` }>S</span>
                <span className={`task-frequency 
                                    ${frequencies.sunday =="1" ? "active" : "" }` }>S</span>
              </div>
              { score != "" &&
                <div className="audited-score-details ml-50">
                  <label className="font-weight-bold">SCORE: </label> &nbsp; {score}
                </div>
              }
            </div>
          </div>
          <div className="w-200 text-right">

            {status == "completed" &&
          	 <span className="label label-default p-5 mr-10">AUDITED</span>
            }

            <span className="btn btn-link p-0" onClick={() => handleTasksMappingModelShow(inspectable_tasks_mapping)}>
            {status == "completed" ? "Re Audit" : "Audit"}</span>
          </div>
          
        </div>

      </li>  
    </React.Fragment>
  );

}

export default InspectableTasksMappingItem;