import {
	SET_INSPECTION,	
	SET_INSPECTABLE_SECTIONS,
	SET_INSPECTABLE_SUB_SECTIONS,
	SET_INSPECTABLE_TASKS_MAPPINGS,

	SET_SELECTED_INSPECTABLE_SECTION,
	REMOVE_SELECTED_INSPECTABLE_SECTION,

	SET_SELECTED_INSPECTABLE_SUB_SECTION,
	REMOVE_SELECTED_INSPECTABLE_SUB_SECTION,

	UPDATE_INSPECTABLE_TASKS_MAPPINGS,

} from './constants';

export const setInspection = inspection => {
	return ({
		type: SET_INSPECTION,
		inspection: inspection
	})
}

export const setInspectableSections = inspectable_sections => {
	return ({
		type: SET_INSPECTABLE_SECTIONS,
		inspectable_sections: inspectable_sections
	})
}

export const setInspectableSubSections = inspectable_sub_sections => {
	return ({
		type: SET_INSPECTABLE_SUB_SECTIONS,
		inspectable_sub_sections: inspectable_sub_sections
	})
}

export const setInspectableTasksMappings = inspectable_tasks_mappings => {
	return ({
		type: SET_INSPECTABLE_TASKS_MAPPINGS,
		inspectable_tasks_mappings: inspectable_tasks_mappings
	})
}

export const setSelectedInspectableSection = inspectable_section => {
	return ({
		type: SET_SELECTED_INSPECTABLE_SECTION,
		selected_inspectable_section: inspectable_section
	})
}

export const removeSelectedInspectableSection = inspectable_section => {
	return ({
		type: REMOVE_SELECTED_INSPECTABLE_SECTION,
		selected_inspectable_section: {}
	})
}

export const setSelectedInspectableSubSection = inspectable_sub_section => {
	return ({
		type: SET_SELECTED_INSPECTABLE_SUB_SECTION,
		selected_inspectable_sub_section: inspectable_sub_section
	})
}

export const removeSelectedInspectableSubSection = inspectable_sub_section => {
	return ({
		type: REMOVE_SELECTED_INSPECTABLE_SUB_SECTION,
		selected_inspectable_sub_section: {}
	})
}

export const updateInspectableTasksMappings = inspectable_tasks_mappings => {
	return ({
		type: UPDATE_INSPECTABLE_TASKS_MAPPINGS,
		inspectable_tasks_mappings: inspectable_tasks_mappings
	})
}



