import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Offcanvas,
  Modal
} from 'react-bootstrap';
import _ from "underscore";
import moment from "moment"

import Select from 'react-select';

const BREAK_PATTERNS = [0, 15, 30, 45, 60, 75, 90]
const PREDEFINED_TIME_SLOTS = ["12:00 AM", "12:15 AM", "12:30 AM", "12:45 AM", "01:00 AM", "01:15 AM", "01:30 AM", "01:45 AM", "02:00 AM", "02:15 AM", "02:30 AM", "02:45 AM", "03:00 AM", "03:15 AM", "03:30 AM", "03:45 AM", "04:00 AM", "04:15 AM", "04:30 AM", "04:45 AM", "05:00 AM", "05:15 AM", "05:30 AM", "05:45 AM", "06:00 AM", "06:15 AM", "06:30 AM", "06:45 AM", "07:00 AM", "07:15 AM", "07:30 AM", "07:45 AM", "08:00 AM", "08:15 AM", "08:30 AM", "08:45 AM", "09:00 AM", "09:15 AM", "09:30 AM", "09:45 AM", "10:00 AM", "10:15 AM", "10:30 AM", "10:45 AM", "11:00 AM", "11:15 AM", "11:30 AM", "11:45 AM", "12:00 PM", "12:15 PM", "12:30 PM", "12:45 PM", "01:00 PM", "01:15 PM", "01:30 PM", "01:45 PM", "02:00 PM", "02:15 PM", "02:30 PM", "02:45 PM", "03:00 PM", "03:15 PM", "03:30 PM", "03:45 PM", "04:00 PM", "04:15 PM", "04:30 PM", "04:45 PM", "05:00 PM", "05:15 PM", "05:30 PM", "05:45 PM", "06:00 PM", "06:15 PM", "06:30 PM", "06:45 PM", "07:00 PM", "07:15 PM", "07:30 PM", "07:45 PM", "08:00 PM", "08:15 PM", "08:30 PM", "08:45 PM", "09:00 PM", "09:15 PM", "09:30 PM", "09:45 PM", "10:00 PM", "10:15 PM", "10:30 PM", "10:45 PM", "11:00 PM", "11:15 PM", "11:30 PM", "11:45 PM"]

const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const ShiftPayVersionFormModel = ({
  shift,
  shift_pay_version,
  isEdit,
  handleChangePayDataFormSubmit
}) => {
  
  const [show, setShow] = useState(false);
  const [paydata, setPaydata] = useState(shift_pay_version.paydata)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePayDataChange = (dayName, payDataItem) => {
    
    setPaydata({
      ...paydata,
      [dayName]: payDataItem
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    handleChangePayDataFormSubmit(paydata)
    handleClose()
  };

  return (
    <>
      <Button variant="default" onClick={handleShow}>Change Pay Details</Button>

      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        bsSize="lg"
        dialogClassName="w-min-1000">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              Change Pay Details

            </Modal.Title>
            
          </Modal.Header>
          <Modal.Body className="pt-0">
            <table className="table table-hover ">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Allocated Hours</th>
                  <th className="w-150">Start Time</th>
                  <th>Unpaid Break(.min)</th>
                  <th>End Time</th>
                  <th>Award Rate(/hr)</th>
                  <th>Shift Rate(/hr)</th>
                </tr>
              </thead>

              <tbody>
               {shift.recurring_days.map( (day, index) => {

                  return <PayDataRowItem day={day} 
                                  handlePayDataChange={handlePayDataChange}
                                  paydata={paydata} 
                                  key={index} />

                })}
                
              </tbody>
              
            </table>
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}



const PayDataRowItem = ({
  handlePayDataChange,
  day,
  paydata
}) => {

  const [payData, setPayData] = useState({
      allocated_hours: paydata && paydata[day] ? paydata[day].allocated_hours : "",
      start_time: paydata && paydata[day] ? paydata[day].start_time : "",
      finish_time: paydata && paydata[day] ? paydata[day].finish_time : "",
      unpaid_break: paydata && paydata[day] ? paydata[day].unpaid_break : "",
      award_rate: paydata && paydata[day] ? paydata[day].award_rate : "",
      shift_rate: paydata && paydata[day] ? paydata[day].shift_rate : "",
  })
  const optionsForBreakPatterns = BREAK_PATTERNS.map( el => ({value: el, label: el + ' Mins'}))
  const customStyles = {
    control: provided => ({
      border: "2px solid #dfe1e6",
      display: "flex",
      borderRadius: "3px",
    })
  }

  const handleChange = async event => {
    let name = event.target.name
    let value = event.target.value
    await setPayData({
      ...payData,
      [name]: value
    })
  }

  const handleFinishTimeChange = async () => {
    let allocated_hours = payData.allocated_hours || 0
    let unpaid_break = payData.unpaid_break || 0
    let finish_time = moment(payData.start_time, 'hh:mm a').add(allocated_hours, 'hours').add(unpaid_break, 'minutes').format("hh:mm a")
    if (payData.start_time) {
      await setPayData({
        ...payData,
        finish_time: finish_time
      })
    }
  }

  useEffect(() => {
    handlePayDataChange(day, payData)
    //handleFinishTimeChange()
  }, [payData])

  useEffect(() => {
    handleFinishTimeChange()
  }, [payData.allocated_hours, payData.start_time, payData.unpaid_break])

  const {
    allocated_hours,
    start_time,
    finish_time,
    unpaid_break,
    award_rate,
    shift_rate
  } = payData


  return(
    <tr>
      <td className="text-capitalize">{day}</td>
      <td>
        <input onChange={e => handleChange(e)}
                  value={allocated_hours}
                  className="form-control"
                  name="allocated_hours" 
                  type="number" />
      </td>
      <td className="w-150">
        <Select options={PREDEFINED_TIME_SLOTS.map(el=>({value: el, label: el}) )}
                  defaultValue={{value: start_time, label: start_time}}
                  styles={customStyles}
                  name="start_time"
                  className="w-150px"
                  onChange={selectedOption => handleChange({
                    target: {
                      name: "start_time",
                      value: selectedOption.value
                    }
                  })} />
      </td>
      
      <td>
        <Select options={optionsForBreakPatterns}
                  defaultValue={{value: unpaid_break, label: unpaid_break + ' Mins'}}
                  styles={customStyles}
                  name="unpaid_break"
                  onChange={selectedOption => handleChange({
                    target: {
                      name: "unpaid_break",
                      value: selectedOption.value
                    }
                  })} />
      </td>
      <td>
        <input onChange={e => handleChange(e)}
                  value={finish_time}
                  disabled={true}
                  className="form-control"
                  name="finish_time" 
                  type="text" />
      </td>
      <td>
        <input onChange={e => handleChange(e)}
                  value={award_rate}
                  className="form-control"
                  name="award_rate" 
                  type="number" />
      </td>
      <td>
        <input onChange={e => handleChange(e)}
                  value={shift_rate}
                  className="form-control"
                  name="shift_rate"
                  type="number" />
      </td>
    </tr>
  )
}

export default ShiftPayVersionFormModel;