import React from 'react';

import Chart from 'chart.js';
//import moment from 'moment';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import Color from 'color';
import {ColorCodes} from 'config/colors';

//import distinctColors from 'distinct-colors'

class BarChart extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	cleaners: this.props.cleaners,
      sorted_time_trackings_for_graph: this.props.sorted_time_trackings_for_graph,
      site: this.props.site,
			date_range: this.props.date_range,
			sorted_date_range: this.props.sorted_date_range,
      chart_datasets: [],
			allocated_hours: this.props.allocated_hours,
			annotations: [], // for Allocated Hours horizontal lines.
			chart_max_value: 15,
    };
  }

  componentDidMount = async () => {
  	await this.setChartDataSet();
  	await this.createChart();
  }

  setChartDataSet = () => {
  	const {
			cleaners,
			sorted_time_trackings_for_graph,
			allocated_hours,
			chart_max_value,
		} = this.state

		let new_chart_dataset = []
		let new_annotations = []
		let new_chart_max_value = chart_max_value

		cleaners.forEach( (cleaner,i) => {
			let trackings = sorted_time_trackings_for_graph.filter( item => item.cleaner_id == cleaner.id )
			let graph_color = dynamicColors()//Colors[Math.floor(Math.random() * Colors.length)]
			let dataset = {}

			dataset.label = cleaner.user.full_name
			dataset.data	= trackings[0].total_time_spent
			dataset.backgroundColor = graph_color[0],
			dataset.borderColor			= graph_color[1],
			dataset.borderWidth			= 1

			new_chart_dataset.push(dataset)
		})

		allocated_hours.forEach((hours,i) => {
			let annotations = {
				id: 'a-line-' + i,
				type: 'line',
				mode: 'horizontal',
				scaleID: 'y-axis-0',
				drawTime: 'afterDatasetsDraw',
				value: hours,
				borderColor: '#F44336',
				borderWidth: 2,
				borderDash: [1,2],
				label: {
					enabled: true,
					content: hours+ ' Hours',
					position: "right",
					fontStyle: "normal",
					fontSize: 10,
					xPadding: 3,
					yPadding: 3,
					cornerRadius: 3,
				}
			}
			new_annotations.push(annotations)
		})

		if (allocated_hours.length != 0){
			new_chart_max_value = Math.max(...allocated_hours)
			new_chart_max_value += 5
		}

		console.log(allocated_hours)
		console.log(new_chart_max_value)

		this.setState({ 
			chart_datasets: new_chart_dataset,
			annotations: new_annotations ,
			chart_max_value: new_chart_max_value,
		})

  }
  
  createChart = () => {
		const ctx = document.getElementById('time_tracking_1');

		const timeTrachking1 = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: this.state.sorted_date_range,
					datasets: this.state.chart_datasets,
				},
				plugins: [ChartAnnotation],
				options: {
					responsive: true,
					scales: {
						yAxes: [{
							// type: 'time',
							// time: {
							// 	unit: 'minute',
							// 	displayFormats: {
							// 		quarter: 'h:mm'
							// 	}
							// },
							scaleLabel: {
								display: true,
								labelString: 'Total Time Spent (Per Day)'
							},
							ticks: {
								beginAtZero: true,
								max: this.state.chart_max_value,
                min: 0,
                stepSize: 1,
							},
						}],
						xAxes: [{
							scaleLabel: {
								display: true,
								labelString: 'Days'
							},
						}]
					},
					title: {
			      display: true,
			      text: 'Graphical presentation of Time Tracking details'
			    },

			    annotation: {
						annotations: this.state.annotations
			    }
				},
				
		});
	}
	
  render () {
  	return <canvas id="time_tracking_1" className="w-p100"></canvas>;

  }
}

export default BarChart;


// const Colors = [
// 	['rgba(230, 32, 32, 0.4)', 'rgba(230, 32, 32, 0.7)'],
// 	['rgb(230, 21, 94, 0.4)', 'rgb(230, 21, 94, 0.7)'],
// 	['rgb(114, 49, 245, 0.4)', 'rgb(114, 49, 245, 0.7)'],
// 	['rgb(11, 105, 227, 0.4)', 'rgb(11, 105, 227, 0.7)'],
// 	['rgb(0, 125, 150, 0.4)', 'rgb(0, 125, 150, 0.7)'],
// 	['rgb(0, 140, 77, 0.4)', 'rgb(0, 140, 77, 0.7)'],
// 	['rgb(250, 167, 0, 0.4)', 'rgb(250, 167, 0, 0.7)'],
// 	['rgb(38, 50, 56, 0.4)', 'rgb(38, 50, 56, 0.7)'],
// 	['rgb(251, 0, 220, 0.4)', 'rgb(251, 0, 220, 0.7)'],
// 	['rgb(66, 66, 66, 0.4)', 'rgb(66, 66, 66, 0.7)'],
// 	['rgb(0, 188, 212, 0.4)', 'rgb(0, 188, 212, 0.7)']
// ]

const dynamicColors = () => {
	const randomColor = ColorCodes[Math.floor(Math.random()*ColorCodes.length)];
	const _toRgb = Color(randomColor).rgb().color
	const bgColor = 'rgba(' + _toRgb[0]+ ','+ _toRgb[1]+ ','+ _toRgb[2]+ ', 0.5)'
	const borderColor = 'rgba(' + _toRgb[0]+ ','+ _toRgb[1]+ ','+ _toRgb[2]+ ', 0.9)'

	return [bgColor,
					borderColor];
}