import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setSelectedInspectableSubSection,
        setInspectableSubSections } from '../../../duck/actions';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import {Button, Modal, Alert} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners'

class AuditSubSectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	is_submit_loading: false,
    	audit_sub_section_model_show: false,
    	selected_inspectable_sub_section: this.props.inspection.selected_inspectable_sub_section

    };

    this.handleChange = this.handleChange.bind(this)
    this.handleScoreChange = this.handleScoreChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleShow = this.handleShow.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  // componentDidMount = async () => {
   
  // }

  handleChange = event => {
    this.setState({
    	selected_inspectable_sub_section: {
    		...this.state.selected_inspectable_sub_section,
    		[event.target.name]: event.target.value
    	}
      
    });
  }

  handleScoreChange = score => {
  	this.setState({ 
  		selected_inspectable_sub_section: {
  			...this.state.selected_inspectable_sub_section,
  			score: score,
  		}
  	})
  }

  handleSubmit = () => {

    const self = this
    const inspectable_item = self.state.selected_inspectable_sub_section
    const {note, score} = inspectable_item
    const formData = new FormData();

    self.setState({ is_submit_loading: true })

    formData.append('inspectable_item[score]', score )
    formData.append('inspectable_item[note]', note )

    setupCSRFToken();

    axios.put(`/inspections/${inspectable_item.inspection_id}/audit_sub_section/${inspectable_item.inspectable_id}`, formData)
    .then(response => {

      const {inspectable_sub_sections} = this.props.inspection
      let inspectable_sub_sections_2 = inspectable_sub_sections.filter(ins_ss => ins_ss.id != response.data.inspectable_item.id )

      let new_inspectable_sub_sections = [
            ...inspectable_sub_sections_2,
            response.data.inspectable_item
          ]
     
      self.setState({ 
        is_submit_loading: false,
        audit_sub_section_model_show: false 
      })
      self.props.setInspectableSubSections(new_inspectable_sub_sections)
      self.props.checkSectionCompletable();
      self.props.checkInspectableSectionAudited();

      toastr.success("The Sub Section has been audited successfully!")
     
      self.setState({ 
      	is_submit_loading: false,
      	audit_sub_section_model_show: false 
      })

      self.props.handleSubSectionItemClick(response.data.inspectable_item)

    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")

      self.setState({ is_submit_loading: false })
    
    })
  }

  handleClose = () => this.setState({ audit_sub_section_model_show: false })
  handleShow = () => {
  	this.setState({ 
  		audit_sub_section_model_show: true,
      selected_inspectable_sub_section: this.props.inspection.selected_inspectable_sub_section,
  	})
  }

  render(){
  	const {	inspection } = this.props

  	const {
  		selected_inspectable_sub_section,
  		is_submit_loading, 
  		audit_sub_section_model_show
  	} = this.state

  	const {
  		note, 
  		score, 
  		status, 
  		item_name
  	} = selected_inspectable_sub_section

  	if (selected_inspectable_sub_section.id == null ){
  		return <div></div>
  	}

  	return(
	  	<React.Fragment>
	  		<span className="btn btn-link p-0" onClick={this.handleShow}>
	  			{status == "completed" ? "Re Audit" : "Audit"}</span>

	  		<Modal show={audit_sub_section_model_show} onHide={this.handleClose}>
	        
	        <Modal.Header closeButton>
	          <Modal.Title>{item_name}</Modal.Title>
	        </Modal.Header>

	        <Modal.Body>
	        	

	        	<form className="site-visit-form" 
	              onSubmit={this.handleSubmit}>

	            <div className="form-group">
	              <label className="d-block" >Your Score:</label>
	              <div className="btn-group w-p100">
	                <button type="button" 
	                				className={`btn btn-default btn-stroke w-p20 ${score == 1 ? "btn-primary" : ""}`}
	                				onClick={this.handleScoreChange.bind(this, 1)}	>
	                	<span className={`fa fa-star font-size-20 star-rating ${score == 1 ? "active" : ""}`}></span><br />
	                	Poor
	                </button>
	                
	                <button type="button" 
	                				className={`btn btn-default btn-stroke w-p20 ${score == 2 ? "btn-primary" : ""}`}
	                				onClick={this.handleScoreChange.bind(this, 2)}	>
	                	<span className={`fa fa-star font-size-20 star-rating ${score == 2 ? "active" : ""}`}></span><br />
	                	Fair
	                </button>

	                <button type="button" 
	                				className={`btn btn-default btn-stroke w-p20 ${score == 3 ? "btn-primary" : ""}`}
	                				onClick={this.handleScoreChange.bind(this, 3)}	>
	                	<span className={`fa fa-star font-size-20 star-rating ${score == 3 ? "active" : ""}`}></span><br />
	                	Good
	                </button>

	                <button type="button" 
	                				className={`btn btn-default btn-stroke w-p20 ${score == 4 ? "btn-primary" : ""}`}
	                				onClick={this.handleScoreChange.bind(this, 4)}	>
	                	<span className={`fa fa-star font-size-20 star-rating ${score == 4 ? "active" : ""}`}></span><br />
	                	Very Good
	                </button>

	                <button type="button" 
	                				className={`btn btn-default btn-stroke w-p20 ${score == 5 ? "btn-primary" : ""}`}
	                				onClick={this.handleScoreChange.bind(this, 5)}	>
	                	<span className={`fa fa-star font-size-20 star-rating ${score == 5 ? "active" : ""}`}></span><br />
	                	Excellent
	                </button>
	              </div>
	            </div>

	            <div className="form-group">
	              <label>Comment</label>
	              <textarea name="note" 
	                        className="form-control w-p100 h-min-150"
	                        onChange={this.handleChange}
	                        value={note || ""} ></textarea>
	            </div>

	          </form>

	        </Modal.Body>
	        
	        <div className="well well-sm p-20">
					  <strong><i className="fa fa-info-circle"></i> Please Note!</strong> <br />You are auditing a Section. This action will audit the allocated tasks at the same time. 
					  If you want to audit an individual task, Please close this and do it individually.
					</div>

	        <Modal.Footer>
	        	<div className="d-flex justify-content-end">
	        		<BeatLoader
	        			className="m-10"
	              sizeUnit={"px"}
	              size={7}
	              color={"#0d75e0"}
	              loading={is_submit_loading}
	            />
	            <Button className="btn btn-default btn-quirk" onClick={this.handleClose}>
		            Close
		          </Button>
		          <Button className="btn btn-primary btn-quirk" onClick={this.handleSubmit}>
		            Save Changes
		          </Button>
	        	</div>
	          
	        </Modal.Footer>
	      
	      </Modal>

	  	</React.Fragment>
	  )
  }
}

const mapStateToProps = state => {
  return {
    ...state.inspection,
  }
}

const mapDispatchToProps = dispatch => ({
  setSelectedInspectableSubSection: inspectable_sub_section => dispatch(setSelectedInspectableSubSection(inspectable_sub_section)),
  setInspectableSubSections: inspectable_sub_sections => dispatch(setInspectableSubSections(inspectable_sub_sections)),
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(AuditSubSectionForm);