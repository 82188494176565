import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Offcanvas,
  Modal
} from 'react-bootstrap';

import _ from "underscore";
import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';
import toastr from 'toastr';

import moment from "moment"

import Select from 'react-select';
import AsyncSelect from 'react-select/async';

const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
import shiftIconDefault from "assets/images/icons/shift-icon-default.jpeg"

const ShiftSaffFormModal = ({
  shift,
  isEdit,
}) => {
  
  const [show, setShow] = useState(false);
  const [existingStaff, setExistingStaff] = useState([])
  const [existingStaffLoadStatus, setExistingStaffLoadStatus] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState({})
  const [selectEmployeeType, setSelectEmployeeType] = useState("existing_staff")
  const [shiftDays, setShiftDays]           = useState({
                                                monday: _.contains(shift.recurring_days, "monday"),
                                                tuesday: _.contains(shift.recurring_days, "tuesday"),
                                                wednesday: _.contains(shift.recurring_days, "wednesday"),
                                                thursday: _.contains(shift.recurring_days, "thursday"),
                                                friday: _.contains(shift.recurring_days, "friday"),
                                                saturday: _.contains(shift.recurring_days, "saturday"),
                                                sunday: _.contains(shift.recurring_days, "sunday"),
                                              });
  const [workType, setWorkType] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [note, setNote] = useState("")

  const customStyles = {
    control: provided => ({
      border: "2px solid #dfe1e6",
      display: "flex",
      borderRadius: "3px",
    })
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchExistingStaffDetails() 
  }, []);

  useEffect(() => {
    matchParamsWithSelectedStaff()
  }, [selectedStaff]);

  const matchParamsWithSelectedStaff = () => {
    if (selectedStaff) {
      setWorkType(selectedStaff.work_type)
      setDisplayName(selectedStaff.display_as ? selectedStaff.display_as : "")
      setNote(selectedStaff.note)
      setShiftDays({
        monday: selectedStaff.work_days && selectedStaff.work_days.monday == "true",
        tuesday: selectedStaff.work_days && selectedStaff.work_days.tuesday == "true",
        wednesday: selectedStaff.work_days && selectedStaff.work_days.wednesday == "true",
        thursday: selectedStaff.work_days && selectedStaff.work_days.thursday == "true",
        friday: selectedStaff.work_days && selectedStaff.work_days.friday == "true",
        saturday: selectedStaff.work_days && selectedStaff.work_days.saturday == "true",
        sunday: selectedStaff.work_days && selectedStaff.work_days.sunday == "true",
      })
    }
    
  }

  
  const fetchExistingStaffDetails = () => {
    if(existingStaffLoadStatus == false) {
      axios.get(`/shifts/${shift.id}/get_site_staff.json`)
        .then(response => {
          setExistingStaff(response.data.existing_site_staff)
          setExistingStaffLoadStatus(true)
          //handleSelectOptionsForStaff(response.data.existing_site_staff)
          // setIsLoading(false)
        })
        .catch(error => {
          console.error(error.message)
          // setIsLoading(false)
        })
    }
    
    
  }

  const fetchNewStaffDetails = async inputString => {
    
    if(inputString.length > 2) {
      let staffList = []

      try {
        const response = await axios.get(`/shifts/${shift.id}/search_staff_by_keyword.json?q=${inputString}&site_id=${shift.site_id}`)
        staffList = await response.data.employees
        return staffList;

      } catch (err) {
          console.error(error.message)
      }
    }

    return staffList;
    
  }

  const handleChangeStaffTypeSlect = selectedOption => {
    setSelectEmployeeType(selectedOption.value)
  }

  const handleChange = async event => {
    let name = event.target.name
    let value = event.target.value
  }

  const handleChangeStaffSelect  = selectedOption => {
    setSelectedStaff(selectedOption)
  }

  const handleChangeNewStaffSelect = selectedOption => {

    let selected_user = selectedOption
    let new_designation = {
      user_id: selected_user && selected_user.user && selected_user.user.id, 
      shift_id: shift.id, 
      work_type: "", 
      display_as: "", 
      note: ""
    }

    setSelectedStaff(new_designation)

  }

  const handleShiftDayClick = selectedDay => {
    setShiftDays({ 
      ...shiftDays, 
      [selectedDay]: !shiftDays[selectedDay]}
    )
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (selectEmployeeType == "existing_staff") {
      updateShiftDesignation();
    } else {
      createShiftDesignation();
    }

    //handleClose()
  };

  const updateShiftDesignation = () => {
    // PUT 

    if (selectedStaff.id ) {
      const payload = {
        user_id: selectedStaff.user.id,
        shift_id: shift.id,
        work_type: workType, 
        monday: shiftDays.monday,
        tuesday: shiftDays.tuesday,
        wednesday: shiftDays.wednesday,
        thursday: shiftDays.thursday,
        friday: shiftDays.friday,
        display_as: displayName,
        note: note
      }
      setupCSRFToken();

      axios.put(`/designations/assignees/${selectedStaff.id}`, {designation: payload})
      .then(response => {
        toastr.success('Staff has been updated Successfully!')
        handleClose()
        window.location.reload()
      })
      .catch(error => {
        console.error(error.message)
        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
      })
    } else {
      toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
      return ""
    }
  }

  const createShiftDesignation = () => {
    // POST "/sites/476/designations/assignees"
    if (selectedStaff.user_id ) {
      const payload = {
        user_id: selectedStaff.user_id,
        shift_id: shift.id,
        work_type: workType, 
        monday: shiftDays.monday,
        tuesday: shiftDays.tuesday,
        wednesday: shiftDays.wednesday,
        thursday: shiftDays.thursday,
        friday: shiftDays.friday,
        display_as: displayName,
        note: note
      }
      setupCSRFToken();

      axios.post(`/sites/${shift.site_id}/designations/assignees`, {designation: payload})
      .then(response => {
        toastr.success('Staff has been allocated Successfully!')
        handleClose()
        window.location.reload()
      })
      .catch(error => {
        console.error(error.message)
        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
      })
    } else {
      toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
      return ""
    }
  }


  return (
    <>
      <Button variant="default" onClick={handleShow}>Add New Staff</Button>

      <Modal show={show}
        onHide={handleClose}
        backdrop="static"
        bsSize="lg"
        dialogClassName="w-min-1000">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton className="p-20">
            <Modal.Title>
              Add New Staff
            </Modal.Title>
            
          </Modal.Header>
          <Modal.Body className="p-20">
            <label className="font-weight-bold">Selected Shift</label>
            <div className="bg-grey-100 p-20 mb-30">
              <div className="row pb-3">
                <div className="col-md-6">
                  <div className="d-flex">
                    <div>
                      <div className="text-center img-thumb-box align-middle w-80 h-80 rounded overflow-hidden mr-10">
                        <img src={shiftIconDefault} className="img-thumb d-block w-p100" />
                      </div>
                    </div>
                    <div>
                      <h2 className="mt-0 mb-10 p-0 font-weight-500">{shift.name}</h2>
                      <p className="m-0 p-0"><strong>Site: </strong> <a href="#" className="">{shift.site.name}</a></p>
                      
                      <span className="label label-default">Open</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col border-end">
                      <h4 className="font-size-6 font-weight-500">Commencement Date</h4>
                      <p>{moment(shift.commencement_date).format('Do MMMM YYYY')}</p>
                    </div>

                    <div className="col border-end">
                      <h4 className="font-size-6 font-weight-500">Total hours per week</h4>
                      <p>{shift.total_hours_per_week ? shift.total_hours_per_week : "NA"}</p>
                    </div>

                    <div className="col">
                      <h4 className="font-size-6 font-weight-500">Frequency</h4>
                      <div className="info-group">
                        <div className="tasks-item-row">
                          <div className="task-details d-flex h-20">
                            {weekDays.map( (day, index) => {
                              return <span className={`task-frequency ${_.contains(shift.recurring_days, day) ? 'active' : ''}`} key={index}>{day.substring(0, 2)}</span>
                              } )}  
                          
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-8">
                <div className="row mb-20">
                  <div className="col-5">
                    <label className="font-weight-bold">How do you want to select?</label>
                    <Select options={[{
                            label: "Select from existing staff",
                            value: "existing_staff"
                          },
                          {
                            label: "Add New Staff",
                            value: "add_new_staff"
                          }]}
                          defaultValue={{
                            label: "Select from existing staff",
                            value: "existing_staff"
                          }}
                          inputClassName="w-200px mr-5"
                          styles={customStyles}
                          cacheOptions
                          onChange={selectedOption => handleChangeStaffTypeSlect(selectedOption)}

                    />
                  </div>
                </div>

                <div className="row mb-20">
                  <div className="col-8">
                    <label className="font-weight-bold">Select Employee</label>
                    { selectEmployeeType == "existing_staff" ?
                      <Select options={existingStaff}
                        className="w-200px mr-5"
                        styles={customStyles}
                        isClearable={true}
                        cacheOptions
                        onInputChange={fetchExistingStaffDetails}
                        onChange={selectedOption => handleChangeStaffSelect(selectedOption)}
                        formatOptionLabel={option => (
                          <div className="d-flex w-300">
                            <div className="w-50 h-50 mr-5">
                              <img className="img-circle d-block w-p100" src={option.user.avatar_url} alt="Img" />
                            </div>
                            <div className="">
                              <h4 className="media-heading text-primary">{option.user.full_name}</h4>
                              <p className="m-0 p-0"><strong>EMP. ID:</strong> {option.user.employee_id} | <strong>E:</strong> {option.user.email}</p>
                            </div>
                          </div>
                        )}

                      />
                    :
                      <AsyncSelect
                        cacheOptions
                        loadOptions={fetchNewStaffDetails}
                        styles={customStyles}
                        isClearable={true}
                        defaultOptions={existingStaff}
                        onChange={selectedOption => handleChangeNewStaffSelect(selectedOption)}
                        formatOptionLabel={option => (
                          <div>
                            <div className="d-flex w-300">
                              <div className="w-50 h-50 mr-5">
                                <img className="img-circle d-block w-p100" src={option.user.avatar_url} alt="Img" />
                              </div>
                              <div className="">
                                <h4 className="media-heading text-primary">{option.user.full_name}</h4>
                                <p className="m-0 p-0"><strong>EMP. ID:</strong> {option.user.employee_id} | <strong>E:</strong> {option.user.email}</p>
                              </div>
                            </div>
                          </div>
                        )}
                      />
                    }
                    
                    
                  </div>
                </div>

                <div className="form-group">
                  <label className="font-weight-bold d-block">Work Type</label>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label className="toggle radio">
                        <input className="toggle__input radio" 
                              type="radio" 
                              value={workType}
                              name="work_type"
                              checked={ workType == "permanent" ? true : false}
                              onChange={e => setWorkType("permanent")} />
                        <span className="toggle__label">
                          <span className="toggle__text">&nbsp; Permanent Cleaner</span>
                        </span>
                      </label>
                    </div>

                    <div className="form-group col-md-6">
                      <label className="toggle radio">
                        <input className="toggle__input radio" 
                              type="radio" 
                              value={workType}
                              name="work_type"
                              checked={ workType == "temprary" ? true : false}
                              onChange={e => setWorkType("temprary")} />
                        <span className="toggle__label">
                          <span className="toggle__text">&nbsp; Temporary Cleaner</span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group">

                  <div className="form-group mb-20">
                    <label className="form-label" htmlFor="shift_status">Select Shift Days</label>
                    <div className="date-grid fw-500">
                      {weekDays.map( (day, index) => {
                        return <span key={index} style={{borderWidth: '2px'}}
                              onClick={ e => handleShiftDayClick(day) }
                              className={`text-uppercase ${shiftDays[day] ? 'active' : ''}`}>{day.substring(0, 2)}</span>

                        } )}  
                    </div>
                    <p className="text-muted">Shift hours and Pay details will be set automatically.</p>
                  </div>

                  
                </div>

                <div className="form-group">
                  <label className="font-weight-bold">Display Name &nbsp;</label><span className="text-muted">Optional</span>
                  <input className="form-control" 
                        placeholder="Night Cleaner" 
                        type="text" 
                        name="designation[display_as]"
                        defaultValue={displayName}
                        value={displayName} 
                        onChange={e => setDisplayName(e.target.value)} />
                </div>

                <div className="form-group">
                  <label className="font-weight-bold">Note &nbsp;</label><span className="text-muted">Optional</span>
                  <textarea className="form-control w-p100" 
                            placeholder="Additional Note" 
                            name="designation[note]" 
                            defaultValue={note}
                            value={note}
                            onChange={e => setNote(e.target.value)}></textarea>
                </div>
              </div>
            </div>
            
          </Modal.Body>
          <Modal.Footer>
            <Button variant="default" onClick={handleClose}>
              Close
            </Button>
            { selectEmployeeType == "existing_staff" ? 
              <Button type="submit" className="btn btn-primary">Update Changes</Button>
            : <Button type="submit" className="btn btn-primary">Save Changes</Button>
            }
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

export default ShiftSaffFormModal;