import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import {Button, Modal, Alert} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners'

import AsyncSelect from 'react-select';

class AssignNewSite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      designation_id: this.props.employee_id,
      user_id: this.props.user_id,
      show_assign_site_form: false,
      is_submit_loading: false,
      clients: [],
      sites: [],
      inputValue: ""
    };
    
  }

  componentDidMount = async () => {
  	//await this.getUserDetails();
  }

  fetchClients = () => {

  	if (this.state.clients.length == 0) {
  		// axios.get(`/staffs/${desig_id}/assigned_sites.json`)
    //   .then(response => {

    //     this.setState({
    //     	designation: response.data.designation,
    //       user: response.data.user,
    //       site_designations: response.data.site_designations,
    //       is_loading: false,
    //     })
        
    //   })
    //   .catch(error => {
    //     console.error(error.message)
    //     this.setState({
    //       is_loading: false,
    //     })
    //   })
  	}
    // const desig_id = this.props.match.params.desig_id
    
    // if (desig_id != undefined){
    //   axios.get(`/staffs/${desig_id}/assigned_sites.json`)
    //   .then(response => {

    //     this.setState({
    //     	designation: response.data.designation,
    //       user: response.data.user,
    //       site_designations: response.data.site_designations,
    //       is_loading: false,
    //     })
        
    //   })
    //   .catch(error => {
    //     console.error(error.message)
    //     this.setState({
    //       is_loading: false,
    //     })
    //   })
    // }

  }

  handleSubmit = () => {

  }

  handleClose = () => this.setState({ show_assign_site_form: false })
  
  handleShow = () => this.setState({ show_assign_site_form: true })

  handleInputChange = newValue => {
    const inputValue = newValue.replace(/\W/g, '');

    this.setState({ inputValue });
    
    // if (inputValue.length > 1) {
    // 	axios.get(`/staffs/${this.state.designation_id}/assigned_sites.json?`)
    //   .then(response => {

    //     this.setState({
    //     	designation: response.data.designation,
    //       user: response.data.user,
    //       site_designations: response.data.site_designations,
    //       is_loading: false,
    //     })
        
    //   })
    //   .catch(error => {
    //     console.error(error.message)
    //     this.setState({
    //       is_loading: false,
    //     })
    //   })
    // }
    return inputValue;
  }

  

  render() {

  	const {
  		is_loading,
  		show_assign_site_form,
  		is_submit_loading,
  		clients,
  		sites
  		// user,
  		// designation,
  		// site_designations,
  	} = this.state

  	return (
	  		<React.Fragment>
		  		<ul className="panel-options">
			      <li>
			       	<Button variant="primary" onClick={this.handleShow}>
				        Assign New Site
				      </Button>
			      </li>
			    </ul>
				
				
					<Modal show={show_assign_site_form} 
          	onHide={() => this.handleClose() } className="model-primary">
	        
		        <Modal.Header closeButton>
		          <Modal.Title>Assign New Site</Modal.Title>
		        </Modal.Header>

		        <Modal.Body>

		        	<div className="form-group">
		        		<label>Select Client </label>
		        		<pre>inputValue: "{this.state.inputValue}"</pre>
		        		<AsyncSelect
				          cacheOptions
				          loadOptions={clients}
				          defaultOptions
				          onInputChange={this.handleInputChange} />
		        	</div>
		        

		        </Modal.Body>
		        

		        <Modal.Footer>
		        	<div className="d-flex justify-content-end">
		        		<BeatLoader
		        			className="m-10"
		              sizeUnit={"px"}
		              size={7}
		              color={"#0d75e0"}
		              loading={is_submit_loading}
		            />
		            <Button className="btn btn-default btn-quirk" 
	                onClick={()=> this.handleClose()}>
			            Close
			          </Button>
			          <Button className="btn btn-primary btn-quirk" onClick={this.handleSubmit}>
			            Save & Submit
			          </Button>
		        	</div>
		          
		        </Modal.Footer>
		      
		      </Modal>
						
	  		</React.Fragment>
	  	)

  }
}


export default AssignNewSite;
