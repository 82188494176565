import React, { useState, useEffect } from 'react';

import _ from "underscore";
import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';
import toastr from 'toastr';

import ShiftSaffFormModal from "./form_model";

const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]

const ShiftStaffDetails = (props) => {
  // let {site_id} = useParams();

  const {
    shift 
  } = props

  const [shift_staff, setShiftStaff] = useState([])

  useEffect(() => {
    fetchShiftStaffDetails() 
  }, []);

  const fetchShiftStaffDetails = () => {
    axios.get(`/shifts/${shift.id}/staff_details.json`)
      .then(response => {
        setShiftStaff(response.data.shift.designations)
        // setIsLoading(false)
      })
      .catch(error => {
        console.error(error.message)
        // setIsLoading(false)
      })
  }

  const handleShiftStaffDeleteClick = designation_id => {
    
    if(window.confirm('Are you sure want to delete the item?')){

      setupCSRFToken();

      axios.delete(`/designations/assignees/${designation_id}`)
      .then(response => {
        let designations = shift_staff.filter( staff => staff.id != designation_id );
        setShiftStaff(designations)

        toastr.success('Staff has been removed Successfully!')
      })
      .catch(error => {
        console.error(error.message)
      })
    }
  }

  return (
    <React.Fragment>
      <section>
        <div className="section-header d-flex justify-content-between align-items-center mb-4">
            <div>
              <h4 className="fs-14 m-0">Shift Staff Details</h4>
            </div>
            <div>
              <ShiftSaffFormModal shift={shift}/>
            </div>
          </div>
        <table className="table table-default table-striped table-hover">
          <thead>
            <tr>
              <th>Emp. ID</th>
              <th>Staff Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Work Type</th>
              <th>Assigned Days</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {shift_staff.map( (d, index) => {
              return (
                <tr key={index}>
                  <td className="w-100"><strong>{d.user.employee_id}</strong></td>
                  <td>
                    <div className="d-flex w-200">
                      <div className="w-50 h-50 mr-5">
                        <img className="img-circle d-block w-p100" src={d.user.avatar_url} alt="Img" />
                      </div>
                      <div className="">
                        <h4 className="media-heading">{d.user.full_name}</h4>
                        <p className="media-usermeta text-muted font-size-12"><i className="glyphicon glyphicon-briefcase"></i> {d.desplay_name}</p>
                      </div>
                    </div>
                  </td>
                  <td>{d.user.email}</td>
                  <td>{d.user.phone}</td>
                  <td>{d.work_type}</td>
                  <td>
                    <div className="info-group">
                      <div className="tasks-item-row">
                        <div className="task-details d-flex h-20">
                          {weekDays.map( (day, index) => {
                            return <span key={index} className={`task-frequency ${d.work_days && d.work_days[day] == 'true' ? 'active' : ''}`} key={index}>{day.substring(0, 2)}</span>
                            } )}  
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="w-150 ">
                    <div className="d-flex btn-group mr5">

                      <a className="btn btn-default btn-sm mr-1" href={`/staffs/${d.id}?site_id=${d.site_id}`}>View Profile</a>
                      <div className="dropdown">
                        <button className="btn btn-default btn-sm dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <button className="dropdown-item text-danger" onClick={e => handleShiftStaffDeleteClick(d.id) }>Delete</button>
                        </div>
                      </div>
                      {/*<button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>*/}
                      
                      {/*<div className="list-group dropdown-menu position-right w-min-300" style={{padding: 0, border: 'none'}} role="menu">
                        <a className="list-group-item list-group-item-action flex-column align-items-start" href="#">
                          <h5 className="mb-1">Resend Site Instructions</h5>
                          <p className="mb-1">Resend instructions for Site allocation and Mobile app</p>
                        </a>

                        <a className="list-group-item list-group-item-action flex-column align-items-start" href="#">
                          <h5 className="mb-1">Edit information</h5>
                          <p className="mb-1">Edit Site Allocation details such as Hours, Dates etc.</p>
                        </a>
                        <a data-confirm="Are you sure you want to delete this?" className="list-group-item list-group-item-action flex-column align-items-start" rel="nofollow" data-method="delete" href="#">
                          <h5 className="mb-1">Remove Designation</h5>
                          <p className="mb-1">Remove this cleaner from this site.</p>
                        </a>            
                      </div>*/}
                      
                    </div>
                  </td>
                </tr>
              )
            } )}
            
          </tbody>
        </table>
      </section>
    </React.Fragment>
  )
}

export default ShiftStaffDetails