import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import ListLoader from 'components/common/list_loader';

import ReportHeader from './components/_header'
import AuditableItems from './components/_auditable_items'

import UserSignaturePad from './components/user_signature_pad';

class OHSAudits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      ohs_audit: {},
      site: {},
      client: {},
      ohs_auditable_items: []
    };
    
  }

  componentDidMount = () => {
  	this.getOhsAudits();
  }

  getOhsAudits = () => {
    const ohs_audit_id = this.props.match.params.ohs_audit_id
    
    if (ohs_audit_id != undefined){
      axios.get(`/ohs_audits/${ohs_audit_id}/edit.json`)
      .then(response => {
        this.setState({
          ohs_audit: response.data.ohs_audit,
          site: response.data.site,
          client: response.data.client,
          ohs_auditable_items: response.data.ohs_auditable_items,
          is_loading: false,
        })
        
      })
      .catch(error => {
        console.error(error.message)
        this.setState({
          is_loading: false,
        })
      })
    }

  }

  render() {

  	if (this.state.is_loading){
  		return (
  			<div className="panel-body">
  				<ul style={{listStyleType: "none"}}>
  					<ListLoader times={4} />
  				</ul>
  			</div>
  		)
  	}
  	else{
  		return (
	      <React.Fragment>
	        <ReportHeader
	         	{...this.props}
	         	ohs_audit={this.state.ohs_audit} />
	        
	        <hr />

	        <div className="panel-body">
	          <AuditableItems
	          	ohs_audit={this.state.ohs_audit}
	          	ohs_auditable_items={this.state.ohs_auditable_items} />

	        </div>

          <UserSignaturePad 
            {...this.props}
            ohs_audit={this.state.ohs_audit} />

	      </React.Fragment>
	   
	    );
  	}
  
    
  }
}


export default OHSAudits;
