import React from 'react';

//import BarChart from './_bar_chart';
//import TableView from './_table_view';
//import StaffItem from './_staff_item';

//import { MDBDataTable } from 'mdbreact';
import $ from 'jquery';
//import DataTable from 'datatables.net'
import moment from 'moment';

class TimeTrackingDataTable extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	cleaners: this.props.cleaners,
      //time_trackings: this.props.time_trackings,
      sorted_date_range: this.props.sorted_date_range,
      table_data: []
      //allocated_hours: this.props.allocated_hours,
      //site: this.props.site,
      //date_range: this.props.date_range
    };

  }

  componentDidMount = () => {
    // $('#time_tracking_table').DataTable({
    //   'order': [[1, 'desc']]
    // });
  }

  
  render () {
    const {
      sorted_date_range,
      time_trackings
    } = this.props
    //$.DataTable = require('datatables.net');


  	return (
      <React.Fragment>
	      <div className="panel">
          <div className="panel-heading pb-0">
            <h4 className="panel-title">Time Tracking Details</h4>
          </div>

          <div className="panel-body">

            <table className="table table-default table-hover table-striped" id="time_tracking_table">
              <thead>
                <tr>
                  <th>Date</th>   
                  <th className="w-200">User</th>   
                  <th className="bg-blue-grey-200 blue-grey-700">Checkin Time</th>
                  <th className="bg-blue-grey-200 blue-grey-700">Location</th>
                  <th className="bg-blue-grey-300 blue-grey-700">Checkout Time</th>
                  <th className="bg-blue-grey-300 blue-grey-700">Location</th>
                  <th className="font-size-10">Time Spent <br />HH.MM</th>
                  <th>Device ID</th>
                </tr>
              </thead>

              <tbody>
                {time_trackings.map( (tracking_item,i) => {
                  return <TableRow
                          key={i}
                          tracking_item={tracking_item} />

                  })
                }
                
              </tbody>
            </table>
            

          </div>
        </div>
			</React.Fragment>
   
    );

  }
}


const TableRow = ({tracking_item}) => {
  const validateLable = validity => {
    if (validity == true){
      return <small className="label label-primary">GPS: TRUE</small>
    } 
    else if (validity == false){
      return <small className="label label-danger">GPS: FALSE</small>
    } else if (validity == "WIP") {
      return <small className="label label-warning">WIP</small>
    } else {
      return ""
    }
  }

  const isSunday = () => {
    
    if (moment(tracking_item.date, 'DD-MM-YYYY').weekday() == 0){
      return 'bg-blue-grey-100'
    }else{
      return ''
    }
  }

  return (
    <tr className={`sdfsd ${isSunday()}`}>
      <td className="w-100 font-size-10"><strong>{tracking_item.date}</strong></td>
      <td className="w-200"><strong>{tracking_item.cleaner}</strong></td>
      <td className="font-size-10">{tracking_item.checkin_time}</td>
      <td className="font-size-10">
        {tracking_item.checkin_location}<br />
          {validateLable(tracking_item.checkin_gps)}
      </td>
      <td className="font-size-10">
          {tracking_item.checkout_time}
      </td>
      <td className="font-size-10">
          {tracking_item.checkout_location}<br />

          {validateLable(tracking_item.checkout_gps)}

      </td>
      <td className="font-size-12 w-100">
          {tracking_item.total_time_spent}
      </td>
      <td className="font-size-10">{tracking_item.device_id}</td>
      
    </tr>
  )
}

export default TimeTrackingDataTable;




