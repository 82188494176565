import React from 'react'
import PropTypes from 'prop-types';
// import toastr from 'toastr';

import NewCommentForm from './new_comment_form';
import ToolboxMeetingCommentItem from './toolbox_meeting_comment_item'

// import axios from 'axios';
// import setupCSRFToken from 'config/setupCSRFToken'
// import serialize from 'form-serialize';

class ToolboxMeetingComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: this.props.comments,
      site: this.props.site,
      toolbox_meeting: this.props.toolbox_meeting,
    };

    this.unSetComment = this.unSetComment.bind(this)
  }

  setNewComment = comment => {
    this.setState({
      comments: [
        ...this.state.comments,
        comment
      ],
    })
  }

  //Delete a comment
  unSetComment = comment_id => {
    console.log(comment_id)
    let comments = this.state.comments.filter(comment => {
      return comment.id !== comment_id
    })

    this.setState({
      comments: comments
    })
  }

  render () {

    return (
      <section className="report-comments">
        <h4>Discussion Summary</h4>
        
        {this.state.comments.map((comment, index) => (
          <ToolboxMeetingCommentItem 
                    comment={comment} 
                    key={index}
                    unSetComment={this.unSetComment} />
        ))}

        <NewCommentForm 
          site={this.state.site}
          toolbox_meeting={this.state.toolbox_meeting}
          setNewComment={this.setNewComment} />

      </section>
    );
    

  }
}

export default ToolboxMeetingComments
