import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import highchartsGantt from "highcharts/modules/gantt";
import HC_exporting from 'highcharts/modules/exporting'

import moment from 'moment'


//import ScheduledJobsSummary from './components/scheduled_jobs_summary';
//require("highcharts/modules/gantt")(Highcharts);

class ScheduledJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      scheduled_jobs: [],
      sites: [],
      chartDataSeries: [],
      is_gantt_chart_ready: false,
    };
    
  }

  componentDidMount = () => {
  	this.getScheduledJobs()
  }

  getScheduledJobs = () => {
    
    axios.get(`/scheduled_jobs.json`)
      .then(response => {
        
        this.setState({
          scheduled_jobs: response.data.scheduled_jobs,
          sites: response.data.sites,
          is_loading: false,
        })

        this.createChartDataSeries()
        
      })
      .catch(error => {
        console.error(error.message)
        this.setState({
          is_loading: false,
        })
      })
  }

  createChartDataSeries = () => {
  	const {
  		scheduled_jobs,
  		sites,
  		is_gantt_chart_ready
  	} = this.state

  	const series = sites.map((site, i) => {

  		let site_scheduled_jobs = scheduled_jobs.filter( (job) => { return job.site_id == site.id } )

  		let assignees = []

  		if (site_scheduled_jobs.length > 0 ) {
												
				site_scheduled_jobs[0].recurring_event.assigned_users.map( assignee => {
					assignees.push(assignee.user.first_name) 
				})
				
			}

			return {
				site_name: site.name,
				assignees: assignees,
				total_jobs: site_scheduled_jobs.length,
				total_completed: site_scheduled_jobs.filter( job => { return job.status == "completed"}).length,
				data: site_scheduled_jobs.map( (job, k) => {
					return {
						id: 'job-' + i,
						title: job.job_type,
						site_name: site.name,
						assignees: assignees,
						start: moment(job.start_time).valueOf(),
						status: job.status,
						end: moment(job.end_time).valueOf(),
						color: function(){
							let color = job.color
							if (moment().format() > moment(job.end_time).format() && job.status == "completed"){
								color = "#6d6e71"
							} else if (moment().format() > moment(job.end_time).format() ) {
								color = "#f44336"
							}
							return color;
						}(),
						y: i
					}
				})
			}
		})

		this.setState({
			chartDataSeries: series,
			is_gantt_chart_ready: true
		})

  	if(is_gantt_chart_ready ){
  		loadGanttChart();
  	}
  }

  loadGanttChart = () => {
  	const {
  		chartDataSeries
  	} = this.state

  }


  render() {
  	const {
  		is_loading,
  		chartDataSeries,
  		sites,
  		scheduled_jobs
  	} = this.state

		const ganttChartOptions = {
					    series: chartDataSeries,
					    lineColor: '#333333',
					    borderColor: '#333333',
					    exporting: {
					    	menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"],
						    sourceWidth: 3000,
						    buttons: {
									contextButton: {
							      menuItems: [{
											textKey: 'printChart',
											onclick: function () {
												this.print();
											}
										}, {
											separator: true
										}, {
											textKey: 'downloadPNG',
											onclick: function () {
												this.exportChart();
											}
										}, {
											textKey: 'downloadJPEG',
											onclick: function () {
												this.exportChart({
													type: 'image/jpeg'
												});
											}
										}, {
											separator: true
										},{
											textKey: 'downloadPDF',
											onclick: function () {
												this.exportChart({
													type: 'application/pdf'
												});
											}
										}, {
											textKey: 'downloadSVG',
											onclick: function () {
												this.exportChart({
													type: 'image/svg+xml'
												});
											}
										}]
									}
								}
						  },
					    title: {
					      text: 'Scheduled Inspections'
					    },
					    tooltip: {
					      pointFormat: '<span>Site Name: {point.site_name}</span><br/><span>Job Type: {point.title}</span><br/><span>From: {point.start:%e. %b}</span><br/><span>To: {point.end:%e. %b}</span><br/><span>Assigned To: {point.assignees}</span><br/><span>Status: {point.status}</span>'
					    },
					    xAxis: {
					    	tickColor: '#333333',
					      currentDateIndicator: true,
					      lineColor: "#333333",
					      gridLineColor: "#333333",
					      grid: {
					      	enabled: true,
					      	borderColor: '#333333'
					      }
					    },
					    navigator: {
					        enabled: true,
					        liveRedraw: true,
					        series: {
					            type: 'gantt',
					            pointPlacement: 0.5,
					            pointPadding: 0.25
					        },
					        yAxis: {
				            min: 0,
				            max: 3,
				            reversed: true,
				            categories: []
					        }
					    },
					    scrollbar: {
					        enabled: true
					    },
					    rangeSelector: {
					        enabled: true,
					        selected: 0
					    },
					    yAxis: {
				        type: 'category',
				        gridLineColor: "#333333",
				        lineColor: "#333333",
				        grid: {
				        	enabled: true,
				        	borderColor: '#333333',
				          columns: [{
			              title: {
			                text: 'Site Name'
			              },
			              categories: chartDataSeries.map( s => {
			                return s.site_name;
			              })
				          }, {
		                title: {
		                    text: 'Assigned To'
		                },
		                categories: chartDataSeries.map(s => {
		                    return s.assignees;
		                })
			            },
			            {
		                title: {
		                  text: 'Total Scheduled'
		                },
		                categories: chartDataSeries.map(s => {
		                    return s.total_jobs;
		                })
			            },
			            {
		                title: {
		                  text: 'Total Completed'
		                },
		                categories: chartDataSeries.map(s => {
		                    return s.total_completed;
		                })
			            }],
			            borderColor: '#333333'
				        },
				        max: sites.length,
					    },
						};

		highchartsGantt(Highcharts);
		HC_exporting(Highcharts)

  	if (is_loading) {
  		return "Loading..."
  	}else {
  		return (
	      <React.Fragment>
	      	<HighchartsReact
				    highcharts={Highcharts}
				    constructorType={'ganttChart'}
				    options={ganttChartOptions}
				  />

				 	{/*<button classNamee="btn btn-primary" onClick={ () => this.handleDownloadPdf() }>Download As PDF</button>*/}

				 	{/*<ScheduledJobsSummary
				 		sites={sites} 
				 		scheduled_jobs={scheduled_jobs} />*/}
	      </React.Fragment>
	   
	    );
  	}

  	
  
    
  }
}


export default ScheduledJob;
