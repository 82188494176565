import React, { Component } from 'react'

import AsyncSelect from 'react-select/async';
import axios from 'axios';
import _ from 'underscore';

class SearchUser extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	    users: [],
	    usersLoaded: false,
	    inputValue: '',
	  };
	}


	fetchUsers = inputValue => {
		if (this.state.usersLoaded) {
			return this.state.users.filter(i =>
		    i.label.toLowerCase().includes(inputValue.toLowerCase())
		  );
		}else{
			axios.get('/ams/issuings/search_users?q=' + inputValue)
	    .then(res => {

			  let options = [];
			  $.each( JSON.parse(res.data.users), function( key, val) {
			    options.push( {value: val["id"], label: val["first_name"] + " " + val["last_name"]});
			  });

			  this.setState({
	    		usersLoaded: true,
	    		users: options,
	    	})
	    	return options
	    	
	    })
	    .then( options => {
	    	return options.filter(i =>
			    i.label.toLowerCase().includes(inputValue.toLowerCase())
			  );
	    })
		}
	  
	};

	loadOptions = (inputValue, callback) => {
	  if (inputValue.length >= 4 ){
	  	callback(this.fetchUsers(inputValue));
	  }else{
	  	this.setState({
	  		usersLoaded:false,
	  	})
	  }

	};

	handleInputChange = newValue => {
    this.setState({ newValue });
    return newValue;
  };

  render(){
    return (
    	<div>
    		<label>Issued For:</label>
    		<AsyncSelect
		          cacheOptions
		          loadOptions={this.loadOptions}
		          defaultOptions
		          onInputChange={this.handleInputChange}
							name="assets_issuing[issued_for]"
							autofocus
							simpleValue
		        />
		    <small>Type Email, First Name or Last Name</small>
    	</div>
    )
  }
}

export default SearchUser;