import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

const TasksMappingItemForm = ({
  form_title,
  task_name,
  frequency_type,

  sunday,
  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,

  handleChange,
  handleFrequencyClick

}) => {
  return (
    <React.Fragment>
      <div className="form-group">
        <input type="text" 
              name="task_name" 
              className="form-control w-p100" 
              required="required" 
              autoFocus
              value={task_name}
              onChange={e => handleChange(e)} />
      </div>

      <div className="row">
        <div className="form-group col-md-6">
          <select className="form-control" 
                      tabIndex="8" 
                      name="frequency_type"
                      value={frequency_type}
                      onChange={e => handleChange(e)}>

            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
            <option value="fuarterly">Fortnightly</option>
            <option value="twice_a_week">2/Week</option>
            <option value="monthly">Monthly</option>
            <option value="termly">Termly</option>
            <option value="yearly">Yearly</option>
            <option value="half_yearly">Half Yearly</option>
            <option value="quarterly">Quarterly</option>
            <option value="as_required">As Required</option>
          </select>
        </div>

        <div className="form-group col-md-6">
          <span className={`task-frequency w-30 ${Number(sunday) === 1 ? "active" : ""}`}
            onClick={e => handleFrequencyClick({
              target: {
                name: "sunday",
                value: sunday,
              },
            }) } >SU</span>

          <span className={`task-frequency w-30 ${Number(monday) === 1 ? "active" : ""}`}
              onClick={e => handleFrequencyClick({
                target: {
                  name: "monday",
                  value: monday,
                },
              })} >MO</span>
          
          <span className={`task-frequency w-30 ${Number(tuesday) === 1 ? "active" : ""}`}
              onClick={e => handleFrequencyClick({
                target: {
                  name: "tuesday",
                  value: tuesday,
                },
              })} >TU</span>
          
          <span className={`task-frequency w-30 ${Number(wednesday) === 1 ? "active" : ""}`}
              onClick={e => handleFrequencyClick({
                target: {
                  name: "wednesday",
                  value: wednesday,
                },
              })} >WE</span>
          
          <span className={`task-frequency w-30 ${Number(thursday) === 1 ? "active" : ""}`}
              onClick={e => handleFrequencyClick({
                target: {
                  name: "thursday",
                  value: thursday,
                },
              })} >TH</span>

          <span className={`task-frequency w-30 ${Number(friday) === 1 ? "active" : ""}`}
              onClick={e => handleFrequencyClick({
                target: {
                  name: "friday",
                  value: friday,
                },
              })} >FR</span>

          <span className={`task-frequency w-30 ${Number(saturday) === 1 ? "active" : ""}`}
              onClick={e => handleFrequencyClick({
                target: {
                  name: "saturday",
                  value: saturday,
                },
              })} >SA</span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TasksMappingItemForm;