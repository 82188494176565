import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import Dropzone from 'react-dropzone';
//import Jimp from 'jimp';

import imageCompression from 'browser-image-compression';

import { BeatLoader } from 'react-spinners';
import _ from 'underscore';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

import CommentAttachmentItem from './comment_attachment_item'

class CommentAttachments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment_id: this.props.comment_id,
      attachments: this.props.attachments || [],
      is_loading: false,
      loading_images_count: 0,
    };

    this.onImageDrop = this.onImageDrop.bind(this)
  }

  onImageDrop = files => {
    
    const comment_id = this.state.comment_id
    let attachments = this.state.attachments
    let self = this;
    
    self.setState({ loading_images_count: files.length })

    _.each(files, (file,index) => {
      
      const fd = new FormData();
      let files_length = files.length
      let i = 0;
      
      let imageResizeOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true
      }

      imageCompression(file, imageResizeOptions)
      .then(function (compressedFile) {

        fd.append('attachment[file]', compressedFile, file.name)
        fd.append('attachment[attachable_id]', comment_id)
        fd.append('attachment[attachable_type]', 'Comment')
         
        setupCSRFToken();
        
        axios.post('/attachments/', fd)
        .then(response => {
          attachments.push(response.data)

          self.setState({
            attachments: attachments,
            loading_images_count: (self.state.loading_images_count - 1),
          })

          console.log(self.state.loading_images_count)

          // toastr.success("New attachments have been uploaded successfully")
            
        })
        .catch(error => {
          console.error(error.message)
        })

      })
      
      .catch(function (error) {
        console.error(error.message);
        toastr.error("An error occurred during the process")
      });

    })

    
  }

  render () {
    const {attachments, is_loading, loading_images_count} = this.state

    return (
      <div>
        <div className="row">
          {attachments.map((attachment, index) => (
            <CommentAttachmentItem
              attachment={attachment}
              key={index} />
          ))}

          {[...Array(loading_images_count)].map((count, key) => (
            <div className="comment-attachments-column col-xs-3 col-md-2" key={key}>
              <div className="comment-attachment-wrapper loading">
                <BeatLoader
                  sizeUnit={"px"}
                  size={7}
                  color={"#a6b0c5"}
                  loading={true}
                />
              </div>
            </div>
          ))}
        </div>

        <Dropzone
          onDrop={this.onImageDrop}
          accept="image/*"
          multiple={true}
          uploadMultiple={true}>
            {({getRootProps, getInputProps}) => {
              return (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div>
                      <button className="btn btn-default"><i className="fa fa-upload"></i>Upload Photos</button>
                      <p>Drag files here, or click to select files</p>
                    </div>
                  </div>
                </section>
              )
            }}
        </Dropzone>
      </div>
    )
  }
}

export default CommentAttachments
