import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import { Switch, Route } from 'react-router-dom'

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';


import ScheduledJobPreview from './preview'

const ScheduledJob = props => {

  return(
    <React.Fragment>
      <Route exact path={["/scheduled_jobs/:scheduled_job_id/preview"]} component={ScheduledJobPreview} />
    </React.Fragment>
  )
}



export default ScheduledJob;