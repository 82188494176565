import React from 'react'

import ListLoader from 'components/common/list_loader'

const CompleteLoadingPage = () => {

	return (
    <React.Fragment>
      <h4 className="panel-title mb10">Inspection</h4>
      
      <div className="row no-gutters">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="panel panel-specifications col-md-12 h-min-500">
            <div className="row">
              <div className="col-md-4 panel-sp-left pr-md-0">
           
                <div className="sections-list-wrapper">
                  <div className="list-header">
                    <div className="loading-wrapper-cell">
											<div className="image"></div>
											<div className="text">
												<div className="text-line"></div>
												<div className="text-line w-p50"></div>
											</div>
										</div>
                  </div>
                  <ul className="sections-list">
                    <ListLoader times={4}/>
                  </ul>
                </div>

              </div>

              <div className="col-md-8 panel-sp-right">
              	<div className="list-header p-20">
					        <div className="loading-wrapper-cell">
										<div className="image"></div>
										<div className="text">
											<div className="text-line"></div>
											<div className="text-line w-p50"></div>
										</div>
									</div>
					      </div>

					      <div className="tasks-details">
					      	
					        <div className="panel-tasks">
					          <div className="panel-body">
					            <div className="tasks-wrapper">

					              <ul className="list-group list-tasks-mappings list-group-striped" style={{listStyleType: "none"}}>

								          <ListLoader times={4}/>
								       
								        </ul>

					            </div>
					          </div>
					        </div>

					      </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

export default CompleteLoadingPage;