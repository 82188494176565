import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import {Button, Modal, Alert} from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';


class UserSignaturePad extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      modelShow: false,
      trimmedDataURL: this.props.ohs_audit.signature || null,
      ohs_audit: this.props.ohs_audit,
    };

    this.handleSignatureRemove = this.handleSignatureRemove.bind(this)
    
  }

  sigPad = {}

  handleClose = () => this.setState({ modelShow: false});
  handleShow = () => this.setState({ modelShow: true});

  handleSubmit = () => {
    const signatureDataUrl = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
    const formData  = new FormData();
    formData.append('ohs_audit[signature]', signatureDataUrl )

    setupCSRFToken();

    axios.put(`/ohs_audits/${this.state.ohs_audit.id}`, formData)
    .then(response => {
      this.setState({ 
        trimmedDataURL: signatureDataUrl,
         modelShow: false,
       })
    })
    .catch(error => {
      console.log("error catched")
      console.log(error)

      toastr.error( "Please check the inputs and submit the form again.",
                    "An error occurred during the process")
 
    })
  }

  handleSignatureRemove = e => {
  	e.preventDefault()

    if(window.confirm('Are you sure to delete this record?')){ 
      const formData  = new FormData();
      formData.append('ohs_audit[signature]', "" )

      setupCSRFToken();

      axios.put(`/ohs_audits/${this.state.ohs_audit.id}`, formData)
      .then(response => {
        this.setState({ 
          trimmedDataURL: null,
         })
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })
    }
  	
    

  }

  render() {

  	const { 
  		trimmedDataURL,
  		modelShow } = this.state

  	return(
  		<div className="panel-body">
  			<h4>Your Signature</h4>
  			
  			<div>
  				{trimmedDataURL
	        ? <div className="signature-wrapper">
	        		<img className="signature" style={{width: '130px', height: 'auto'}}
	          		src={trimmedDataURL} />

	          	<a href="#" style={{verticalAlign: "bottom"}} onClick={this.handleSignatureRemove}>Remove</a>
	        	</div>
	        
	        :
          <Button className="btn btn-primary btn-quirk"
                onClick={this.handleShow} >Sign Here</Button>
          }
  			</div>

        


			  <Modal show={modelShow} onHide={this.handleClose}>
	        
	        <Modal.Header closeButton>
	          <Modal.Title>Your Signature</Modal.Title>
	
	        </Modal.Header>

	        <Modal.Body>

    				<SignaturePad 
    					canvasProps={{className: 'w-p100 h-300 sigCanvas',
    												style: {background: '#f6f8fa'} }}
          		ref={(ref) => { this.sigPad = ref }} />

	        </Modal.Body>
	        
	        <Modal.Footer>
	        	<Button className="btn btn-default btn-quirk" onClick={this.handleClose}>
	            Cancel
	          </Button>
	          
	          <Button className="btn btn-primary btn-quirk" onClick={this.handleSubmit}>
	            Save Changes
	          </Button>
	          
	        </Modal.Footer>
	      
	      </Modal>
  		</div>
  		
  	)
  }
}

export default UserSignaturePad;