import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import _ from 'underscore';

import DocumentsListItem from './_documents_list_item';

const DocumentsPanel = ({
  user,
  designation,
  user_documents,
  current_stage,
  document_types,

  updateUserDocuments,
  removeUserDocuments,
  handleCurrentStageChange

}) => {

  if (current_stage == "documents"){
    return (
      <React.Fragment>
        <hr className="m-0" />
        <div className="panel-heading active">
          <div className="d-flex">
            <span className="icon-button font-weight-bold mt-5">2</span>
            <div className="ml-10">
              <h3 className="m-0">Setup Required Documents</h3>
              <p>Set up a list of required documents.</p>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <ul className="list-group">
              
            {_.map(document_types, (name, document_type) => {
              return (
                <DocumentsListItem
                  key={document_type}
                  document_type={document_type}
                  document_name={name}
                  user_documents={user_documents} 
                  user={user}
                  updateUserDocuments={updateUserDocuments}
                  removeUserDocuments={removeUserDocuments} />
              )
            }) }

          </ul> 

          <div className="form-group">
            <button className="btn btn-primary btn-quirk" onClick={() => handleCurrentStageChange('training')}>SAVE & NEXT</button>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <hr className="m-0" />
        <div className="panel-heading disabled">
          <div className="d-flex">
            <span className="icon-button font-weight-bold mt-5">2</span>
            <div className="ml-10">
              <h3 className="m-0">Setup Required Documents</h3>
              <p>Set up a list of required documents.</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  
}



export default DocumentsPanel;
