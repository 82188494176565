import React, {useState} from 'react';

import { Button, Modal } from 'react-bootstrap';

const FiltersPabel = ()  => {
	
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
	
  return (
    <React.Fragment>
      <button onClick={() => setShow(true)} 
              className="btn-link d-block pull-right">View Data Table</button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-xl"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Custom Modal Styling
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SampleTable />
        </Modal.Body>
      </Modal>
    </React.Fragment>
 
  );
}


export default FiltersPabel;




const SampleTable = () => {
  return(
    <table className="table table-default table-hover table-striped">
      <thead>
        <tr>
          <th>User</th>   
          <th className="bg-blue-grey-200 blue-grey-700">Checkin Time</th>
          <th className="bg-blue-grey-200 blue-grey-700">Location</th>
          <th className="bg-blue-grey-300 blue-grey-700">Checkout Time</th>
          <th className="bg-blue-grey-300 blue-grey-700">Location</th>
          <th className="font-size-10">Time Spent</th>
          <th>Device ID</th>
        </tr>
      </thead>
      <tbody>
          <tr>
            <td className="w-200"><strong>Isuru Dhanajith</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 08:32PM</td>
            <td className="font-size-10">
              -22.7249153, 117.7771005<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.7249153, 117.7771005<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                00:00:21
            </td>
            <td className="font-size-10">02d71d82cc693dae</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Yashodha Rashmi Kumara</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.731732, 117.7741467<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:19PM
            </td>
            <td className="font-size-10">
                -22.7323568, 117.7756347<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:01:42
            </td>
            <td className="font-size-10">3017ba2d5d028ae1</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Lushan Anushka</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.7317408, 117.7741452<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.7324436, 117.7733237<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:03
            </td>
            <td className="font-size-10">cd33cfa035bef87e</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Jeewantha Nuwan Theekshana</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.73162836396127, 117.77415954533667<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.73238490479342, 117.7732909993175<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:04
            </td>
            <td className="font-size-10">7AEEE859-C111-4541-A0E9-746DCBB8BC1F</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Viraj Vishwajith Liyanage</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.73160633159237, 117.77416650556732<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.732381551348226, 117.77332458537472<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:07
            </td>
            <td className="font-size-10">66025FEA-4AC0-4DA2-996D-6F28DD7E49F6</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Imesh Kawshika</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.73155926249346, 117.77412793166808<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.73238743607607, 117.77319704255494<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:13
            </td>
            <td className="font-size-10">A576B1B5-EF9A-40DB-AAC0-A13AB6892C19</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Dona Champa Mihirani</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 09:12AM</td>
            <td className="font-size-10">
              -22.57114750999999, 117.30998149999995<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:20PM
            </td>
            <td className="font-size-10">
                -22.570786469999994, 117.31387448999996<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:07:37
            </td>
            <td className="font-size-10">0DF95BBA-7DD1-4010-A0E4-E5868DEFD06F</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Isuru Ranmali</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 09:04AM</td>
            <td className="font-size-10">
              -22.57214006613993, 117.31163841735707<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:19PM
            </td>
            <td className="font-size-10">
                -22.572120501429772, 117.3116333705204<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:15:41
            </td>
            <td className="font-size-10">4A8B7709-603A-4555-8763-3E44F9C32A82</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Thilina Buddika</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 09:01AM</td>
            <td className="font-size-10">
              -22.57191961948437, 117.31250032916807<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:20PM
            </td>
            <td className="font-size-10">
                -22.572129503367787, 117.31164148564794<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:19:04
            </td>
            <td className="font-size-10">E71A870A-727A-4976-A5C0-CF73156B4671</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Ishan Sanuka</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 08:58AM</td>
            <td className="font-size-10">
              -22.385514105, 117.3766086<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:47PM
            </td>
            <td className="font-size-10">
                -22.385094568940666, 117.37622376111392<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:48:47
            </td>
            <td className="font-size-10">599BD064-2D03-47D5-AD75-26EDF93313AD</td>
              
          </tr>

          <tr>
            <td className="w-200"><strong>Isuru Dhanajith</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 08:32PM</td>
            <td className="font-size-10">
              -22.7249153, 117.7771005<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.7249153, 117.7771005<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                00:00:21
            </td>
            <td className="font-size-10">02d71d82cc693dae</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Yashodha Rashmi Kumara</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.731732, 117.7741467<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:19PM
            </td>
            <td className="font-size-10">
                -22.7323568, 117.7756347<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:01:42
            </td>
            <td className="font-size-10">3017ba2d5d028ae1</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Lushan Anushka</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.7317408, 117.7741452<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.7324436, 117.7733237<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:03
            </td>
            <td className="font-size-10">cd33cfa035bef87e</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Jeewantha Nuwan Theekshana</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.73162836396127, 117.77415954533667<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.73238490479342, 117.7732909993175<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:04
            </td>
            <td className="font-size-10">7AEEE859-C111-4541-A0E9-746DCBB8BC1F</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Viraj Vishwajith Liyanage</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.73160633159237, 117.77416650556732<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.732381551348226, 117.77332458537472<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:07
            </td>
            <td className="font-size-10">66025FEA-4AC0-4DA2-996D-6F28DD7E49F6</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Imesh Kawshika</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 11:17AM</td>
            <td className="font-size-10">
              -22.73155926249346, 117.77412793166808<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 08:32PM
            </td>
            <td className="font-size-10">
                -22.73238743607607, 117.77319704255494<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                09:15:13
            </td>
            <td className="font-size-10">A576B1B5-EF9A-40DB-AAC0-A13AB6892C19</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Dona Champa Mihirani</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 09:12AM</td>
            <td className="font-size-10">
              -22.57114750999999, 117.30998149999995<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:20PM
            </td>
            <td className="font-size-10">
                -22.570786469999994, 117.31387448999996<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:07:37
            </td>
            <td className="font-size-10">0DF95BBA-7DD1-4010-A0E4-E5868DEFD06F</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Isuru Ranmali</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 09:04AM</td>
            <td className="font-size-10">
              -22.57214006613993, 117.31163841735707<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:19PM
            </td>
            <td className="font-size-10">
                -22.572120501429772, 117.3116333705204<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:15:41
            </td>
            <td className="font-size-10">4A8B7709-603A-4555-8763-3E44F9C32A82</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Thilina Buddika</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 09:01AM</td>
            <td className="font-size-10">
              -22.57191961948437, 117.31250032916807<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:20PM
            </td>
            <td className="font-size-10">
                -22.572129503367787, 117.31164148564794<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:19:04
            </td>
            <td className="font-size-10">E71A870A-727A-4976-A5C0-CF73156B4671</td>
              
          </tr>
          <tr>
            <td className="w-200"><strong>Ishan Sanuka</strong></td>
            <td className="font-size-10">Thursday, 02 April 2020 08:58AM</td>
            <td className="font-size-10">
              -22.385514105, 117.3766086<br />
                  <small className="label label-danger">GPS: FALSE</small>
            </td>
            <td className="font-size-10">
                Thursday, 02 April 2020 09:47PM
            </td>
            <td className="font-size-10">
                -22.385094568940666, 117.37622376111392<br />

                <small className="label label-danger">GPS: FALSE</small>

            </td>
            <td className="font-size-12 w-100">
                12:48:47
            </td>
            <td className="font-size-10">599BD064-2D03-47D5-AD75-26EDF93313AD</td>
              
          </tr>
      </tbody>
    </table>
  )
}