import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

import { BounceLoader } from 'react-spinners';

const ListItem = ({item,index}) => {

	const [value, setValue] = useState(item.value || false)
	const [isLoading, setIsLoading] = useState(false)

	const handleChange = e => {
		
    const ohs_auditable_item = item
    const formData = new FormData();
    
    setValue(e.target.value)

    setIsLoading(true)

    formData.append('ohs_audit[value]', e.target.value )

    setupCSRFToken();

    axios.put(`/ohs_auditable_items/${ohs_auditable_item.id}`, formData)
    .then(response => {
    	setIsLoading(false)
    })
    .catch(error => {
      console.log("error catched")
      console.log(error)

      toastr.error( "Please check the inputs and submit the form again.",
      							"An error occurred during the process")
 
    })

	}

	return (
  	<React.Fragment>
  		<tr key={index}>
  			<td>
          {item.question}
        </td>

        <td>
        	<label className={`toggle ${isLoading ? "danger" : ""}`}>
            <input type="radio" 
            				name={`value_${item.id}`} 
            				className="toggle__input radio"
            				value={value == "YES" ? true : false }
            				checked={value == "YES" ? true : false }
            				onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked ? "YES" : false,
                        },
                      })
                     }} />
            <span className="toggle__label radio">
	          	<span className="toggle__text"></span>
	          </span>
	        </label>
          
        </td>
        <td>
        	<label className={`toggle ${isLoading ? "danger" : ""}`}>
            <input type="radio" 
            				name={`value_${item.id}`} 
            				className="toggle__input radio" 
            				value={value == "NO" ? true : false }
            				checked={value == "NO" ? true : false }
            				onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked ? "NO" : false,
                        },
                      })
                     }} />

            <span className="toggle__label radio">
              <span className="toggle__text"></span>
            </span>
          </label>
        	
        </td>
        <td>
        	<label className={`toggle ${isLoading ? "danger" : ""}`}>
            <input type="radio" 
            				name={`value_${item.id}`} 
            				className="toggle__input radio" 
            				value={value == "N/A" ? true : false }
            				checked={value == "N/A" ? true : false }
            				onChange={(e) => {
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: e.target.checked ? "N/A" : false,
                        },
                      })
                     }} />

            <span className="toggle__label radio">
              <span className="toggle__text"></span>
            </span>
          </label>

        </td>
  		</tr>
    </React.Fragment>
  )
}

export default ListItem
