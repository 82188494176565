
import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import { BeatLoader } from 'react-spinners'

class MarkAsCompletedForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_submit_loading: false,
      gspecs_ready: this.props.gspecs_ready,
    };

    this.handleSubmit = this.handleSubmit.bind(this)

  }

  componentDidMount = () => {
    console.log(this.props)

  }


  handleSubmit = e => {

    const self = this
    const site_id = this.props.site_id
    const formData = new FormData();
    const gspecs_ready = e.target.value

    self.setState({ is_submit_loading: true })

    formData.append('site[gspecs_ready]', gspecs_ready )

    setupCSRFToken();

    axios.put(`/sites/${site_id}/`, formData)
    .then(response => {
    	
      //const tasks_template = response.data.tasks_template
      
      this.setState({
      	gspecs_ready: gspecs_ready
      })

      self.props.updateSiteGSpecsReadyStatus(gspecs_ready)

      toastr.success( "The site has been updated successfully")

    })
    .catch(error => {
      console.log("error catched")
      console.log(error)

      toastr.error( "Please check the inputs and submit the form again.",
      							"An error occurred during the process")
 
    })

    self.setState({ is_submit_loading: false })
  }

  render(){

    const {
      gspecs_ready
    } = this.state
  
  	return(
	  	<React.Fragment>

        <div className="section-details p-30">
          <div className="panel-announcement p-0" style={{background: '#f6f8fa', borderRadius: '4px'}}>
            <div className="panel-body">
              <form>
                { gspecs_ready &&
                  <h2>This site is marked as "General Specifications Ready"</h2>
                }

                { !gspecs_ready &&
                  <h2>General Specifications Not-Ready</h2>
                }

                <div className="form-group">
                  <label className="toggle">
                    <input type="checkbox" 
                            name="is_general" 
                            className="toggle__input"
                            value={gspecs_ready}
                            checked={gspecs_ready}
                            onChange={(e) => {
                              this.handleSubmit({
                                target: {
                                  name: e.target.name,
                                  value: e.target.checked,
                                },
                              })
                             }} />
                    {gspecs_ready && 
                      <span className="toggle__label">
                        <span className="toggle__text">&nbsp; Click here when you want to edit Tasks Templates</span>
                      </span>
                    }

                    {!gspecs_ready && 
                      <span className="toggle__label">
                        <span className="toggle__text">&nbsp; Click here when you finish <br />
                          <span className="text-muted">&nbsp; This will mark the site as "General Specifications Ready"</span>
                        </span>
                      </span>
                    }
                  </label>
                  
                  
                </div>
              </form>
                
            </div>
          </div>
        </div>
      </React.Fragment>
	  )
  }
}

export default MarkAsCompletedForm
