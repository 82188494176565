import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';

import SubSectionsList from './components/SubSectionsList'

class Specification extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      site_id: parseInt(this.props.match.params.site_id),
    };
  }

  render () {
  	return (
      <div className="panel panel-specifications col-md-12">
				<div className="row">
			    <div className="col-md-4 panel-sp-left">
			      <SubSectionsList />
			    </div>

			    <div className="col-md-8 panel-sp-right">
			      <div className="section-details p-30">
			        <p className="media-usermeta">Select a Building name</p>
			      </div>
			    </div>
			  </div>
			</div>
   
    );

  }
}

Specification.propTypes = {
  //site_id: PropTypes.number,
  match: ReactRouterPropTypes.match.isRequired,
};

export default Specification
