import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import ReactRouterPropTypes from 'react-router-prop-types';

import _ from 'underscore'
import { Modal, Button } from 'react-bootstrap';

import TasksMappingQuickForm from './components/tasks_mappings/TasksMappingQuickForm'

import TaskDateFields from './components/TaskDateFields';

class TasksMappings extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	error: null,
    	sub_section_id: this.props.match.params.sub_section_id,
      showEditTaskModel: false,
      tm: {},
    	result: [],
      //site_id: parseInt(this.props.match.params.site_id),
    };

    this.showEditTaskModel = this.showEditTaskModel.bind(this);
    this.handleEditTaskModelHide = this.handleEditTaskModelHide.bind(this);
  }

  componentDidMount() {
  	this.getTasksMappings();
  }

  getTasksMappings(){
  	const { sub_section_id } = this.state

  	fetch(`/sub_sections/${sub_section_id}/tasks_mappings`, {
    	headers: {
    		"Content-Type": "application/json"
    	},
    	credentials: "include"
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          result: result
        });
      },

      (error) => {
        this.setState({
          error
        });
      }
    )
  }

  showEditTaskModel = (tm, e) => {
    e.preventDefault();
    console.log(tm)
    this.setState({
      showEditTaskModel: true,
      tm: {
        ...tm,
      },
    })
  }

  handleEditTaskModelHide = (e) => {
    this.setState({
      showEditTaskModel: false
    })
  }

  render () {
  	const { error, result, sub_section_id, tm } = this.state;
    
  	return (
      <div>
	      <h4 className="panel-title mb20">Allocated Tasks</h4>
	      <div className="tasks-wrapper">
	        {result.map(tm => (

	          <div key={tm.id} className="task-item-row mb-5 d-flex justify-content-between">
	            <div className="task-name">
	            	{tm.task_name}
                <br />
                <small>
                  <Link to="#" onClick={this.showEditTaskModel.bind(this, tm)} >Edit</Link> | <Link data-confirm="Are you sure you want to delete this item?" rel="nofollow" data-method="delete" to={'/tasks_mappings/' + tm.id} className="text-danger">Delete</Link>
                </small>
	            </div>
	            <div className="task-details">
	              <span className="task-frequency-type mr-5">{tm.frequency_type}</span>
                
                <TaskDateFields frequencies={tm.frequencies}/>
	            </div>
	          </div>

	        ))}

          <button  onClick={this.showEditTaskModel.bind(this, tm)}
                   className="btn btn-default">Add New Task</button>
	      </div>



        <Modal
          show={this.state.showEditTaskModel}
          onHide={this.handleEditTaskModelHide}
          container={this}
          aria-labelledby="contained-modal-title"
          >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title">
              Edit Task
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="task-item-row">
              <TasksMappingQuickForm sub_section_id={sub_section_id} is_tasks_template={false} tm={tm}/>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleEditTaskModelHide}>Close</Button>
            <input type="submit" className="btn btn-primary btn-quirk" value="Submit" disabled={!this.state.isFormSubmitEnabled} />
          </Modal.Footer>
        </Modal>
				
			</div>
   
    );

  }
}

TasksMappings.propTypes = {
  is_tasks_template: PropTypes.bool,
  match: ReactRouterPropTypes.match.isRequired,
};



export default TasksMappings
