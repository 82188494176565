import React from 'react'
import PropTypes from 'prop-types';
// import toastr from 'toastr';

import NewCommentForm from './new_comment_form';
import WorkOrderCommentItem from './work_order_comment_item'

// import axios from 'axios';
// import setupCSRFToken from 'config/setupCSRFToken'
// import serialize from 'form-serialize';

class WorkOrderComments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: this.props.comments,
      site: this.props.site,
      work_order: this.props.work_order,
      current_user_id: this.props.current_user_id,
    };

    this.unSetComment = this.unSetComment.bind(this)
  }

  setNewComment = comment => {
    this.setState({
      comments: [
        ...this.state.comments,
        comment
      ],
    })
  }

  //Delete a comment
  unSetComment = comment_id => {
    console.log(comment_id)
    let comments = this.state.comments.filter(comment => {
      return comment.id !== comment_id
    })

    this.setState({
      comments: comments
    })
  }

  render () {

    return (
      <section className="report-comments">
        <h4>Comments</h4>
        
        {this.state.comments.map((comment, index) => (
          <WorkOrderCommentItem 
                    comment={comment} 
                    key={index}
                    unSetComment={this.unSetComment}
                    current_user_id={this.props.current_user_id} />
        ))}

        <NewCommentForm 
          site={this.state.site}
          work_order={this.state.work_order}
          setNewComment={this.setNewComment} />

      </section>
    );
    

  }
}

export default WorkOrderComments
