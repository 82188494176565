import React from "react";

const AccountRegistrationParcial = ({
	user,
	handleChange,
	handleSubmit
}) => {
	return (
		<React.Fragment>
			<div className="text-right top-right-text-wrapper pt-20 pr-20">
        <p className="font-size-14">Already have an Account? <a href="/signin">Sign In</a></p>
      </div>

      <div className="p-20 reg-pane-header text-center">                         
        <h3 className="head-title">Account Registration</h3>
        <h4 className="head-subtitle">You are just 5 simple steps away from account registration.</h4>
      </div>

      <form className="ohs-audit-header-form" onSubmit={handleSubmit} >
      	<div className="row pl-20 pr-20">
          <div className="col-md-6 form-group">
            <label>First Name</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-user"></i>
                </div>
              </div>
              
              <input className="form-control" 
	                  type="text" 
	                  value={user.first_name || ""} 
	                  onChange={handleChange}
	                  name="first_name"
                    placeholder="First Name" />
            </div>
          </div>

          <div className="col-md-6 form-group">
            <label>Middle Name</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-user"></i>
                </div>
              </div>
              
              <input className="form-control" 
	                  type="text" 
	                  value={user.last_name || ""} 
	                  onChange={handleChange}
	                  name="last_name"
                    placeholder="Last Name" />
            </div>
          </div>
        </div>

        <div className="row pl-20 pr-20">
          <div className="col-md-12 form-group">
            <label>Surname/ Last Name</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-envelope"></i>
                </div>
              </div>
              
              <input className="form-control" 
	                  type="text" 
	                  value={user.surname || ""} 
	                  onChange={handleChange}
	                  name="surname"
                    placeholder="Family Name" />
            </div>
          </div>
        </div>

        <div className="row pl-20 pr-20">
          <div className="col-md-12 form-group">
            <label>Email</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-envelope"></i>
                </div>
              </div>
              
              <input className="form-control" 
	                  type="email" 
	                  value={user.email || ""} 
	                  onChange={handleChange}
	                  name="email"
                    placeholder="Your Email" />
            </div>
          </div>
        </div>

        <div className="row pl-20 pr-20">
          <div className="col-md-6 form-group">
            <label>Date of Birth</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-calendar"></i>
                </div>
              </div>
              
              <input className="form-control" 
	                  type="date" 
	                  value={user.dob || ""} 
	                  onChange={handleChange}
	                  name="dob" />

            </div>
          </div>

          <div className="col-md-6 form-group">
            <label>Phone</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text" style={{paddingRight: '8px', paddingLeft: '8px'}}>
                  <img src="/assets/icons/icons8-australia.png" style={{width: '27px'}} /> +61
                </div>
              </div>
              
              <input className="form-control" 
	                  type="text" 
	                  value={user.phone || ""} 
	                  onChange={handleChange}
	                  name="phone"
                    placeholder="Mobile Number" />
            </div>
          </div>
        </div>

        <div className="row pl-20 pr-20">
          <div className="col-md-6 form-group">
            <label>Password</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-key"></i>
                </div>
              </div>
              <input className="form-control" 
	                  type="password" 
	                  value={user.password || ""} 
	                  onChange={handleChange}
	                  name="password"
                    placeholder="*********" />
            </div>
          </div>

          <div className="col-md-6 form-group">
            <label>Password Confirmation</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-key"></i>
                </div>
              </div>
              
              <input className="form-control" 
	                  type="password" 
	                  value={user.password_confirmation || ""} 
	                  onChange={handleChange}
	                  name="password_confirmation"
                    placeholder="*********" />

            </div>
          </div>
        </div>

        <div className="form-group p-20 text-center">
          <hr />
          <button onClick={handleSubmit} className="btn btn-primary btn-login pl-30 pr-30">Next</button>
        </div>
      </form>
			
		</React.Fragment>
	)
}

export default AccountRegistrationParcial;