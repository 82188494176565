import $ from 'jquery';
import React from "react";
import ReactDOM from "react-dom";
import App from '../src/App';
var Turbolinks = require("turbolinks")


$(document)

.on('click', '.table-scheduled-jobs .job-item-row', function(e){
  var _target = $(e.target);

  var _this = $(this)
  var sjob_id = _this.data('sjob_id')
  var sjob_title = _this.data('sjob_title')

  $('#jobPreviewOffCanvas #modal-title').html(sjob_title)

  history.pushState({}, '', `/scheduled_jobs/${sjob_id}/preview`)

  if (!$('#react_comp_wrapper').is(':empty')) {
    ReactDOM.unmountComponentAtNode($('#react_comp_wrapper')[0])
    //$('#react_comp_wrapper').html()
  }
  
  ReactDOM.hydrate(<App />, $('#react_comp_wrapper')[0])

  e.preventDefault()

  e.preventDefault()
})