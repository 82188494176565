//Basics
import React from 'react'

const FormErrors = props => {

	return(
		<div className="alert alert-danger">
			<h4>The following errors occurred during saving your asset.</h4>

		  <ul className="list-unstyled">
		  	{props.errors.map((message, index) => (
		  			<li key={index}>{message}</li>
		  		))
		  	}
		  </ul>
		</div>
	)
}

export default FormErrors