import React, { useState, useEffect } from 'react';

import {
  Button,
  Offcanvas,
  Modal
} from 'react-bootstrap';
import _ from "underscore";
import moment from "moment"
import Select from 'react-select';
import currency from "currency.js"

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import ShiftPayVersionFormModel from "./form_model";


const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const ShiftPayDetails = ({
  shift
}) => {
  const [shift_pay_version, setShiftPayVersion] = useState(shift.shift_pay_version || {})

  const [loading, setLoading] = useState(false)

  const handleChangePayDataFormSubmit = paydata => {
    let payload = {
      shift_id: shift.id,
      paydata: paydata
    }

    if(shift_pay_version.id == null) {
      createShiftPayVersion(payload)
    } else {
      updateShiftPayVersion(payload)
    }

  }

  const createShiftPayVersion= payload => {
    setupCSRFToken();
    axios.post(`/shift_pay_versions/`, {shift_pay_version: payload})
        .then(response => {
          setShiftPayVersion(response.data.shift_pay_version)

          toastr.success( "The shift has been updated successfully")

        })
        .catch(error => {
          console.log("error catched")
          console.log(error)

          toastr.error( "Please check the inputs and submit the form again.",
                        "An error occurred during the process")
     
        })
  }

  const updateShiftPayVersion = payload => {
    setupCSRFToken();
    axios.put(`/shift_pay_versions/${shift_pay_version.id}`, {shift_pay_version: payload})
        .then(response => {
          setShiftPayVersion(response.data.shift_pay_version)

          toastr.success( "The shift has been updated successfully")

        })
        .catch(error => {
          console.log("error catched")
          console.log(error)

          toastr.error( "Please check the inputs and submit the form again.",
                        "An error occurred during the process")
     
        })
  }

  if(shift_pay_version.id > 0) {
    return(
      <React.Fragment>
        <section>
          <div className="section-header d-flex justify-content-between align-items-center mb-30">
            <div>
              <h4 className="fs-14 m-0">Shift Pay Details</h4>
        
            </div>
            <div>
              <ShiftPayVersionFormModel 
                shift={shift}
                shift_pay_version={shift_pay_version} 
                isEdit={true}
                handleChangePayDataFormSubmit={handleChangePayDataFormSubmit}/>
            </div>
          </div>

          <div className="section-body">
            
            <table className="table table-hover table-default table-striped">
              <thead>
                <tr>
                  <th>Day</th>
                  <th>Allocated Hours</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Unpaid Break(.min)</th>
                  <th>Award Rate(/hr)</th>
                  <th>Shift Rate(/hr)</th>
                </tr>
              </thead>

              <tbody>
                {weekDays.map( (dataKey, index) => {
                  let data = shift_pay_version.paydata[dataKey]

                  return(
                    <tr key={index}>
                      <td className="text-capitalize">{dataKey}</td>
                      <td>{data && data.allocated_hours}</td>
                      <td>{data && data.start_time}</td>
                      <td>{data && data.finish_time}</td>
                      <td>{data && data.unpaid_break}</td>
                      <td>{data && data.award_rate ? `${currency(data.award_rate).format()}` : ""}</td>
                      <td>{data && data.shift_rate ? `${currency(data.shift_rate).format()}` : ""}</td>
                    </tr>
                  )
                })}
              </tbody>
              
            </table>
          </div>
          
        </section>
      </React.Fragment>

    )
  } else {
    return (
      <React.Fragment>
        <section>
          <div className="section-header d-flex justify-content-between align-items-center mb-30">
            <div>
              <h4 className="fs-14 m-0">Shift Pay Details</h4>
        
            </div>
            <div>
              <ShiftPayVersionFormModel 
                shift={shift}
                shift_pay_version={shift_pay_version} 
                isEdit={false}
                handleChangePayDataFormSubmit={handleChangePayDataFormSubmit}/>
            </div>
          </div>

          <div className="section-body">
            <table className="table table-hover table-default table-striped">
              <thead>
                <tr>
                  <th className="text-muted">Day</th>
                  <th className="text-muted">Allocated Hours</th>
                  <th className="text-muted">Start Time</th>
                  <th className="text-muted">End Time</th>
                  <th className="text-muted">Unpaid Break(.min)</th>
                  <th className="text-muted">Award Rate(/hr)</th>
                  <th className="text-muted">Shift Rate(/hr)</th>
                </tr>
              </thead>

              <tbody>
                {weekDays.map( (dataKey, index) => {
      
                  return(
                    <tr key={index}>
                      <td className="text-capitalize text-muted">{dataKey}</td>
                      <td className="text-muted"> - </td>
                      <td className="text-muted"> - </td>
                      <td className="text-muted"> - </td>
                      <td className="text-muted"> - </td>
                      <td className="text-muted"> - </td>
                      <td className="text-muted"> - </td>
                    </tr>
                  )
                })}
              </tbody>
              
            </table>

            <p>No Shift pay version found for this shift. Shift Pay Version defines the payment information along with the allocated hours for a given shift.</p>
          </div>
          
        </section>
      </React.Fragment>
    )
  }
  
}



export default ShiftPayDetails