import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';


import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

import { Button, Modal } from 'react-bootstrap';

import AssetsIssuingItemNote from './assets_issuing_item_note'

// const IssuingNote = ({
//     issuing_item,
//     addIssuingNote,
//     issuingStatus
//     }) => {

//   if (issuing_item.issuing_note && issuing_item.issuing_note.length > 0){
//     return issuing_item.issuing_note
//   }

//   else if (issuingStatus == 'draft'){
//     return <a href="" onClick={addIssuingNote}>Add Note</a> 
//   }

//   else{
//     return "-"
//   }
// }


class AssetIssuingItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      issuing_item: this.props.issuing_item,
      assets_issuing: this.props.assets_issuing,
      asset: this.props.asset,
      isItemRemoved: false,
      is_issuing_editable: this.props.is_issuing_editable,
      showReturnAssetModel: false,
    };

    this.handleRemove = this.handleRemove.bind(this);
    this.addIssuingNote = this.addIssuingNote.bind(this);
    this.handleShowReturnAssetModel = this.handleShowReturnAssetModel.bind(this);
    this.handleCloseReturnAssetModel = this.handleCloseReturnAssetModel.bind(this);
    this.handleAssetReturnSubmit = this.handleAssetReturnSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAssetReturnUndo = this.handleAssetReturnUndo.bind(this);
    this.handleUpdateIssuingItem = this.handleUpdateIssuingItem.bind(this);
  }

  addIssuingNote = e => {
    e.preventDefault();
    console.log("lskdjflksjd")
  }

  handleChange = event => {
    this.setState({
      issuing_item: {
        ...this.state.issuing_item,
        [event.target.name]: event.target.value
      }
    });
  }

  handleRemove = () => {

    if(window.confirm('Are you sure want to delete the item?')){
      const issuing_item = this.state.issuing_item

      setupCSRFToken();

      axios.delete(`/ams/assets_issuing_items/${issuing_item.id}`,)
      .then(response => {
        this.setState({ isItemRemoved: true })

        toastr.success("The item has been deleted successfully!")
          
      })
      .catch(error => {
        console.log("error catched")
        console.log(error.response)

        toastr.error("An error occurred during the process")
      }) 
    }
  }

  handleAssetReturnUndo = e =>{
    this.setState({
      issuing_item: {
        ...this.state.issuing_item,
        returned_at: null,
        returned_by: null,
        collected_by: null,
      }
    }, () => {
      this.handleUpdateIssuingItem();
    });

    e.preventDefault();
  }

  handleUpdateIssuingItem = () => {

    const issuing_item = this.state.issuing_item

    setupCSRFToken();

    axios.put(`/ams/assets_issuing_items/${issuing_item.id}`, { 
      assets_issuing_item: issuing_item,
      is_returned: false })
    .then(response => {
      console.log(response)
      this.setState({
        issuing_item: response.data,
      })

      toastr.success("The issuing item has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
      

      // window.flash_messages.addMessage(
      //    { id: Math.floor((Math.random() * 1000)), 
      //       message: error.response.data.message, 
      //       type: 'error' });
    })
  }


  handleAssetReturnSubmit = e => {
    e.preventDefault();

    const issuing_item = this.state.issuing_item

    setupCSRFToken();

    axios.put(`/ams/assets_issuing_items/${issuing_item.id}`, { 
      assets_issuing_item: issuing_item,
      is_returned: true })
    .then(response => {
      console.log(response)
      this.setState({
        issuing_item: response.data,
        showReturnAssetModel: false,
      })

      toastr.success("The issuing item has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
  
    })

  }

  handleCloseReturnAssetModel() {
    this.setState({ showReturnAssetModel: false });
  }

  handleShowReturnAssetModel() {
    this.setState({ showReturnAssetModel: true });
  }

  render () {
    const {asset, assets_issuing, issuing_item, isItemRemoved} = this.state

    if ( !isItemRemoved){

      return (
        <React.Fragment>
          <div className="form-group card position-relative">
            <div className="media media-gray">
              <div className="media-body">
                <h4 className="media-heading">
                  <a href="#">{asset.name}</a>
                </h4>
                <p><strong>Warehouse Code: {issuing_item.asset_code}</strong><br />
                  <strong>Manufacture: </strong> {asset.manufacture} - <strong>Model: </strong> {asset.model}
                </p>

                {this.state.issuing_item.returned_at &&
                  <div>
                    <hr className="m-0 mb-10"/>
                    <p><strong>Returned By:</strong> {issuing_item.returned_by}<br />
                        <strong>Returned At: </strong> {issuing_item.returned_at}<br />
                        <strong>Collected By: </strong> {issuing_item.collected_by}
                    </p>

                    <p><strong>Returned Note: </strong> {issuing_item.returned_note}</p>
                  </div>
                }

              </div>

            </div>

            { this.state.is_issuing_editable &&
              <ul className="panel-options">
                <li><a className="text-danger" onClick={this.handleRemove}>Remove</a></li>
              </ul>
            }

            { this.state.assets_issuing.status == "issued" && !this.state.issuing_item.returned_at &&
              <ul className="panel-options">
                <li><button className="btn btn-primary btn-sm" onClick={this.handleShowReturnAssetModel}>Check & Return</button></li>
              </ul>
            }

            { this.state.assets_issuing.status == "issued" && this.state.issuing_item.returned_at &&
              <ul className="panel-options">
                <li><span className="label label-success">Asset Returned</span></li>
                <li><a href="#" onClick={this.handleAssetReturnUndo}>Unmark as Returned</a></li>
              </ul>
            }
          </div>

          <Modal show={this.state.showReturnAssetModel} onHide={this.handleCloseReturnAssetModel}>
            <form name="AssetsReturningForm" className="asset-returning-form" onSubmit={this.handleAssetReturnSubmit} >
              <Modal.Header closeButton>
                <Modal.Title>Check & Return Asset</Modal.Title>
              </Modal.Header>
              <div className="model-sub-header bg-blue-grey-100">
                <div className="panel-heading">
                  <div className="media">
                    <div className="media-body">
                      <h4 className="media-heading">
                        <a href="#">{asset.name}</a>
                      </h4>
                      <p><strong>Warehouse Code: {issuing_item.asset_code}</strong><br />
                        <strong>Manufacture: </strong> {asset.manufacture} - <strong>Model: </strong> {asset.model}
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>

              <Modal.Body>
                <section className="panel-report-header">
                  <div className="form-group">
                    <label>Returned By (Name)</label>
                    <input className="form-control" 
                      type="text" 
                      value={this.state.assets_issuing.returned_by} 
                      onChange={this.handleChange}
                      name="returned_by"
                      required={true} />
                  </div>

                  <div className="form-group">
                  <label>Returned Note</label>
                    <textarea className="form-control w-p100" 
                      name="returned_note"
                      value={this.state.assets_issuing.returned_note}
                      onChange={this.handleChange} />
                  </div>
                </section>
                
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleCloseReturnAssetModel}>Close</Button>
                <input type="submit"
                      className="btn btn-primary btn-quirk mr-5"
                      value="SAVE CHANGES" />
              </Modal.Footer>
            </form>
          </Modal>
        </React.Fragment>
      )
    }
    else{
      return ""
    }
  }
}

export default AssetIssuingItem
