import React from 'react';

const StaffItem = ({cleaner}) => {

  return(
    <div className="col-xs-12 col-sm-12 col-md-6">
      <div className="media" style={{ background: '#f6f8fa', 
                                      padding: '8px', 
                                      borderRadius: '4px',
                                      marginBottom: '10px',
                                      marginRight: '10px'}} >
        <div className="media">
          <div className="media-left">
              <img className="media-object width80" src="/assets/user_avatar_default_150x-b4b00440417d8dce602b9020daa9ba489142938a96d27030dd29a506cd859a09.png" alt="User avatar default 150x" />
  
          </div>
          <div className="media-body">
            <h4 className="media-heading m-0">{cleaner.user.full_name}</h4>
            <p className="media-usermeta m-0"><i className="glyphicon glyphicon-briefcase"></i> {cleaner.display_name} - {cleaner.work_type} </p>

            {cleaner.custom_fields && cleaner.custom_fields.assigned_supervisor_name &&
              <p className="media-usermeta m-0"><i className="glyphicon glyphicon-user"></i> Sup: {cleaner.custom_fields.assigned_supervisor_name}</p>
            }
            
            <p className="media-usermeta m-0"><i className="glyphicon glyphicon-info-sign"></i> {cleaner.note}</p>
            <p className="media-usermeta m-0"><i className="glyphicon glyphicon-time"></i> {cleaner.allocated_hours} Hours</p>
  
            <div className="info-group">
              <div className="tasks-item-row">
                <div className="task-details d-flex h-20">
                  <span className={`task-frequency ${cleaner.work_days && cleaner.work_days.sunday == "true" ? 'active' : ''}`}>S</span>
                  <span className={`task-frequency ${cleaner.work_days && cleaner.work_days.monday == "true" ? 'active' : ''}`}>M</span>
                  <span className={`task-frequency ${cleaner.work_days && cleaner.work_days.tuesday == "true" ? 'active' : ''}`}>T</span>
                  <span className={`task-frequency ${cleaner.work_days && cleaner.work_days.wednesday == "true" ? 'active' : ''}`}>W</span>
                  <span className={`task-frequency ${cleaner.work_days && cleaner.work_days.thursday == "true" ? 'active' : ''}`}>T</span>
                  <span className={`task-frequency ${cleaner.work_days && cleaner.work_days.friday == "true" ? 'active' : ''}`}>F</span>
                  <span className={`task-frequency ${cleaner.work_days && cleaner.work_days.saturday == "true" ? 'active' : ''}`}>S</span>
                </div>
  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StaffItem;