//Basics
import React from 'react'
import { render } from 'react-dom'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

import AssetForm from './AssetForm';
import FormErrors from 'components/errors/FormErrors';

class AssetsFormContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      showErrors: false,
      avatar: '',
    }
  }
  
  handleSubmit = e => {
    e.preventDefault();

    // const hash_id = props.match.params.hash_id
    //const data  = serialize(e.target, { hash: true, empty: true })
    //data.avatar = this.state.avatar

    const fd = new FormData(e.target)

    setupCSRFToken();

    axios.post(`/ams/assets`, fd)
    .then(response => {
      console.log(response.data.id)
      
      let asset_id = response.data.id
      let asset_path = `/ams/assets/${asset_id}`
      return <Redirect to={asset_path} />

      toastr.success('Asset has been registered Successfully!')
      

    })
    .catch(error => {
      console.log("error catched")
      console.log(error)
      toastr.error('An Error occurred during saving your asset')

      this.handleErrors(error.response.data.errors)

    })

  }

  handleErrors = errors => {
    this.setState({
      errors: errors,
      showErrors: true,
    })
    
  }

  handleImageChange = event => {

    this.setState({
      avatar: event.target.files[0],
    });
  }


  render(){
    return (
      <div>
        {this.state.showErrors && 
          <FormErrors errors={this.state.errors} />
        }
        
        <AssetForm 
          {...this.props}
          handleSubmit={this.handleSubmit}
          handleImageChange={this.handleImageChange}/>
      </div>
    )
  }
}

AssetsFormContainer.propTypes = {
  asset: PropTypes.object,
};


export default AssetsFormContainer
