import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types"

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import {Modal, Button, Form} from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';

import ClientAcknowledgementFormPartial from './client_acknowledgement_form_partial';

class CompleteInspection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      inspection_id: this.props.inspection_id,
      site_name: this.props.site_name,
      site_full_address: this.props.site_full_address,
      site_logo: this.props.site_logo,
      system_generated_score: this.props.system_generated_score,
      inspector_score: this.props.inspector_score,
      inspector_feedback: this.props.inspector_feedback,
      show_inprogress_note: this.props.show_inprogress_note,
      was_client_presented: this.props.was_client_presented == null ? true : this.props.was_client_presented,
      have_you_met_client: this.props.have_you_met_client == null ? true : this.props.have_you_met_client,
      reason_not_met_client: this.props.reason_not_met_client,
      client_contact_name: this.props.client_contact_name,

      inspector_signature: null,
      signatureModelShow: false,
    };

    this.handleSignatureSubmit = this.handleSignatureSubmit.bind(this)
    this.handleSignatureRemove = this.handleSignatureRemove.bind(this)
    this.handleChange       = this.handleChange.bind(this);

  }

  sigPad = {}

  handleSignatureModelClose = () => this.setState({signatureModelShow: false })
  handleSignatureModelShow = () => this.setState({signatureModelShow: true })

  handleSignatureSubmit = event => {
    event.preventDefault()

    if(this.sigPad.isEmpty()) {
      toastr.error( "Please sign on the Signature Pad",
                    "Your Signature is required ")

    } else {
      const signatureDataUrl = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
      const formData  = new FormData();

      const { error, 
        isLoaded, 
        inspection_id,
        inspector_score,
        inspector_signature,
        inspector_feedback,
        was_client_presented,
        have_you_met_client,
        reason_not_met_client,
        client_contact_name
      } = this.state;

      formData.append('inspection[inspector_signature]', signatureDataUrl )
      if (inspector_feedback !== null) {
        formData.append('inspection[inspector_feedback]', inspector_feedback )
      }

      if (inspector_score !== null) {
        formData.append('inspection[inspector_score]', inspector_score )
      }

      formData.append('inspection[was_client_presented]', was_client_presented )
      formData.append('inspection[have_you_met_client]', have_you_met_client )
      formData.append('inspection[reason_not_met_client]', reason_not_met_client == undefined ? "" : reason_not_met_client )
      
      if (was_client_presented == false && have_you_met_client == false ) {
        formData.append('inspection[client_contact_name]', "" )
      } else {
        formData.append('inspection[client_contact_name]', client_contact_name )
      }
      

      setupCSRFToken();

      axios.put(`/inspections/${inspection_id}/complete_inspection`, formData)
      .then(response => {
        window.location.reload()
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })
    }

    
  }

  handleSignatureRemove = e => {
    e.preventDefault()

    console.log("clicked")

    this.setState({ 
      inspector_signature: null,
     })

    this.sigPad.clear();
    
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }


  render() {
    const { error, 
      isLoaded, 
      inspection_id,
      site_name,
      site_full_address,
      site_logo,
      system_generated_score,
      inspector_score,
      inspector_feedback,
      was_client_presented,
      have_you_met_client,
      reason_not_met_client,
      client_contact_name,
      show_inprogress_note,
      inspector_signature,
      signatureModelShow
    } = this.state;
    
    return (
      <React.Fragment>
        <Button className="btn btn-success btn-quirk"
                        onClick={this.handleSignatureModelShow} >Mark as completed</Button>

        <Modal show={signatureModelShow} onHide={this.handleSignatureModelClose}>
          
          <Modal.Header closeButton>
            <Modal.Title>Your Signature</Modal.Title>
  
          </Modal.Header>
          <div className="model-sub-header bg-blue-grey-100">
            <div className="panel-heading">
              <div className="media">
                <div className="media-left">
                  <img src={site_logo} className="media-object w-100" /> 
                </div>
                <div className="media-body"  style={{verticalAlign: 'middle'}}>
                  <h4 className="media-heading blue-grey-500">{site_name}</h4>
                  <p>{site_full_address}</p>
                </div>
              </div>
              
            </div>
          </div>

          <form onSubmit={this.handleSignatureSubmit}>
            <Modal.Body>

              { show_inprogress_note &&
                <div className="alert alert-warning light">
                  <h4 className="m-0 p-0 mb-20" style={{color: '000000'}}>
                      <span className="fa fa-exclamation-triangle"></span> ATTENTION!</h4>
                   <strong>This inspection has un-audited areas and tasks. Please make sure you audit all the areas before completing the inspection.</strong>
                   
                   <div className="progress progress-striped active">
                    <div className="progress-bar progress-bar-warning" role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{width: '40%'}}>
                      <span className="sr-only">40% Complete (success)</span>
                    </div>
                  </div>
                </div>
              }

              <div>
                
                
                <div className="form-group">
                  <div className="well well-sm">
                    <div className="d-flex justify-content-between">
                      <h4>System Generated Score: <strong>{system_generated_score}</strong>%</h4>
                    </div>
                  
                  </div>

                  <label className="form-label"><strong>Your Score</strong></label>
                  <p className="text-muted">Please <strong>DO NOT</strong> change the system generated score by large amount. </p>
                  
                  <input type="number"
                          className="form-control"
                          name="inspector_score"
                          value={inspector_score}
                          onChange={this.handleChange}
                          placeholder={system_generated_score} />
                </div>

                <div className="form-group">
                  <label className="form-label"><strong>Inspector Comment</strong></label>

                  <textarea className="form-control h-100"
                          name="inspector_feedback"
                          value={inspector_feedback}
                          onChange={this.handleChange} />
                </div>

                <ClientAcknowledgementFormPartial 
                  handleChange={this.handleChange}
                  {...this.state}/>

                <div className="form-group mb-30">
                  <label>Inspector Signature (Required)</label>
                  <SignaturePad 
                    canvasProps={{className: 'w-p100 h-300 sigCanvas',
                                  style: {background: '#f6f8fa'} }}
                    ref={(ref) => { this.sigPad = ref }} />

                  <a className="mt-10 mb-10" href="#" onClick={this.handleSignatureRemove}>Clear Signature</a>
                </div>
              </div>

            </Modal.Body>
            
            <Modal.Footer>
              <Button className="btn btn-default btn-quirk" onClick={this.handleSignatureModelClose}>
                Cancel
              </Button>
              
              <button
                    className="btn btn-primary btn-quirk" 
                    onClick={this.handleSignatureSubmit}>Save Changes </button>
              
            </Modal.Footer>
          </form>
        
        </Modal>

      </React.Fragment>
    );

  }
}

CompleteInspection.propTypes = {
  inspection_id: PropTypes.number,
  site_name: PropTypes.string,
  site_full_address: PropTypes.string,
  site_logo: PropTypes.string,
  system_generated_score: PropTypes.string,
  inspector_score: PropTypes.string,
  inspector_feedback: PropTypes.string,
  show_inprogress_note: PropTypes.bool
};

export default CompleteInspection;