import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types"

import EmployeeSignaturePad from './employee_signature_pad';
//import WitnessSignaturePad from './witness_signature_pad';
import moment from 'moment';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import { BeatLoader } from 'react-spinners';

class SignatureComponent extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      user: this.props.user,
      assessment: this.props.assessment,
      is_pdf_processing: false,
    };

    //this.handleWitnessByChange = this.handleWitnessByChange.bind(this)
    //this.handleWitnessByRemove = this.handleWitnessByRemove.bind(this)
    //this.handleSubmitForReviewButtonClick = this.handleSubmitForReviewButtonClick.bind(this)
  }


  updateAssessmentState = assessment => {
  	console.log("called")
  	this.setState({
  		assessment: assessment,
  	})
  }

  // handleWitnessByChange = event => {
  //   const formData  = new FormData();
  //   const assessment = this.state.assessment
  //   const witness_by = event.target.value

  //   formData.append('assessment[witness_by]', witness_by )
  //   formData.append('assessment[assessment_type]', assessment.assessment_type)

  //   setupCSRFToken();

  //   axios.put(`/assessments/${assessment.id}`, formData)
  //   .then(response => {
  //     this.setState({ 
  //       assessment: response.data.assessment
  //      })
      
  //   })
  //   .catch(error => {
  //     console.log("error catched")
  //     console.log(error)

  //     toastr.error( "Please check the inputs and submit the form again.",
  //                   "An error occurred during the process")
 
  //   })
  // }

  // handleWitnessByRemove = e => {
  // 	e.preventDefault()

  //   if(window.confirm('Are you sure to delete this record?')){ 
  //     const formData  = new FormData();
  //     const assessment = this.state.assessment

  //     //formData.append('assessment[witness_signature]', "" )
  //     formData.append('assessment[witness_by]', "" )
  //     formData.append('assessment[assessment_type]', assessment.assessment_type)
  //     //formData.append('assessment[witness_signed_at]', "")

  //     setupCSRFToken();

  //     axios.put(`/assessments/${assessment.id}`, formData)
  //     .then(response => {
  //       this.setState({ 
	 //        assessment: response.data.assessment
	 //      })
  //     })
  //     .catch(error => {
  //       console.log("error catched")
  //       console.log(error)

  //       toastr.error( "Please check the inputs and submit the form again.",
  //                     "An error occurred during the process")
   
  //     })
  //   }
  // }

  handleSubmitForReviewButtonClick = () => {
  	const {
  		emp_signature,
  		//witness_by,
  		//witness_signature,
  		//witness_signed_at,
  		id,
  	} = this.state.assessment

  	if (!emp_signature) {
  		toastr.error( "Please fill the required fields",
                      "An error occurred during the process")
  	} else {
  		this.setState({is_pdf_processing: true })

  		setupCSRFToken();

  		axios.post(`/assessments/${id}/submit_for_review`, {})
      .then(response => {
        this.setState({ 
	        is_pdf_processing: false,
	      })

        toastr.success( "Your document has been submitted for review",
                      "Transaction Completed")
        window.location.reload(true);
        
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        this.setState({ is_pdf_processing: false })

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })
  	}
  }
  
  render() {
  	const {
  		user,
  		assessment,
  		isLoaded,
      is_pdf_processing
  	} = this.state

  	return (
  		<React.Fragment>
  			<div className="row">
	  			<div className="col-md-6 mt-50">
	    			
	    			<EmployeeSignaturePad
	    				user={user}
	    				assessment={assessment}
	    				updateAssessmentState={this.updateAssessmentState} />
	    			
	    			<div className="mt-30">
	    				<h4>Employee Name</h4>
	    				<h3>{user.full_name }</h3>
	    			</div>

	    			<div className="mt-30">
	    				<h4>Date: </h4>
	    				{assessment.emp_signed_at
		    				? <p>{moment(assessment.emp_signed_at).format("DD/MM/YYYY hh:mm:ss A")}</p>
		 	
		    				: <p>..................................................................</p>
		    				
	    				}
	    			</div>
	    			
	    		</div>

	    		{/*<div className="col-md-6 mt-50">

	    			<WitnessSignaturePad
	    				user={user}
	    				assessment={assessment}
	    				updateAssessmentState={this.updateAssessmentState} />
	    			
	    			<div className="mt-30">
	    				<h4>Witness Name</h4>
	    				{assessment.witness_by ? (
	    					<div>
	    						<h3 style={{textTransform: 'uppercase'}}>{assessment.witness_by}</h3>
	    						<a href="#" onClick={this.handleWitnessByRemove}>Remove</a>
	    					</div>
	    				) : (
	    					<div>
	    						<input type="text" 
		    					name="witness_by" 
		    					className="form-control"
		    					onBlur={this.handleWitnessByChange} />
		    					<span className="text-muted"><em> Required</em></span>
	    					</div>
	    				) }
	    				
	    			</div>  */}

	    			{/* <div className="mt-30">
	    				<h4>Date: </h4>
	    				{assessment.witness_signed_at
		    				? <p>{moment(assessment.witness_signed_at).format("DD/MM/YYYY hh:mm:ss A")}</p>
		 	
		    				: <p>..................................................................</p>
		    				
	    				}
	    			</div> */}

	  		</div>
	  		
	  		<hr />

        <div className="d-flex justify-content-between">
          <div>
            <a href="/assessments"
               className="btn btn-default btn-quirk mr-10" >Back to Assessments</a>
          </div>
          <div>
            {( () => {
              if(is_pdf_processing) {
                return (
                    <>
                      <button className="btn btn-primary btn-quirk btn-danger">Request in progress...</button>
                      <BeatLoader
                        sizeUnit={"px"}
                        size={7}
                        color={"#a6b0c5"}
                        loading={true} />
                    </>
                  )
              } else {
                return (
                  <>
                    <button className="btn btn-primary btn-quirk mr-10" 
                    onClick={this.handleSubmitForReviewButtonClick}>Submit for Review</button>
                    
                  </>
                )
                
              }
            })() }
          </div>
        </div>
	  		
  		</React.Fragment>
  	)
  }
}

SignatureComponent.propTypes = {
  user: PropTypes.object,
  assessment: PropTypes.object
};

export default SignatureComponent;