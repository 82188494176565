import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import Dropzone from 'react-dropzone';
//import Jimp from 'jimp';

import imageCompression from 'browser-image-compression';

import { BeatLoader } from 'react-spinners';
import _ from 'underscore';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

import DocumentItem from './document_item'

class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      work_order: this.props.work_order,
      documents: this.props.documents || [],
      current_user_id: this.props.current_user_id,
      is_loading: false,
      is_attachment_loading: false,
    };

    this.onAttachmentDrop = this.onAttachmentDrop.bind(this)
  }

  onAttachmentDrop = file => {
    
    const work_order_id = this.state.work_order.id
    const fd = new FormData();

    this.setState({ is_attachment_loading: true })

    let documents = this.state.documents
    let self = this;

    fd.append('document[file]', file[0], file[0].name)
    fd.append('document[name]', file[0].name)
    fd.append('document[documentable_id]', this.state.work_order.id)
    fd.append('document[documentable_type]', 'WorkOrder')
    
    setupCSRFToken();
    
    axios.post('/documents/', fd)
    .then(response => {
      documents.push(response.data.document)

      self.setState({
        documents: documents,
        is_attachment_loading: false,
      })

      //console.log(self.state.loading_images_count)

      // toastr.success("New attachments have been uploaded successfully")
        
    })
    .catch(error => {
      console.error(error.message)
    })
    
  }

  render () {
    const {
      work_order,
    	documents, 
    	is_loading,
		is_attachment_loading
	} = this.state

    return (
      <div>
        <div className="row">
          {documents.map((document, index) => (
            <DocumentItem
              document={document}
              work_order={work_order}
              current_user_id={this.props.current_user_id}
              key={index} />
          ))}

    	{is_attachment_loading && <div className="comment-attachments-column col-xs-3 col-md-2">
						              <div className="comment-attachment-wrapper loading">
						                <BeatLoader
						                  sizeUnit={"px"}
						                  size={7}
						                  color={"#a6b0c5"}
						                  loading={true}
						                />
						              </div>
						            </div>
						        }
        </div>
        
        <Dropzone
          onDrop={this.onAttachmentDrop}
          multiple={false}
          uploadMultiple={false}>
            {({getRootProps, getInputProps}) => {
              return (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div>
                      <button className="btn btn-default"><i className="fa fa-upload"></i>Upload attachments</button>
                      <p>Drag files here, or click to select files</p>
                    </div>
                  </div>
                </section>
              )
            }}
        </Dropzone>
      </div>
    )
  }
}

export default Documents
