import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types"

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import {Form} from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

import ClientAcknowledgementFormPartial from './client_acknowledgement_form_partial';

const CompleteReport = ({
  site_visit_item
}) => {
  const [loading, setLoading] = useState(false)
  const [site_visit, setSiteVisit] = useState(site_visit_item)
  const [inspector_signature, setInspectorSignature] = useState("")
  const [sigPad, setSigPad] = useState({})

  const handleSignatureSubmit = event => {
    event.preventDefault()

    if(sigPad.isEmpty()) {
      toastr.error( "Please sign on the Signature Pad",
                    "Your Signature is required ")

    } else {
      const signatureDataUrl = sigPad.getTrimmedCanvas().toDataURL('image/png')
      
      const {
        id,
        inspector_signature,
        was_client_presented,
        have_you_met_client,
        reason_not_met_client,
        client_contact_name,
        client_contact_job_role
      } = site_visit;

      const payload = {
        inspector_signature: signatureDataUrl,
        was_client_presented: was_client_presented,
        have_you_met_client: have_you_met_client,
        reason_not_met_client: reason_not_met_client == undefined ? "" : reason_not_met_client,
        client_contact_name: was_client_presented == false && have_you_met_client == false ? "" : client_contact_name,
        client_contact_job_role: client_contact_job_role
      }
      
      setupCSRFToken();

      console.log()

      axios.post(`/site_visits/${id}/complete`, {site_visit: payload})
      .then(response => {
        window.location.reload()
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })
    }
  }

  const handleSignatureRemove = e => {
    e.preventDefault()
    setInspectorSignature("")
    sigPad.clear();
  }

  const handleChange = event => {
    setSiteVisit({
      ...site_visit,
      [event.target.name]: event.target.value
    })
  }

  return(
    <React.Fragment>
      <form onSubmit={handleSignatureSubmit}>
        <div>

          <ClientAcknowledgementFormPartial 
                  handleChange={handleChange}
                  site_visit={site_visit}/>

          <div className="form-group mb-30">
            <label>Inspector Signature (Required)</label>

            <SignatureCanvas penColor='black'
                            canvasProps={{width: 500, height: 200,className: 'sigCanvas',
                                          style: {background: '#f6f8fa'} }}
                            ref={ref => { setSigPad(ref) }} />

            <div>
              <a className="mt-10 mb-10" href="#" onClick={e=> handleSignatureRemove(e)}>Clear Signature</a>
            </div>
          </div>
        </div>
        <div>
          
          <button
                className="btn btn-primary btn-quirk" 
                onClick={handleSignatureSubmit}>Save Changes </button>
        </div>
          
      </form>

    </React.Fragment>
  )
}
export default CompleteReport;
