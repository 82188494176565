import React from 'react'

import AttachmentsModel from '../attachments';

const SubSectionAuditResult = ({selected_inspectable_sub_section}) => {

	return (
    <React.Fragment>
    	<div className="audit-result-box">
        <div className="result-item">
          <h4 className="sub-heading">COMMENT</h4>
          <p>{selected_inspectable_sub_section.note || "Not Awailable"}</p>
        </div>

        <div className="result-item">
          <h4 className="sub-heading">SCORE</h4>
          <div>
          	{_.times(selected_inspectable_sub_section.score, function(n){ 
          		return <i className="fa fa-star star-rating font-size-16 active" key={n}></i>
          	 }) }

          	{_.times( (5 - selected_inspectable_sub_section.score), function(n){ 
          		return <i className="fa fa-star star-rating font-size-16" key={n}></i>
          	 }) }

          </div>
        </div>

        <div className="result-item">
          <h4 className="sub-heading">PHOTOS</h4>
          <AttachmentsModel attachable_item={selected_inspectable_sub_section} />
        </div>
      </div>
    </React.Fragment>
  );

}

export default SubSectionAuditResult;