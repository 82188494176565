import React from 'react';

import queryString from 'query-string'

class FiltersPabel extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      range: "custom",
    };
    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount= async () => {
  	await this.setDefaultValues()
  }

  setDefaultValues = () => {
    const parsed = queryString.parse(location.search);
    const range = parsed.range
    const _from = parsed.from
    const _to = parsed.to

    this.setState({
      from: _from,
      to: _to,
      range: range,
    })
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
	
  render () {
    const {
      from,
      to,
      range
    } = this.state

  	return (
      <React.Fragment>
	      <div className="panel col-md-12">
          <div className="panel-heading">
            <h4 className="panel-title">Allocated Staff</h4> 
            <p className="nomargin">Time Tracking Details for allocated staff</p>
          </div>

          {/* <div className="panel-body pt-0 ">
            <div className="media media-gray mb-30 d-flex justify-content-between">
              <div>
                <a className={`btn btn-default w-100 mr-5 ${range=='this_week' ? 'btn-primary' : ''}`} href="?range=this_week">This Week</a>
                <a className={`btn btn-default w-100 mr-5 ${range=='last_week' ? 'btn-primary' : ''}`} href="?range=last_week">Last Week</a>
                <a className={`btn btn-default w-100 mr-5 ${range=='last_fortnight' ? 'btn-primary' : ''}`} href="?range=last_fortnight">Last Fortnight</a>
              </div>
              
              <form className="form-inline">
                <input type="hidden" 
                        name="range" 
                        className="form-control" 
                        value="custom" />
                
                <input type="date" 
                        name="from" 
                        className="form-control" 
                        value={from}
                        onChange={this.handleChange} />
                
                <input type="date" 
                        name="to" 
                        className="form-control" 
                        value={to}
                        onChange={this.handleChange} />

                <button className="btn btn-primary">Submit</button>
              </form>

            </div>
          </div> */}
        </div>
			</React.Fragment>
   
    );

  }
}


export default FiltersPabel;
