import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

class ToolboxMeetingReportHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTitleEditable: false,
      toolbox_meeting: this.props.toolbox_meeting,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.baseState    = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)

    this.toggleTitleEditable()
  }

  handleChange = event => {
    this.setState({
      toolbox_meeting: {
        ...this.state.toolbox_meeting,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const toolbox_meeting = this.state.toolbox_meeting

    console.log(toolbox_meeting)

    setupCSRFToken();

    axios.put(`/toolbox_meetings/${toolbox_meeting.id}`, { toolbox_meeting: toolbox_meeting })
    .then(response => {
      this.setState({
        toolbox_meeting: response.data,
        isTitleEditable: false,
        isSVChanged: true,
      })

      this.baseState = this.state

      toastr.success("The report has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
      

      // window.flash_messages.addMessage(
      //    { id: Math.floor((Math.random() * 1000)), 
      //       message: error.response.data.message, 
      //       type: 'error' });
    })

  }

  toggleTitleEditable = () => {
    this.setState({
      isTitleEditable: !this.state.isTitleEditable
    })
  }

  render () {

    if (this.state.isTitleEditable) {
      return(
        <form name="ToolboxMeetingHeaderForm" className="tool-box-form" onSubmit={this.handleSubmit} >
          <section className="panel-report-header">
            <div className="report-title">
              <h4>Report Title</h4>
              <div className="form-group">
                <input className="form-control input-lg" 
                  type="text" 
                  value={this.state.toolbox_meeting.title} 
                  onChange={this.handleChange}
                  name="title" />
              </div>
            </div>

            <div className="form-group mt-10">
              <input type="submit"
                className="pull-right btn btn-primary btn-quirk"
                value="SAVE CHANGES" />
              <button onClick={this.resetForm} className="pull-right btn btn-default btn-quirk mr-5">Cancel</button>
            </div>
          </section>
        </form>
      )
    }

    else {
      return (
        <section className="panel-report-header">
          <div className="report-title">
            <h4>Report Title</h4>
            <div className="form-group">
              <h3>{this.state.toolbox_meeting.title}</h3>
            </div>
          </div>
          
          <ul className="panel-options pt-10">
            <li><a href="javascript:void(0)" onClick={this.toggleTitleEditable}>Edit Title</a></li>
          </ul>
        </section>
      );
    }
    

  }
}

export default ToolboxMeetingReportHeader
