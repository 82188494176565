import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

class TopClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
 			clients: [],
 			top_clients_with_numbers: []
    };
  }

  componentDidMount = () => {
  	this.getWidgetDetails()
  }

  getWidgetDetails = () => {
    axios.get(`/top_clients.json`)
    .then(response => {
      this.setState({
        clients: response.data.top_clients,
        top_clients_with_numbers: response.data.top_clients_with_numbers,
        is_loading: false,
      })
    })
    .catch(error => {
      console.error(error.message)
      this.setState({
        is_loading: false,
      })
    })
  }

  render() {

  	const {
  		is_loading,
  		clients,
  		top_clients_with_numbers
  	} = this.state

  	if (is_loading) {
  		return ""
  	} else {
  		return (
				<React.Fragment>
					<h4 className="panel-title mb10">TOP CLIENTS</h4>
					<div className="row">
						<div className="col-md-12 mb-10">
							<div className="d-flex pb-10" style={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
								
							
								{top_clients_with_numbers && top_clients_with_numbers.map( (item, index)=> {
									let client = clients.filter( (client, index) => item.id == client.id)[0]

									return <ClientItem key={index} client={client} number_of_sites={item.number_of_sites}/>
								} )}


							</div>
						</div>
					</div>
				</React.Fragment>
			)
  	}
  	
  }
}


const ClientItem = ({client, number_of_sites}) => {
  return (
  	<a href={`/clients/${client.hashid}`}>
	    <div className="recent-item-card d-flex">
				<div className="mr-5">
					<img src={client.logo} className="w-50 client-logo" />

				</div>
				<div>
					<h4>{client.primary_site.name.substring(0, 25)}</h4>
					<p className="text-muted">{number_of_sites} Sites</p>
				</div>
			</div>
		</a>
  )
}

export default TopClients;
