import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import { BeatLoader } from 'react-spinners'

import TasksMappingItemForm from './_form';

class NewTasksMapping extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    	task_name: "",
      frequency_type: "daily",
      sunday: 0,
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      tasks_template: this.props.tasks_template,

      is_submit_loading: false,
    };

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleFrequencyClick = this.handleFrequencyClick.bind(this)
    this.baseState = this.state
  }


  handleChange = event => {
    this.setState({
    	[event.target.name]: event.target.value
      
    });
  }

  handleFrequencyClick = event => {
    this.setState({
    	[event.target.name]: Number(event.target.value) == 1 ? 0 : 1
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const self = this
    const formData = new FormData();
    const { task_name, 
            frequency_type, 
            sunday,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
          
            tasks_template} = this.state

    self.setState({ is_submit_loading: true })

    formData.append('tasks_template', true )

    formData.append('tasks_mapping[task_name]', task_name )
    formData.append('tasks_mapping[frequency_type]', frequency_type )
    formData.append('tasks_mapping[frequencies][sunday]', sunday )
    formData.append('tasks_mapping[frequencies][monday]', monday )
    formData.append('tasks_mapping[frequencies][tuesday]', tuesday )
    formData.append('tasks_mapping[frequencies][wednesday]', wednesday )
    formData.append('tasks_mapping[frequencies][thursday]', thursday )
    formData.append('tasks_mapping[frequencies][friday]', friday )
    formData.append('tasks_mapping[frequencies][saturday]', saturday )

    setupCSRFToken();

    axios.post(`/tasks_templates/${tasks_template.id}/tasks_mapping`, formData)
    .then(response => {
      
      const tasks_mapping = response.data.tasks_mapping
      
      this.setState(this.baseState)
      self.props.updateTasksMappings(tasks_mapping)

    })
    .catch(error => {
      console.log("error catched")
      console.log(error)

      toastr.error( "Please check the inputs and submit the form again.",
      							"An error occurred during the process")
 
    })

    self.setState({ is_submit_loading: false })
  }

  render(){

    const {
      task_name,
      frequency_type,
      tasks_template,
    } = this.state
    
    return(
      <React.Fragment>
        <div className="task-item-row">
          <div className="new-task-form">
            <h4>New Tasks Mapping</h4>
            <form onSubmit={ this.handleSubmit}>

              <TasksMappingItemForm
                handleChange={this.handleChange}
                handleFrequencyClick={this.handleFrequencyClick}
                {...this.state} />  
              

              <button type="submit"
                className="btn btn-primary">SUBMIT</button>

            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NewTasksMapping;
