import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import _ from 'underscore';

import AssessmentsListItem from './_assessments_list_item';

const InductionPanel = ({
  user,
  designation,
  current_stage,
  user_assessments,
  assessment_types,

  handleCurrentStageChange,
  updateUserAssessments,
  remoteUserAssessments

}) => {

  if (current_stage == "training"){
    return (
      <React.Fragment>
        <hr className="m-0" />
        <div className="panel-heading active">
          <div className="d-flex">
            <span className="icon-button font-weight-bold mt-5">2</span>
            <div className="ml-10">
              <h3 className="m-0">Training & Induction</h3>
              <p>Set up required training modules.</p>
            </div>
          </div>
        </div>
        <div className="panel-body">
          <ul className="list-group">
              
            {_.map(assessment_types, (name, assessment_type) => {
              return (
                <AssessmentsListItem
                  key={assessment_type}
                  assessment_type={assessment_type}
                  assessment_name={name}
                  user_assessments={user_assessments} 
                  user={user}
                  updateUserAssessments={updateUserAssessments}
                  remoteUserAssessments={remoteUserAssessments} />
              )
            }) }

          </ul> 

          <div className="form-group">
            <button className="btn btn-primary btn-quirk" onClick={() => handleCurrentStageChange('finalise')}>SAVE & NEXT</button>
          </div>
        </div>
      </React.Fragment>
    )
  } else {
    return (
      <React.Fragment>
        <hr className="m-0" />
        <div className="panel-heading disabled">
          <div className="d-flex">
            <span className="icon-button font-weight-bold mt-5">2</span>
            <div className="ml-10">
              <h3 className="m-0">Training & Induction</h3>
              <p>Set up required training modules.</p>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  
}



export default InductionPanel;
