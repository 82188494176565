import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

import CommentAttachments from './comment_attachments';

class ToolboxMeetingCommentItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCommentEditable: false,
      comment: this.props.comment,
      attachments: this.props.comment.attachments || [],
      isCommentRemoved: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteComment = this.deleteComment.bind(this)
    this.baseState    = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)

    this.toggleCommentEditable()
  }

  handleChange = event => {
    this.setState({
      comment: {
        ...this.state.comment,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const comment = this.state.comment

    setupCSRFToken();

    axios.put(`/comments/${comment.id}`, { comment: comment })
    .then(response => {
      this.setState({
        comment: response.data,
        isCommentEditable: false,
      })

      this.baseState = this.state

      toastr.success("The comment has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
    })

  }

  deleteComment = e => {
    e.preventDefault();

    if(window.confirm('Are you sure want to delete the item?')){
      const comment = this.state.comment

      setupCSRFToken();

      axios.delete(`/comments/${comment.id}`,)
      .then(response => {
        this.setState({ isCommentRemoved: true })

        this.props.unSetComment(comment.id)

        toastr.success("The comment has been deleted successfully!")
          
      })
      .catch(error => {
        console.log("error catched")
        console.log(error.response)

        toastr.error("An error occurred during the process")
      }) 
    }
  }

  toggleCommentEditable = () => {
    this.setState({
      isCommentEditable: !this.state.isCommentEditable
    })
  }

  render () {
    const {isCommentEditable, comment, attachments} = this.state


    if (isCommentEditable) {
      return(
        <div className="comment new-comment">
      
          <form className="toolbox-meeting-form" 
                onSubmit={this.handleSubmit} >

            <div className="form-group">
              <label>Issue/Task Name</label>
              <input type="text" 
                      name="subject" 
                      className="form-control" 
                      placeholder="External Glass Cleaning"
                      value={comment.subject || ""}
                      onChange={this.handleChange} />
            </div>

            <div className="form-group">
              <label>Comment</label>
              <textarea name="message" 
                        className="form-control w-p100 h-min-150"
                        value={comment.message || ""}
                        onChange={this.handleChange}></textarea>
            </div>
            <div className="form-group mt-10">
              <input type="submit"
                className="pull-right btn btn-primary btn-quirk"
                value="SAVE CHANGES" />
              <button onClick={this.resetForm} className="pull-right btn btn-default btn-quirk mr-5">Cancel</button>
            </div>
          </form>
        </div>
      )
    }

    else if (!this.state.isCommentRemoved) {
      return (
        <div className="comment-box" key={comment.id}>
          <div className="comment-header">
            <h4>{comment.subject}</h4>

            <ul className="panel-options pt-10">
              <li><a href="javascript:void(0)" onClick={this.toggleCommentEditable}>Edit</a></li>
              <li>|</li>
              <li><a href="javascript:void(0)" onClick={this.deleteComment}>Delete</a></li>
            </ul>
          </div>
          <div className="comment-body">
            <div className="comment-message mb-30">
              <label className="w-100"><strong>Description: </strong></label>
              <p>{comment.message}</p>
            </div>

            <hr />
            <CommentAttachments 
              attachments={attachments}
              comment_id={comment.id} />

          </div>
          <div className="comment-footer">
            
          </div>

        </div>
      );
    }

    else {
      return ("")
    }
  }
}

export default ToolboxMeetingCommentItem
