import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {} from '../../../duck/actions';


import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'


import toastr from 'toastr';
import Dropzone from 'react-dropzone';
import imageCompression from 'browser-image-compression';

import { BeatLoader } from 'react-spinners';
import _ from 'underscore';


import AttachmentItem from './attachment_item'
import AttachmentsLoading from '../common/attachments_loading'

class AttachmentsModel extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
    	is_loading: false,
    	attachable_item: this.props.attachable_item,
    	attachments: [],
    	loading_images_count: 0,
    	show_attachments: false,

    };

    this.handleViewPhotosLinkClick = this.handleViewPhotosLinkClick.bind(this);
    this.onImageDrop = this.onImageDrop.bind(this)
    this.baseState = this.state
  }

  componentDidMount = () => {
    this.handleViewPhotosLinkClick()
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.attachable_item.id !== this.props.attachable_item.id) {
      //this.setState({ attachments: [] })
      this.handleViewPhotosLinkClick()
    }
  }

  handleViewPhotosLinkClick = async ()  => {
    console.log("called")
  	const {inspection} = this.props
  	const inspectable_item_id = this.props.attachable_item.id

  	await this.setState({ is_loading: true})

  	const response = await axios.get(`/inspections/${inspection.id}/inspectable_item/${inspectable_item_id}/attachments`)
    
    await this.setState({
	          attachments: response.data.attachments,
	          is_loading: false,
	          show_attachments: true,
	        })
  }

  onImageDrop = files => {
    
    const {inspection} = this.props
  	const attachable_id = this.state.attachable_item.id

    let attachments = this.state.attachments
    let self = this;
    
    self.setState({ loading_images_count: files.length })

    _.each(files, (file,index) => {
      
      const fd = new FormData();
      let files_length = files.length
      let i = 0;
      
      let imageResizeOptions = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000,
        useWebWorker: true
      }

      imageCompression(file, imageResizeOptions)
      .then(function (compressedFile) {

        fd.append('attachment[file]', compressedFile, file.name)
        fd.append('attachment[attachable_id]', attachable_id)
        fd.append('attachment[attachable_type]', 'InspectableItem')
         
        setupCSRFToken();
        
        axios.post('/attachments/', fd)
        .then(response => {
          attachments.push(response.data)

          self.setState({
            attachments: attachments,
            loading_images_count: (self.state.loading_images_count - 1),
          })

          console.log(self.state.loading_images_count)

          // toastr.success("New attachments have been uploaded successfully")
            
        })
        .catch(error => {
          console.error(error.message)
        })

      })
      
      .catch(function (error) {
        console.error(error.message);
        toastr.error("An error occurred during the process")
      });

    })
    
  }


  
  render () {
  	const {
  		is_loading,
  		attachments,
  		loading_images_count,
  		show_attachments
  	} = this.state

  	if (is_loading){
  		return <AttachmentsLoading />
  	}

  	if (!show_attachments){
  		return <button className="btn btn-link p-0"
  							onClick={this.handleViewPhotosLinkClick}>View Photos</button>
  	}

  	return (
  		<React.Fragment>
  			{attachments.length == 0 &&
					<p className="mb-30">No Attachments Available</p>
				}
        
        {loading_images_count > 0 &&
          <span className="p-5 mb-10" style={{background: '#E91E63', borderRadius: '3px', color: '#ffffff',fontWeight: 'bold', display: 'block', width: '320px'}}>Please wait while we are processing your request...</span>
        }

  			<div className="d-flex flex-row flex-wrap">
          {attachments.map((attachment, index) => (
            <AttachmentItem
              attachment={attachment}
              key={index} />
          ))}

          {[...Array(loading_images_count)].map((count, key) => (
            <div className="comment-attachments-column mr-5" key={key}>
              <div className="comment-attachment-wrapper loading">
                <BeatLoader
                  sizeUnit={"px"}
                  size={7}
                  color={"#a6b0c5"}
                  loading={true}
                />
              </div>
            </div>
          ))}
        </div>
        

        {loading_images_count === 0 &&
          <Dropzone
            onDrop={this.onImageDrop}
            accept="image/*"
            multiple={true}
            uploadMultiple={true}>
              {({getRootProps, getInputProps}) => {
                return (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div>
                        <button className="btn btn-default"><i className="fa fa-upload"></i>Upload Photos</button>
                        <p>Drag files here, or click to select files</p>
                      </div>
                    </div>
                  </section>
                )
              }}
          </Dropzone>
        }
  		</React.Fragment>
  	)

  }
}



const mapStateToProps = state => {
  return {
    ...state.inspection,
  }
}

const mapDispatchToProps = dispatch => ({
  //setSelectedInspectableSection: inspectable_section => dispatch(setSelectedInspectableSection(inspectable_section)),
  //setSelectedInspectableSubSection: inspectable_sub_section => dispatch(setSelectedInspectableSubSection(//inspectable_sub_section)),
  //removeSelectedInspectableSubSection: () => dispatch(removeSelectedInspectableSubSection() )
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(AttachmentsModel);



//<QuickForm sub_section_id={sub_section_id} is_tasks_template={false} tm={tm}/>
