import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select';

// import axios from 'axios';
// import setupCSRFToken from 'config/setupCSRFToken'
// import serialize from 'form-serialize';

class SiteSections extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      site: this.props.site,
      site_visit: this.props.site_visit,
    };
  }
  
  handleChange = (newValue, actionMeta) => {
    console.group('Value Changed');
    console.log(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  }

  handleInputChange = (inputValue, actionMeta) => {
    console.group('Input Changed');
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  }

  render () {
    const sections = [ { label: "hello", value: 1},
                       {label: "good", value: 2}
                      ]

    return (
      <CreatableSelect
        isClearable
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        options={sections}
      />
    );
  }
}


export default SiteSections
