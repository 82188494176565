import React from 'react'

import AuditSubSectionForm from './audit_sub_section_form';
import SubSectionAuditResult from './sub_section_audit_result';
import InspectableTasksMappingsContainer from '../tasks_mappings/inspectable_tasks_mappings_container';

const InspectableSubSectionItem = props => {

	const {
		inspection,
    is_loading,

    show_tasks_mappings
	} = props

  const {
    selected_inspectable_sub_section,
    selected_inspectable_section
  } = inspection

  if (is_loading && !show_tasks_mappings){
    return <div></div>
  }

	return (
    <React.Fragment>
    	<div className="list-header p-20">
        <div className="d-flex justify-content-between">
          <h4 className="m-0">{selected_inspectable_sub_section.item_name} &nbsp;
          	<span className={`label label-default label-${selected_inspectable_sub_section.status} pull-right p-5 mr-10`}>
            {selected_inspectable_sub_section.status}
            </span>
          </h4>

          <div className="links-wrapper">
            {selected_inspectable_sub_section.id > 0 && 
              <AuditSubSectionForm {...props} />
            }

          </div>
        </div>
      </div>

      <div className="tasks-details">
      	{selected_inspectable_sub_section.status == "completed" &&
          <SubSectionAuditResult
          	selected_inspectable_sub_section={selected_inspectable_sub_section} />
        }

        { show_tasks_mappings &&

          <InspectableTasksMappingsContainer {...props} />

        }
        
        
      </div>
    </React.Fragment>
  );

}

export default InspectableSubSectionItem;