import React, { useState, useEffect } from 'react';

import _ from "underscore";
import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';


import DatePicker from "react-datepicker";
import ShiftForm from "../components/shift_form"

const ShiftEdit = (props) => {

  const shift_id                    = props.match.params.shift_id;
  const [shift, setShift]           = useState({})
  const [formStatus, setFormStatus] = useState("edit")
  const [isLoading, setIsLoading]   = useState(true)

  useEffect(() => {
    fetchShift() 
  }, []);

  const fetchShift = () => {
    axios.get(`/shifts/${shift_id}.json`)
      .then(response => {
        setShift(response.data.shift)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error.message)
        setIsLoading(false)
      })
  }

  const submitShiftForm = shift => {

    setupCSRFToken();
    setFormStatus("processing")
   
    axios.put(`/shifts/${shift_id}`, {shift: shift})
      .then(response => {
        
        setShift(response.data.shift)
        window.location.href = `/shifts/${shift_id}`
        setFormStatus("submitted")

        toastr.success( "The shift has been updated successfully")

      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })

    
  }

  if(isLoading) {
    return "Loading..."
  } else {
    return (
      <React.Fragment>
        <ShiftForm shiftItem={shift}
                    site_id={shift.site_id}
                    isEdit={true}
                    submitShiftForm={submitShiftForm} />
      </React.Fragment>
    );
  }
}

const ShiftItemSummary = ({
  shiftItem
}) => {

  console.log(shiftItem)

  return(
    <tr>
      <td>{shiftItem.name}</td>
      <td><a href={`/shifts/${shiftItem.hashid}`} className="btn btn-sm btn-default">View More</a></td>
    </tr>
  )
}
  

export default ShiftEdit