import React from 'react'
import { render } from 'react-dom'

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string'

import FiltersPabel from './components/_filters'
import TimeTrackingChartPanel from './components/time_tracking_chart_panel'

import TimeTrackingHeatMapPanel from './components/time_tracking_heatmap_panel'
import TimeTrackingDataTable from './components/time_tracking_table_view'

class SiteTimeTrackings extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	site: {},
      sorted_time_trackings_for_graph: [],
      sorted_time_trackings_for_table: [],
      sorted_time_trackings_for_map: [],
      cleaners: [],
      date_range:[],
      sorted_date_range: [],
      allocated_hours: [],
      is_loading: true,
    };
  }

  componentDidMount() {
  	this.fetchTimeTrackingDetails()
	}

  fetchTimeTrackingDetails = () => {
    const site_id = this.props.match.params.site_id
    const parsed = queryString.parse(location.search);
    const range = parsed.range
    const _from = parsed.from
    const _to = parsed.to

    axios.get(`/sites/${site_id}/trackings.json?range=${range}&from=${_from}&to=${_to}`)
    .then(response => {
      this.setState({
        site: response.data.site,
        sorted_time_trackings_for_graph: response.data.sorted_time_trackings_for_graph,
        sorted_time_trackings_for_map: response.data.sorted_time_trackings_for_map,
        sorted_time_trackings_for_table: response.data.sorted_time_trackings_for_table,
        cleaners: response.data.cleaners,
        date_range: response.data.date_range,
        sorted_date_range: response.data.sorted_date_range,
        allocated_hours: response.data.allocated_hours,
        is_loading: false,
      })
    })
    .catch(error => {
      console.error(error.message)
      this.setState({
        is_loading: false,
      })
    })

  }

  render () {
    const {
      sorted_date_range,
      sorted_time_trackings_for_graph,
      sorted_time_trackings_for_map,
      sorted_time_trackings_for_table,
      site,
      cleaners,
      date_range,
      allocated_hours,
      is_loading
    } = this.state

    if (is_loading) {
      return (
        <React.Fragment>
          <h4 className="panel-title mb20">Time Tracking Report</h4>

          <i>Loading...</i>
        </React.Fragment>
      )
    }
    else {
      return (
        <React.Fragment>
          
          {/* <FiltersPabel /> */}

          
          <TimeTrackingChartPanel 
            sorted_time_trackings_for_graph={sorted_time_trackings_for_graph}
            sorted_date_range={sorted_date_range}
            cleaners={cleaners}
            site={site}
            date_range={date_range}
            allocated_hours={allocated_hours}
            {...this.props} />
          

          <TimeTrackingHeatMapPanel 
            site={site}
            sorted_time_trackings_for_map={sorted_time_trackings_for_map}
            {...this.props} />

          {/*<TimeTrackingDataTable
            time_trackings={sorted_time_trackings_for_table}
            sorted_date_range={sorted_date_range}
            cleaners={cleaners}
            site={site}
            date_range={date_range}
            allocated_hours={allocated_hours}
            {...this.props} /> */}

        </React.Fragment>
     
      );
    }
  	

  }
}

export default SiteTimeTrackings;