//General
import React from 'react'
import PropTypes from 'prop-types';

import AssetIssuingFormContainer from '../components/forms/AssetIssuingFormContainer';

class AssetIssuing extends React.Component {

	render (){
		return(
				<div className="row">
					<div className="col-md-12 col-lg-10 col-sm-12">
						<div className="panel">
						  <div className="panel-heading">
						    <h4 className="panel-title text-success">Issue an Asset</h4>
						    <p className="nomargin">Issue an asset for a specific period</p>
						  </div>
							
							<div className="panel-body">
							  <div className="row panel-body-inner">
							    <div className="col-md-8 col-sm-12">        
							    	<AssetIssuingFormContainer {...this.props} />
								  </div>
							  </div>
							</div>
						</div>

					</div>
				</div> 
			)
	}
}

AssetIssuing.propTypes = {
  asset_issuing: PropTypes.object,
};

export default AssetIssuing