import React, { useState, useEffect } from 'react';

import _ from "underscore";
import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

const ShiftStaffAllocation = (props) => {
  // let {site_id} = useParams();

  //const query_params = useState(queryString.parse(props.location.search))

  const [shifts, setShifts]         = useState([])
  
  return (
    <React.Fragment>
      <section>
        <table className="table table-default table-striped table-hover">
          <thead>
            <tr>
              <th>Emp. ID</th>
              <th>Staff Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Work Type</th>
              <th>Assigned Days</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="w-150"><strong>EMP-00134</strong></td>
              <td>
                <div className="d-flex w-300">
                  <div className="w-50 h-50 mr-5">
                    <img className="img-circle d-block w-p100" src="" alt="Img" />
                  </div>
                  <div className="">
                    <h4 className="media-heading"><a href="#">Angelina Haley</a></h4>
                    <p className="media-usermeta text-muted font-size-12"><i className="glyphicon glyphicon-briefcase"></i> Ground Maintenance Staff</p>
                  </div>
                </div>
              </td>
              <td>kiersten@gmail.com</td>
              <td>0420703336</td>
              <td>Permanent</td>
              <td></td>
              <td className="w-150 ">
                <div className="btn-group mr5">

                  <a className="btn btn-default btn-sm" href="#">View Profile</a>
                  <button type="button" className="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                    <span className="sr-only">Toggle Dropdown</span>
                  </button>
                  
                  <div className="list-group dropdown-menu position-right w-min-300" style={{padding: 0, border: 'none'}} role="menu">
                    <a className="list-group-item list-group-item-action flex-column align-items-start" href="#">
                      <h5 className="mb-1">Resend Site Instructions</h5>
                      <p className="mb-1">Resend instructions for Site allocation and Mobile app</p>
                    </a>

                    <a className="list-group-item list-group-item-action flex-column align-items-start" href="#">
                      <h5 className="mb-1">Edit information</h5>
                      <p className="mb-1">Edit Site Allocation details such as Hours, Dates etc.</p>
                    </a>
                    <a data-confirm="Are you sure you want to delete this?" className="list-group-item list-group-item-action flex-column align-items-start" rel="nofollow" data-method="delete" href="#">
                      <h5 className="mb-1">Remove Designation</h5>
                      <p className="mb-1">Remove this cleaner from this site.</p>
                    </a>            
                  </div>
                  
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </React.Fragment>
  )
}

export default ShiftStaffAllocation