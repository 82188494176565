import React from 'react'

const AttachmentsLoading = () => {
	const imageStyles = {	height: "80px", 
												width: "80px", 
												marginRight: "5px", 
												border: "1px solid #dedede",
    										borderRadius: "4px"}
	return (
    <React.Fragment>
    	<div className="loading-wrapper-cell">
				<div className="image" style={imageStyles} ></div>
				<div className="image" style={imageStyles} ></div>
				<div className="image" style={imageStyles} ></div>
			</div>
    </React.Fragment>
  );

}

export default AttachmentsLoading;