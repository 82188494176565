import React, { useState } from 'react';
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import ListItem from './_list_item';

const List = ({
  tasks_mappings,
  updateTasksMappingItem,
  unSetTasksMappingItem
  }) => {

  return (
    <React.Fragment>
      {tasks_mappings.map((tm, index) => {
        return <ListItem 
                tm={tm} 
                key={index}
                updateTasksMappingItem={updateTasksMappingItem}
                unSetTasksMappingItem={unSetTasksMappingItem} />          
        
        
      } ) }
    </React.Fragment>
  )
}


// const TaskMoreActionsButton = () => {
//   return (
//     <div className="tasks-edit button">

//     </div>
//   )
// }

export default List;



