import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types"

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import {Modal, Button, Form} from 'react-bootstrap';
import SignaturePad from 'react-signature-canvas';

class InspectorSignature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      inspection_id: this.props.inspection_id,
      inspector_signature: null,
    };

    this.handleSignatureSubmit = this.handleSignatureSubmit.bind(this)
    this.handleSignatureRemove = this.handleSignatureRemove.bind(this)

  }

  sigPad = {}

  handleSignatureSubmit = event => {
    event.preventDefault()

    if(this.sigPad.isEmpty()) {
      toastr.error( "Please sign on the Signature Pad",
                    "Your Signature is required ")

    } else {
      const signatureDataUrl = this.sigPad.getTrimmedCanvas().toDataURL('image/png')
      const formData  = new FormData();

      const { error, 
        isLoaded, 
        inspection_id,
        client_contact_name,
        client_contact_job_role,
        client_contact_signature,
        client_feedback,
      } = this.state;

      formData.append('inspection[inspector_signature]', signatureDataUrl )

      setupCSRFToken();

      axios.put(`/inspections/${inspection_id}/update_signature_details`, formData)
      .then(response => {
        this.setState({ 
          inspector_signature: signatureDataUrl,
           signatureModelShow: false,
         })
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error( "Please check the inputs and submit the form again.",
                      "An error occurred during the process")
   
      })
    }

    
  }

  handleSignatureRemove = e => {
    e.preventDefault()

    console.log("clicked")

    this.setState({ 
      inspector_signature: null,
     })

    this.sigPad.clear();
    
  }


  render() {
    const { error, 
      isLoaded, 
      inspection_id,
      inspector_signature,
    } = this.state;
    
    return (
      <React.Fragment>
        <div>
          <SignaturePad 
              canvasProps={{className: 'w-p100 h-300 sigCanvas',
                            style: {background: '#f6f8fa'} }}
              ref={(ref) => { this.sigPad = ref }} />
        </div>

        <a className="mt-10 mb-10" href="#" onClick={this.handleSignatureRemove}>Clear Signature</a>

      </React.Fragment>
    );

  }
}

InspectorSignature.propTypes = {
  inspection_id: PropTypes.number,
};

export default InspectorSignature;