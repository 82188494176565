import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

// import axios from 'axios';
// import setupCSRFToken from 'config/setupCSRFToken';
// import queryString from 'query-string';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
 
    };
    
    console.log(this.props)
  }

  componentDidMount = () => {
  	this.getUserDetails()
  }

  getUserDetails = () => {
    
  }

  render() {

  	return (
			<div className="row row-sm">
        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="widget-card clients-card">
            <div className="d-flex justify-content-between">
              <div className="widget-body">
                <h6 className="widget-title text-white">CLIENTS</h6>
                <h4 className="font-size-30 font-weight-700 text-white">320</h4>
                <p className="mb-0 tx-12 text-white op-7">Total Sites: 1290</p>
              </div>
              <div className="widget-thumb">
                <img className="widget-icon w-60" src="/assets/icons/icons8-city.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="widget-card employees-card">
            <div className="d-flex justify-content-between">
              <div className="widget-body">
                <h6 className="widget-title text-white">Employees</h6>
                <h4 className="font-size-30 font-weight-700 text-white">320</h4>
                <p className="mb-0 tx-12 text-white op-7">Total Sites: 1290</p>
              </div>
              <div className="widget-thumb">
                <img className="widget-icon w-60" src="/assets/icons/icons8-worker-1.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="widget-card inspection-card">
            <div className="d-flex justify-content-between">
              <div className="widget-body">
                <h6 className="widget-title text-white">Inspections</h6>
                <h4 className="font-size-30 font-weight-700 text-white">1102</h4>
                <p className="mb-0 tx-12 text-white op-7">Last Month: 40</p>
              </div>
              <div className="widget-thumb">
                
                <img className="widget-icon w-60" src="/assets/icons/icons8-agreement.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
          <div className="widget-card site-visits-card">
            <div className="d-flex justify-content-between">
              <div className="widget-body">
                <h6 className="widget-title text-white">Site Visits</h6>
                <h4 className="font-size-30 font-weight-700 text-white">2300</h4>
                <p className="mb-0 tx-12 text-white op-7">Last Month: 140</p>
              </div>
              <div className="widget-thumb">
                <img className="widget-icon w-60" src="/assets/icons/icons8-inspection.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
		)

  	
  }
}


export default Dashboard;
