import React from "react";
import Avatar from 'react-avatar';

const TasksTemplatesList = ({
  tasks_templates,
  selected_tasks_template,
  handleTaskTemplateItemClick
}) => {
  

  return (  
    <React.Fragment>
      {tasks_templates.map((tasks_template, index) => (
        <li className={`list-group-x ${selected_tasks_template.id == tasks_template.id ? "active" : ""}`} 
          key={index}
          onClick={() => handleTaskTemplateItemClick(tasks_template)} >
            
          <div className="d-flex">
            <Avatar 
            color="#e4e9f1"
            fgColor="#032f62"
            round="2px"
            name={tasks_template.name} size="30" />

            <div><span className="ml-10"><b>{tasks_template.name}</b></span><br />
            <span className="ml-10 font-size-10">{tasks_template.is_general ? 'Added to General Spec Report' : ''}</span></div>

          </div>
          <i className=" arrow-icon fa fa-chevron-right"></i>
        </li>    
      ))
      }
    </React.Fragment>
  )
}

export default TasksTemplatesList;