import React, {useState, useEffect} from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

const AssignedUserCardItem = ({
  assignee
}) => {

  return (
    <React.Fragment>
      <li id="user_card_1" className="list-group-item d-flex justify-content-between align-items-center">
        <div className="card-flex d-flex" style={{alignItems: 'center !important'}}>
          <div className="img-thumb-box w-30 h-30 mr-10">
            <img className="media-objectd img-thumb" src={assignee.user.avatar_url} />
          </div>
          <div className="client-meta">
            <span className="d-block font-weight-bold">{assignee.user.full_name}</span>
            <span className="label label-default">EMP ID: {assignee.user.employee_id}</span>
            <span className="text-muted ml-5">{assignee.user.email}</span>
          </div>
        </div>
      </li>
    </React.Fragment>
 
  );

}


export default AssignedUserCardItem;
