import React from 'react'

import ListLoader from 'components/common/list_loader'
import Avatar from 'react-avatar';

const InspectableSections = ({
	inspection_id,
  is_loading,
	inspection,
	handleSectionItemClick
	}) => {

	return (
		<React.Fragment>
      <h4 className="panel-title mb10">Inspection</h4>
      <div className="row no-gutters">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="panel panel-specifications col-md-12 h-min-500">
            <div className="row">
              <div className="col-md-4 panel-sp-left pr-md-0">
           
                <div className="sections-list-wrapper">
                  <div className="list-header">
                    <div className="d-flex justify-content-start">
                      <div className="back-btn">
                        <a className="btn btn-default btn-sm" href={`/inspections/${inspection_id}`}>Back</a>
                      </div>
                      <h4> Buildings/ Sections</h4>
                    </div>
                  </div>
                  <ul className="sections-list">
                  	{ is_loading &&
                  		<ListLoader times={4}/>
                  	}

                  	{!is_loading && (inspection.inspectable_sections).map((inspectable_section, index) => (
                        <li className="list-group-x" 
                            key={index}
                            onClick={() => { handleSectionItemClick(inspectable_section)}}>
                          
                          <Avatar 
                          	color="#e4e9f1"
                          	fgColor="#032f62"
                          	round="2px"
                          	name={inspectable_section.item_name} size="30" />

                          <span className="ml-10">{inspectable_section.item_name}</span>
                          
                          <span className={`label label-default label-${inspectable_section.status} pull-right p-5 mr-10`}>{inspectable_section.status}</span>
                          <i className=" arrow-icon fa fa-chevron-right"></i>
                        </li>    
                    	))
                  	}
                 
                  </ul>
                </div>

              </div>

              <div className="col-md-8 panel-sp-right">
                <div className="tasks-details"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
	);
}

export default InspectableSections;