import React, { useState } from 'react';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'

import EditTasksMapping from './edit';

const ListItem = ({
  tm,
  updateTasksMappingItem,
  unSetTasksMappingItem
  }) => {

  const [isEditable, setIsEditable] = useState(false);


  const handleTaskDeleteClick = e => {
    e.preventDefault();

    if(window.confirm('Are you sure want to delete the item?')){

      setupCSRFToken();

      axios.delete(`/tasks_mappings/${tm.id}`,)
      .then(response => {
        const tasks_mapping = response.data.tasks_mapping

        unSetTasksMappingItem(tasks_mapping)

        toastr.success("Item has been removed from the list!")
          
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error("An error occurred during the process")
      }) 
    }
  }

  const handleTaskEditClick = () => {
    setIsEditable(!isEditable)
  }
  
  let {sunday,
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday} = tm.frequencies || {}

    if(isEditable){
      return (
        <div>
          <EditTasksMapping
            tasks_mapping={tm} 
            handleTaskEditClick={handleTaskEditClick}
            updateTasksMappingItem={updateTasksMappingItem}
            />
        </div>
      )
    }
    else {
      return(
        <div className="task-item-row pb-20 mb-5">
          <div className="d-flex justify-content-between">
            <div className="task-name">{tm.task_name}</div>
            <div className="task-details d-flex justify-content-end">
              <div>
                <span className="task-frequency-type mr-5 text-uppercase">{tm.frequency_type}</span>
                <span className={`task-frequency ${!!Number(sunday) ? "active" : ""}`}>S</span>
                <span className={`task-frequency ${!!Number(monday) ? "active" : ""}`}>M</span>
                <span className={`task-frequency ${!!Number(tuesday)? "active" : ""}`}>T</span>
                <span className={`task-frequency ${!!Number(wednesday) ? "active" : ""}`}>W</span>
                <span className={`task-frequency ${!!Number(thursday) ? "active" : ""}`}>T</span>
                <span className={`task-frequency ${!!Number(friday) ? "active" : ""}`}>F</span>
                <span className={`task-frequency ${!!Number(saturday) ? "active" : ""}`}>S</span>
              </div>

            </div> 
          </div>

          <div className="more-actions pull-right">
            <a className=" font-size-10" 
              onClick={() => handleTaskEditClick()}>Edit</a>&nbsp;|&nbsp;
            <a className="font-size-10 text-danger"
              onClick={e => handleTaskDeleteClick(e)}>Delete</a>
            
          </div>
        </div>
      )
      
    }
}


export default ListItem;