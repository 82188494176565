import React from 'react'

import ListLoader from 'components/common/list_loader'

const TasksMappingsLoadingPage = () => {

	return (
    <React.Fragment>
    	<div className="list-header p-20">
        <div className="loading-wrapper-cell">
          <div className="image"></div>
          <div className="text">
            <div className="text-line"></div>
            <div className="text-line w-p50"></div>
          </div>
        </div>
      </div>

      <div className="tasks-details">
        
        <div className="panel-tasks">
          <div className="panel-body">
            <div className="tasks-wrapper">

              <ul className="list-group list-tasks-mappings list-group-striped" style={{listStyleType: "none"}}>

                <ListLoader times={4}/>
             
              </ul>

            </div>
          </div>
        </div>

      </div>
        
    </React.Fragment>
  );

}

export default TasksMappingsLoadingPage;