import React from 'react'

import _ from 'underscore';

const ListLoader = ({times}) => {

	return (
		<React.Fragment>
			{_.times(times || 1, (n) => <ListLoaderItem key={n}/> )}
		</React.Fragment>
	)
}

const ListLoaderItem = ({n}) => {
	return (
		<li className="list-groupl">
			<div className="d-flex">
				<div className="loading-wrapper-cell">
					<div className="image"></div>
					<div className="text">
						<div className="text-line"></div>
						<div className="text-line w-p50"></div>
					</div>
				</div>
			</div>
		</li>
	)
}

export default ListLoader;