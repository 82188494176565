import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setInspectableSections, setInspection } from '../../../duck/actions';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'


import InspectableSections from './inspectable_sections';
import CompleteLoadingPage from '../common/complete_loading_page';

class InspectableSectionsContainer extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      inspection_id: this.props.match.params.inspection_id,
    };

    this.getInspectableSections = this.getInspectableSections.bind(this)

  }

  componentDidMount = async () => {
    this._isMounted = true;
    if (this.props.page != "sub_sections"){
      await this.getInspectableSections();
    }
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getInspectableSections = () => {
    const inspection_id = this.props.match.params.inspection_id

    axios.get(`/inspections/${inspection_id}/inspectable_items?item_type=sections`)
    .then(response => {

      this.props.setInspection(response.data.inspection)
      this.props.setInspectableSections(response.data.inspectable_sections)

      this.setState({ is_loading: false })
    })
    .catch(error => {
      console.error(error.message)
    })

  }

  render () {
    const { is_loading, inspection_id} = this.state
    
    if (is_loading){
      return <CompleteLoadingPage />
    }

    return <InspectableSections
              {...this.props}
              inspection_id={inspection_id}
              is_loading={is_loading} />

  }
}

const mapStateToProps = state => {
  return {
    ...state.inspection,
  }
}

const mapDispatchToProps = dispatch => ({
  setInspection: inspection => dispatch(setInspection(inspection)),
  setInspectableSections: inspectable_items => dispatch(setInspectableSections(inspectable_items)),
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(InspectableSectionsContainer);

