import React from 'react';

import BarChart from './_bar_chart';
//import TableView from './_table_view';
import StaffItem from './_staff_item';

class TimeTrackingChartPanel extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
    	cleaners: this.props.cleaners,
      sorted_time_trackings_for_graph: this.props.sorted_time_trackings_for_graph,
      sorted_date_range: this.props.sorted_date_range,
      allocated_hours: this.props.allocated_hours,
      site: this.props.site,
      date_range: this.props.date_range
    };

  }
	
  render () {

    const {
      sorted_time_trackings_for_graph,
      site,
      cleaners,
      date_range,
      sorted_date_range,
      allocated_hours
    } = this.state

  	return (
      <React.Fragment>
	      <div className="panel">
          <div className="panel-heading pb-0">
            <h4 className="panel-title">Time Tracking Details</h4>
          </div>

          <div className="panel-body">
            <div className="row">
              
              {cleaners.map( (cleaner, index) => {
                return <StaffItem key={index} cleaner={cleaner}/>
              } )
              
              }
            </div>
            <hr />
            
            <BarChart 
              sorted_time_trackings_for_graph={sorted_time_trackings_for_graph}
              cleaners={cleaners}
              site={site}
              sorted_date_range={sorted_date_range}
              date_range={date_range}
              allocated_hours={allocated_hours}
            />

            
          </div>
        </div>
			</React.Fragment>
   
    );

  }
}

export default TimeTrackingChartPanel;
