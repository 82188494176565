import React from 'react'

import Select from 'react-select';
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker.css";

class AssetForm extends React.Component {
  
  constructor(props) {
    super(props);
    const { asset } = this.props

    console.log(asset.assets_sub_category)
    this.state = {
      assets_categories: 			gon.assets_categories,
      assets_sub_categories: 	gon.assets_sub_categories,
      asset: {
	      assets_category_id:     this.props.assets_category ? this.props.assets_category.id : null,
	      assets_sub_category_id: asset.assets_sub_category_id,
	      
	      barcode: 								asset.barcode,
	      code: 									asset.code,
	      condition: 							asset.condition,
	      factory_code: 					asset.factory_code,
	      manufacture: 						asset.manufacture,
	      model: 									asset.model,
				name: 									asset.name,
				next_service_date: 			asset.next_service_date,
				note: 									asset.note,
				product_number: 				asset.product_number,
				purchase_date: 					asset.purchase_date,
				status: 								asset.status,		
			}
 
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSubCategorySelectChange = this.handleSubCategorySelectChange.bind(this)
    this.handleCategorySelectChange = this.handleCategorySelectChange.bind(this)
  }

  handleChange (event) {
    const {name, value } = event.target
    
    this.setState({ 
      [name]: value,
    });
  }

  handleCategorySelectChange = selectedOption => {
    this.setState({ 
    	asset: {
	    	assets_category_id: selectedOption.id,
	    	
	    },
	    assets_sub_categories: gon.assets_sub_categories.filter( 
	    														sub_category => sub_category.assets_category_id == selectedOption.id
	    												),
    });

    console.log(this.state.asset)
  }

  handleSubCategorySelectChange = selectedOption => {
  	let asset  = this.state.asset
  	asset.assets_sub_category_id = selectedOption.id
    this.setState({ 
    	...asset,
    	
    });

    console.log(this.state)
  }

  handleDateChange =  (name, date) => {
	  this.setState({
	  	[name]: date
	  })

	}

	render() {

    const {
      assets_categories,
      assets_sub_categories,
      asset,
	    } = this.state

	  const {
	  	handleSubmit,
	  	handleImageChange
	  } = this.props
      

    return (
      <div>
	      <form name="contactform" className="ad-form" onSubmit={handleSubmit} encType="multipart/form-data" >
	      	<div className="row">
	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Select Asset Category</label>
		          
		          <Select options={assets_categories}
		          				getOptionValue={ (option) => (option['id']) }
		          				getOptionLabel={ (option) => (option['name']) }
		          				name="asset[assets_category_id]"
		          				onChange={this.handleCategorySelectChange}
		          				value={assets_categories.find(option => option.id === asset.assets_category_id)} />
  
	          </div>
	          { asset.assets_category_id != null ? (
		          	<div className="form-group col-md-6 col-sm-12">
			            <label className="font-size-14 d-block">Select Asset Category</label>
				          
				          <Select options={assets_sub_categories}
				          				getOptionValue={ (option) => (option['id']) }
				          				getOptionLabel={ (option) => (option['name']) }
				          				name="asset[assets_sub_category_id]"
				          				onChange={this.handleSubCategorySelectChange}
				          				value={assets_sub_categories.find(ss => ss.id === asset.assets_sub_category_id)} />
		  
			          </div>
			        )
			        : null
	          }
	          
        	</div>

	        <div className="row mt-20">
	          <div className="form-group col-sm-12">
	            <label className="font-size-14 d-block">Wharehouse Code</label>
		          <input 	type="number"
	            				name="asset[barcode]" 
	            				className="form-control"
	                    onChange={this.handleChange} 
	                    value={asset.barcode}
                      required />
  
	          </div>
        	</div>

        	<div className="row mt-20">
	          <div className="form-group col-sm-12">
	            <label className="font-size-14 d-block">Asset Name</label>
		          <input 	type="text"
	            				name="asset[name]" 
	            				className="form-control"
	                    onChange={this.handleChange} 
	                    value={asset.name} />
  
	          </div>
        	</div>

        	<div className="row mt-20">
	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Manufacture</label>
		          <input 	type="text"
	            				name="asset[manufacture]" 
	            				className="form-control"
	                    onChange={this.handleChange} 
	                    value={asset.manufacture} />
  
	          </div>

	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Model</label>
		          <input 	type="text"
	            				name="asset[model]" 
	            				className="form-control"
	                    onChange={this.handleChange} 
	                    value={asset.model} />
  
	          </div>
        	</div>

        	<div className="row">
	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Product No.</label>
		          <input 	type="text"
	            				name="asset[product_number]" 
	            				className="form-control"
	                    onChange={this.handleChange} 
	                    value={asset.product_number} />
  
	          </div>

	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Serial No.</label>
		          <input 	type="text"
	            				name="asset[factory_code]" 
	            				className="form-control"
	                    onChange={this.handleChange} 
	                    value={asset.factory_code} />
  
	          </div>
        	</div>

        	<div className="row">
	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Purchase Date</label>
		          <DatePicker
				        selected={asset.purchase_date}
				        onChange={this.handleDateChange.bind(this, 'purchase_date') }
				        className="form-control w-p100"
				        dateFormat="d/m/yyyy"
				        name="asset[purchase_date]"
				        placeholderText="31/02/2018"
				      />
  
	          </div>

	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Next Service Date</label>
		         	<DatePicker
				        selected={asset.next_service_date}
				        onChange={this.handleDateChange.bind(this, 'next_service_date') }
				        className="form-control w-p100"
				        dateFormat="d/m/yyyy"
				        name="asset[next_service_date]"
				        placeholderText="31/02/2018"
				      />
  
	          </div>
        	</div>

        	<div className="row">
	          <div className="form-group col-md-6 col-sm-12">
	            <label className="font-size-14 d-block">Asset Image</label>
		          <input className="fileInput" 
		          	onChange={handleImageChange}
		          	accept="image/*"       
    						multiple={false}
		            type="file" 
		            name="asset[avatar]"
		            id="avatar_uploader"
		          />
	          </div>
        	</div>

	        <div className="mt-20">
	        	<button type="submit" className="btn btn-primary font-weight-900">Save & Publish</button>
	        </div>

	    	</form>
    	</div>
    )
  }
}

export default AssetForm