import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from "prop-types"
import { BeatLoader } from 'react-spinners';


class SectionsOverview extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: []
    };
  }

  componentDidMount() {
  	this.getSiteDetails();
  }

  getSiteDetails(){
  	let site_id = this.props.site_id

  	fetch(`/sites/${site_id}/site_details`, {
    	headers: {
    		"Content-Type": "application/json"
    	},
    	credentials: "include"
    })
    .then(res => res.json())
    .then(
      (result) => {
        this.setState({
          isLoaded: true,
          result: result
        });
      },

      (error) => {
        this.setState({
          isLoaded: true,
          error
        });
      }
    )
  };
  
  render() {
    const { error, isLoaded, result } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <BeatLoader />;
    } else {
      return (

      	<div>
      		<h4 className="panel-title mb-20">Site Structure</h4>
	      	<div className="row">
	          <div className="col-xs-6 col-sm-4">
	            <div className="pull-left">
	              <div className="icon icon ion-clockd">
	                <img className="w-p100 d-block" src="/assets/icons/section-icon.png" alt="Section icon" />
	              </div>
	            </div>
	            <div className="pull-left">
	              <h4 className="panel-title">Locations</h4>
	              <h3>{result.sections}</h3>
	              <h5 className="text-success">Buildings / Sections </h5>
	            </div>
	          </div>
	          <div className="col-xs-6 col-sm-4">
	            <div className="pull-left">
	              <div className="icon icon ion-eyed">
	                <img className="w-p100 d-block" src="/assets/icons/sub-section-icon.png" alt="Sub section icon" />
	              </div>
	            </div>
	            <h4 className="panel-title">Areas</h4>
	            <h3>{result.sub_sections}</h3>
	            <h5 className="text-success">Sub Sections</h5>
	          </div>
	          <div className="col-xs-6 col-sm-4">
	            <div className="pull-left">
	              <div className="icon icon ion-clockd">
	                <img className="w-p100 d-block" src="/assets/icons/tasks-icon.png" alt="Tasks icon" />
	              </div>
	            </div>
	            <h4 className="panel-title">Tasks</h4>
	            <h3>{result.tasks_mappings}</h3>
	            <h5 className="text-success">Allocated Tasks</h5>
	          </div>
	        </div>
      	</div>
      );
    }
  }
}

SectionsOverview.propTypes = {
  site_id: PropTypes.number
};

export default SectionsOverview;