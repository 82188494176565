import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

class AssetIssuingHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTitleEditable: false,
      assets_issuing: this.props.assets_issuing,
      is_issuing_editable: this.props.is_issuing_editable

    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.baseState    = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)

    this.toggleTitleEditable()
  }

  handleChange = event => {
    this.setState({
      assets_issuing: {
        ...this.state.assets_issuing,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const assets_issuing = this.state.assets_issuing

    setupCSRFToken();

    axios.put(`/ams/issuings/${assets_issuing.id}`, { assets_issuing: assets_issuing })
    .then(response => {
      console.log(response)
      this.setState({
        assets_issuing: response.data,
        isTitleEditable: false,
      })

      this.baseState = this.state

      toastr.success("The issuing has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
      

      // window.flash_messages.addMessage(
      //    { id: Math.floor((Math.random() * 1000)), 
      //       message: error.response.data.message, 
      //       type: 'error' });
    })

  }

  toggleTitleEditable = () => {
    this.setState({
      isTitleEditable: !this.state.isTitleEditable
    })
  }

  render () {

    if (this.state.isTitleEditable) {
      return(
        <form name="AssetsIssuingHeaderForm" className="asset-issuing-form" onSubmit={this.handleSubmit} >
          <section className="panel-report-header">
            <div className="report-title">
              <h4>Issue Header</h4>
              <div className="form-group">
                <input className="form-control input-lg" 
                  type="text" 
                  value={this.state.assets_issuing.issue_header} 
                  onChange={this.handleChange}
                  name="issue_header" />
              </div>
            </div>

            <div className="report-overview">
              <h4>Issue Note</h4>
                <div className="form-group">
                  <textarea className="form-control w-p100" 
                    name="issue_note"
                    value={this.state.assets_issuing.issue_note}
                    onChange={this.handleChange} />
                </div>
            </div>

            <div className="form-group mt-10 pb-50">
              <div className="d-flex justify-content-end">
                <input type="submit"
                className="btn btn-primary btn-quirk mr-5"
                value="SAVE CHANGES" />
                <button onClick={this.resetForm} className="btn btn-default btn-quirk mr-5">Cancel</button>
              </div>
            </div>
          </section>
        </form>
      )
    }

    else {
      return (
        <div className="panel-report-header">
          <div className="report-title">
            <h4>Issue Header</h4>
            <div className="form-group">
              <h3>{this.state.assets_issuing.issue_header}</h3>
            </div>
          </div>

          <div className="report-overview">
            <h4>Issue Note</h4>
              <div className="form-group">
                <p>{this.state.assets_issuing.issue_note}</p>
              </div>
          </div>
          {this.state.is_issuing_editable &&
            <ul className="panel-options">
              <li><a href="javascript:void(0)" onClick={this.toggleTitleEditable}>Edit Title</a></li>
            </ul>
          }
          
        </div>
      );
    }
    

  }
}

export default AssetIssuingHeader
