import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

const UserPanel = ({
	user
}) => {

	
	if (user) {
		return (
			<React.Fragment>
				<div className="panel-heading">
					<div className="d-flex">
						<span className="icon-button font-weight-bold mt-5">1</span>
						<div className="ml-10">
							<h3 className="m-0">Basic information</h3>
							<p>Provide the basic information such as name, email etc.</p>

						</div>
					</div>
				</div>

				<div className="panel-body">

					<div className="selected-user media mb-30 p-20" style={{background: '#e4e6eb', borderRadius: '4px'}}>
		        <div className="media-left">
		          <img className="media-object img-circle w-50" src="/assets/user_avatar_default_150x-b4b00440417d8dce602b9020daa9ba489142938a96d27030dd29a506cd859a09.png" alt="" />
		        </div>
		        <div className="media-body">
		          <h4 className="media-heading nomargin">Floyd M. Romero</h4>
		          <strong>Email: </strong>something@gmail.com
		        </div>
		      </div>
		    </div>
			</React.Fragment>
		)
	} 
	else {
		return (
  		<React.Fragment>
  			<div className="panel-heading">
					<div className="d-flex">
						<span className="icon-button font-weight-bold mt-5">1</span>
						<div className="ml-10">
							<h3 className="m-0">Basic information</h3>
							<p>Provide the basic information such as name, email etc.</p>

						</div>
					</div>
				</div>

				<div className="panel-body">
					<form>
						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
				          <label>First Name</label>
									<input type="text" name="" className="form-control" />
				        </div>

				        <div className="form-group">
				          <label>Middle Name</label>
									<input type="text" name="" className="form-control" />
				        </div>

				        <div className="form-group">
				          <label>Surname/ Last Name</label>
									<input type="text" name="" className="form-control" />
				        </div>

				        <div className="form-group">
				          <label>Email</label>
									<input type="text" name="" className="form-control" />
				        </div>

				        <div className="form-group">
				          <label>Phone</label>
									<input type="text" name="" className="form-control" />
				        </div>

				        <div className="form-group">
			         		<a className="btn btn-default btn-quirk" href="/staffs">GO BACK</a>
			         		<button className= "btn btn-primary btn-quirk ml-5">Save & NEXT</button>
			         	</div>
							</div>
						</div>

						
					</form>
						
				</div>
  		</React.Fragment>
  	)
	}

}

export default UserPanel;
