import React, { Component } from 'react'

import AsyncSelect from 'react-select/async';
import axios from 'axios';



class SearchSite extends Component {
	constructor(props) {
	  super(props);
	  this.state = {
	    sites: [],
	    selectedSite: '',
	    sitesLoaded: false,
	    inputValue: '',
	  };
	}


	fetchSites = inputValue => {
		axios.get('/ams/issuings/search_sites?q=' + inputValue)
    .then(res => {

		  this.setState({
    		sitesLoaded: true,
    		sites: res.data.sites,
    	})

    	return this.state.sites
    	
    })
	  
	};

	loadOptions = (inputValue, callback) => {

	  if (inputValue.length >= 4 ){
	  	callback(this.fetchSites(inputValue));
	  }else{
	  	this.setState({
	  		sitesLoaded:false,
	  	})

	  	this.state.sites
	  }

	};

	handleInputChange = newValue => {
		console.log("newValue")
		console.log(newValue)

    this.setState({ newValue });
    return newValue;
  };

  render(){
  	const { sites } = this.state

    return (
    	<div>
    		<label>Select Site:</label>
    		<AsyncSelect
    					options={sites}
		          loadOptions={this.loadOptions}
		         
		          getOptionValue={ (option) => (option['id']) }
		          getOptionLabel={ (option) => (option['name']) }
		          onInputChange={this.handleInputChange}
							name="assets_issuing[site_id]"
							
		        />

		    <small>Please type first five letters of the site name</small>
    	</div>
    )
    	
  }
}

export default SearchSite;