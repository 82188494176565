import React, { useState } from 'react';
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import { Form } from 'react-bootstrap';

import TasksMappings from './components/_tasks_mappings';

const TasksTemplateEditForm = ({
  tasks_template,
  toggleTitleEditable,
  handleSubmit,
  resetForm,
}) => {

  const [_name, setName] = useState(tasks_template.name)
  const [_description, setDescription] = useState(tasks_template.description)
  const [_is_general, setIsGeneral] = useState(tasks_template.is_general)

  return(
    <form onSubmit={e => handleSubmit(e, _name, _description, _is_general)}>            
      <div className="form-group">
        <label className="form-control-label">Template Name</label>
          <input className="form-control" 
                required="required" 
                type="text" 
                name="name"
                onChange={e => setName(e.target.value)}
                value={_name} />
      </div>

      <div className="form-group">
        <label className="form-control-label">Description</label>
          <textarea className="form-control" 
                    name="description"
                    onChange={e => setDescription(e.target.value)}
                    value={_description}></textarea>
      </div>

      <div className="form-group">
        <label className="toggle">
          <input type="checkbox" 
                  name="is_general" 
                  className="toggle__input"
                  value={_is_general}
                  checked={_is_general ? "checked" : ""}
                  onChange={e => setIsGeneral(e.target.checked)}/>
          <span className="toggle__label">
            <span className="toggle__text">&nbsp; Add to Generel Specifications Report <br />
              <span className="text-muted">&nbsp; This will be shown in Generel spec Report</span>
            </span>
          </span>
        </label>
        
      </div>

      <button className="btn btn-default btn-inline js_submit_button"
              onClick={ () => resetForm()}>CANCEL</button>&nbsp;
      <button type="submit"
              className="btn btn-primary btn-inline js_submit_button">UPDATE CHANGES</button>
    </form>
  )
}

class TasksTemplateItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks_template: this.props.selected_tasks_template,
      tasks_mappings: this.props.tasks_mappings,
      isTitleEditable: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.baseState = this.state

  }
  
  toggleTitleEditable = () => {
    this.setState({
      isTitleEditable: !this.state.isTitleEditable
    })
  }

  resetForm = () => {
    this.setState(this.baseState)
    this.toggleTitleEditable()
  }

  handleSubmit = (e, _name, _description, _is_general) => {
    e.preventDefault();

    const self = this
    const formData = new FormData();

    formData.append('tasks_template[name]', _name )
    formData.append('tasks_template[description]', _description )
    formData.append('tasks_template[is_general]', _is_general )

    setupCSRFToken();

    axios.put(`/tasks_templates/${self.state.tasks_template.id}`, formData)
    .then(response => {
      const tasks_template = response.data.tasks_template

      self.setState({ 
        tasks_template: tasks_template,
        isTitleEditable: false
      })

      self.props.updateTasksTemplateItem(tasks_template)
      toastr.success( "Saved successfully!")

    })
    .catch(error => {
      console.log("error catched")
      console.log(error)

      toastr.error( "Please check the inputs and submit the form again.",
      							"An error occurred during the process")
 
    })

    // self.setState({ is_submit_loading: false })
  }

  deleteTaskTemplate = e => {
    e.preventDefault();

    if(window.confirm('Are you sure want to delete the item?')){
      const tasks_template = this.state.tasks_template

      setupCSRFToken();

      axios.delete(`/tasks_templates/${tasks_template.id}`,)
      .then(response => {

        this.props.unSetTasksTemplate(tasks_template)

        toastr.success("Template has been removed successfully!")
          
      })
      .catch(error => {
        console.log("error catched")
        console.log(error)

        toastr.error("An error occurred during the process")
      }) 
    }
  }

  render(){
    const {
      site_id,
    } = this.props

    const {
      tasks_template,
      isTitleEditable,
      tasks_mappings
    } = this.state

    
  	return(
	  	<React.Fragment>
        <div className="section-details ">
          <div className="panel-heading">
            {isTitleEditable && 
              <TasksTemplateEditForm
                tasks_template={tasks_template}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
                toggleTitleEditable={this.toggleTitleEditable}
                resetForm={this.resetForm} />
            }

            {!isTitleEditable && 
              <div>
                <h3 className="media-heading">{tasks_template.name}</h3>
                  {tasks_template.is_general && 
                    <small className="text-muted">Added to General Specifications Report</small>
                  }
                  <p>{tasks_template.description || "No Description"}</p>
                  
                  <ul className="panel-options">
                    <li><button className="btn btn-link p-0"
                          onClick={this.toggleTitleEditable}>Edit</button> |</li>
                    <li><button className="btn btn-link p-0"
                          onClick={this.deleteTaskTemplate}>Delete</button></li>
                  </ul>
              </div>
            } 
            
          </div>
          <div className="panel-body">
            <TasksMappings
              tasks_mappings={tasks_mappings} 
              tasks_template={tasks_template}/>
          </div>
          

          
        </div>
      </React.Fragment>
	  )
  }
}

export default TasksTemplateItemDetails


