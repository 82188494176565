import React from 'react'
import PropTypes from 'prop-types';
//import $ from 'jquery';
import _ from 'underscore';

// import toastr from 'toastr';
import {Button, Modal, Alert} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

class NewCommentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: this.props.site,
      toolbox_meeting: this.props.toolbox_meeting,
      comment: {
        subject: "",
        message: "",
      },
      files: null,
      showNewCommentModel: false,
      submitLoading: false,
    };

    this.handleChange       = this.handleChange.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);

    this.handleNewCommentModelShow = this.handleNewCommentModelShow.bind(this);
    this.handleNewCommentModelClose = this.handleNewCommentModelClose.bind(this);
  }

  handleNewCommentModelClose() {
    this.setState({ 
      showNewCommentModel: false,
      submitLoading: false,
    });
  }

  handleNewCommentModelShow() {
    this.setState({ showNewCommentModel: true });
  }

  handleChange = event => {
    this.setState({
      comment: {
        ...this.state.comment,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      submitLoading: true
    })

    const toolbox_meeting = this.state.toolbox_meeting
    const formData = new FormData();
    const {subject, message} = this.state.comment

    formData.append('comment[subject]', subject )
    formData.append('comment[message]', message )

    setupCSRFToken();

    axios.post(`/toolbox_meetings/${toolbox_meeting.id}/add_comment`, formData)
    .then(response => {
      this.props.setNewComment(response.data)

      toastr.success("The Comment has been added successfully!")
      
      this.setState({
        comment: {
          subject: "",
          message: "",
        },
      })
      this.handleNewCommentModelClose();

    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")

      this.setState({
        submitLoading: false
      })
    
    })
  }

  render () {

  	const {
  		subject,
  		message,
  		submitLoading
  	} = this.state

    return (
      <div className="comment new-comment">
        <Button variant="primary" className="btn btn-quirk" onClick={this.handleNewCommentModelShow}>
          Add New Comment
        </Button>

        <Modal show={this.state.showNewCommentModel} onHide={this.handleNewCommentModelClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="site-visit-form" 
              onSubmit={this.handleSubmit}
              id="NewCommentForm"
              multipart="true"
              encType="multipart/form-data" >

              

              <div className="form-group">
                <label>Subject</label>
                <input type="text" 
	                name="subject" 
	                className="form-control" 
	                onChange={this.handleChange}
	                value={subject} />
              </div>

              

              <div className="form-group">
                <label>Comment</label>
                <textarea name="message" 
                          className="form-control w-p100 h-min-150"
                          onChange={this.handleChange}
                          value={message} ></textarea>
              </div>
            </form>
            

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleNewCommentModelClose}>
              Back to Report
            </Button>

            { this.state.submitLoading && !this.state.showCommentAtcUpload ?
              <Button className="pull-right btn btn-primary w-150"
                    disabled>
                <BeatLoader
                  sizeUnit={"px"}
                  size={7}
                  color={'#ffffff'}
                  loading={submitLoading}
                />
              </Button>

            :
              <Button className="pull-right btn btn-primary w-150" onClick={this.handleSubmit}>
              SAVE CHANGES
              </Button>
            }

            
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}


export default NewCommentForm
