import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import Chart from 'chart.js';
import {ColorCodes} from 'config/colors';
//import moment from 'moment';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

import axios from 'axios';
// import setupCSRFToken from 'config/setupCSRFToken';
// import queryString from 'query-string';

class InspectionsBySupervisors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      inspections_data: [],
      inspections_chart_data_set: [],
 			supervisors_names: []
    };
  }

  componentDidMount = async () => {
  	await this.fetchReportsData();
  	await this.setChartDataSet();
  	await this.createChart();
  }

  fetchReportsData = async () => {
  	const response = await axios.get(`/inspections_by_supervisors.json`)

    this.setState({
      inspections_data: response.data.inspections
    })

  }

  setChartDataSet = () => {
  	const {inspections_data } = this.state

  	let _supervisors_names = []
  	let inspections_chart_data_set = []

  	inspections_data && inspections_data.forEach( (inspecItem, i) => {
  		if (inspecItem.number_of_inspections > 2) {
  			_supervisors_names.push( inspecItem.first_name)
				inspections_chart_data_set.push(inspecItem.number_of_inspections)
  		}
			
		})


		this.setState({
			inspections_chart_data_set: inspections_chart_data_set,
			supervisors_names: _supervisors_names,

			is_loading: false,
		})

  }

  createChart = () => {

		const ctx = document.getElementById('inspections_by_supervisors_chart');
		const {
			inspections_chart_data_set,
			supervisors_names,
			chart_colours
		} = this.state

		const inspections_by_supervisors = new Chart(ctx, {
				type: 'doughnut',
				data: {
					labels: supervisors_names,
					datasets: [
				    {
				      label: 'Dataset 1',
				      data: inspections_chart_data_set,
				      backgroundColor: ['#5072F6', '#00C6DB', '#FEDF2B' ,'#FFBB22', "#EA4509", '#6236FF', '#0091FF', '#D52E78', '#7E33AB','#D4E155', '#00998E', '#CD80D0', "#ed6a5a","#02c39a","#d62828","#fca311","#fb5607","#9e0059","#390099","#7209b7","#941b0c","#621708","#8900f2","#a100f2","#8900f2","#432371","#9bc53d","#641220","#6e1423","#c71f37","#bd1f36","#a71e34","#662e9b","#cc5803","#e2711d","#ff9505","#143601","#1a4301","#245501","#538d22","#7fb800"],
				      borderWidth: 1,
				    }
				  ],
				},
				// plugins: [ChartAnnotation],
				options: {
			    responsive: false,
			    legend: {
			      	display: true,
			      	align: 'start',
			        position: 'right',
			        labels: {
			        	boxWidth: 10,
			        	boxHeight: 10,
			        }
			      },
			    plugins: {
			      title: {
			        display: false,
			      }
			    }
			  },
		});
	}

  render() {

  	return (
			<div className="panel">
			  <div className="panel-heading">
			    <h4 className="panel-title">Reports By Supervisor</h4> 
			    <p className="tx-12 text-muted mb-0">Number of reports completed by each supervisor for last 12 months</p>
			  </div>

			  <div className="panel-body">
			  	<canvas id="inspections_by_supervisors_chart" style={{display: 'block', width: '100%', minHeight: '300px', height: 'auto'}}></canvas>
			  </div>
			</div>
		)
  	
  }
}


export default InspectionsBySupervisors;
