import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import {Button, Modal, Alert} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners'

import AttachmentsModel from '../attachments';

class AuditTasksMappingItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	is_submit_loading: false,
    	show_audit_form: false,
    	inspectable_tasks_mapping: this.props.selected_tasks_mapping_item,
    	note: this.props.selected_tasks_mapping_item.note || "",
    	score: this.props.selected_tasks_mapping_item.score || 0,
    };

    this.handleChange = this.handleChange.bind(this)
    this.handleScoreChange = this.handleScoreChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEditResultClick =this.handleEditResultClick.bind(this)
    this.handleTasksMappingModelClose = this.handleTasksMappingModelClose.bind(this)
    this.baseState = this.state
    //this.handleShow = this.handleShow.bind(this)
    //this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount = () => {
    if (this.state.inspectable_tasks_mapping.status != "completed"){
    	this.setState({ show_audit_form: true})
    }
  }

  handleChange = event => {
    this.setState({
    	[event.target.name]: event.target.value
      
    });
  }

  handleScoreChange = score => {
  	this.setState({ 
  		score: score
  	})
  }

  handleSubmit = () => {

    const self = this
    const inspectable_item = self.state.inspectable_tasks_mapping
    const formData = new FormData();
    const {note, score} = this.state
    const {inspection} = this.props

    self.setState({ is_submit_loading: true })

    formData.append('inspectable_item[score]', score )
    formData.append('inspectable_item[note]', note )

    setupCSRFToken();

    axios.put(`/inspections/${inspectable_item.inspection_id}/audit_task/${inspectable_item.id}`, formData)
    .then(response => {

      self.setState({ 
      	is_submit_loading: false,
      	show_audit_form: false,
      	inspectable_tasks_mapping: response.data.inspectable_item
      })

      toastr.success("The Task has been audited successfully!")

    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error( "Please check the inputs and submit the form again.",
      							"An error occurred during the process")

      self.setState({ is_submit_loading: false })
    
    })
  }

  handleEditResultClick= () => {
  	this.setState({show_audit_form: true})

  	// if(this.state.inspectable_tasks_mapping.status != "completed"){
  	// 	this.props.handleTasksMappingModelClose()
  	// }
  	// else{
	  // 	//this.setState(this.baseState)
	  // 	this.setState({show_audit_form: !this.state.show_audit_form})
  	// }
  }

  cancelEditAuditedResult = () => {
  	const {
  		inspectable_tasks_mapping
  	} = this.state

  	this.setState({
  			note: inspectable_tasks_mapping.note,
  			score: inspectable_tasks_mapping.score
  		})

  	if(inspectable_tasks_mapping.status != "completed"){

  		this.props.handleTasksMappingModelClose()
  	}
  	else{
	  	this.setState({show_audit_form: !this.state.show_audit_form})
  	}
  }

  handleTasksMappingModelClose =()=> {
  	this.props.getInspectableTasksMappings()
    this.props.handleTasksMappingModelClose()
  }

  render(){
  	const {	
  		inspectable_tasks_mapping,
  		is_submit_loading, 
  		audit_tasks_mapping_model_show,
  		show_audit_form,
  		note, 
  		score,
  	} = this.state

  	const {
  		status,
  		item_name,
  		task_frequency,
  		frequencies
  	} = inspectable_tasks_mapping

  	return(
	  	<React.Fragment>

	  		<Modal show={true} onHide={this.handleTasksMappingModelClose}>
	        
	        <Modal.Header closeButton>
	          <Modal.Title>{item_name}</Modal.Title>
	          <div className="task-frequency-details mt-10 d-flex justify-content-start">
              
	            <div className="task-frequency-details small">
	              <span className="task-details mr-5 label label-default">{task_frequency}</span>
	              <span className={`task-frequency 
	                                  ${frequencies.monday =="1" ? "active" : "" }` }>M</span>
	              <span className={`task-frequency 
	                                  ${frequencies.tuesday =="1" ? "active" : "" }` }>T</span>
	              <span className={`task-frequency 
	                                  ${frequencies.wednesday =="1" ? "active" : "" }` }>W</span>
	              <span className={`task-frequency 
	                                  ${frequencies.thursday =="1" ? "active" : "" }` }>T</span>
	              <span className={`task-frequency 
	                                  ${frequencies.friday =="1" ? "active" : "" }` }>F</span>
	              <span className={`task-frequency 
	                                  ${frequencies.saturday =="1" ? "active" : "" }` }>S</span>
	              <span className={`task-frequency 
	                                  ${frequencies.sunday =="1" ? "active" : "" }` }>S</span>
	            </div>
	          </div>
	        </Modal.Header>
	        
	        {status == "completed" && !show_audit_form &&
        		<TasksMappingAuditResult 
        			inspectable_tasks_mapping={inspectable_tasks_mapping}
        			handleEditResultClick={this.handleEditResultClick} />
        	}

	        <Modal.Body>
	       
	        	{ status != "completed" || show_audit_form ?
		        	<form className="site-visit-form" 
		              onSubmit={this.props.handleTasksMappingModelShow}>

		            <div className="form-group">
		              <label className="d-block" >Your Score:</label>
		              <div className="btn-group w-p100">
		                <button type="button" 
		                				className={`btn btn-default btn-stroke w-p20 ${score == 1 ? "btn-primary" : ""}`}
		                				onClick={this.handleScoreChange.bind(this, 1)}	>
		                	<span className={`fa fa-star font-size-20 star-rating ${score == 1 ? "active" : ""}`}></span><br />
		                	Poor
		                </button>
		                
		                <button type="button" 
		                				className={`btn btn-default btn-stroke w-p20 ${score == 2 ? "btn-primary" : ""}`}
		                				onClick={this.handleScoreChange.bind(this, 2)}	>
		                	<span className={`fa fa-star font-size-20 star-rating ${score == 2 ? "active" : ""}`}></span><br />
		                	Fair
		                </button>

		                <button type="button" 
		                				className={`btn btn-default btn-stroke w-p20 ${score == 3 ? "btn-primary" : ""}`}
		                				onClick={this.handleScoreChange.bind(this, 3)}	>
		                	<span className={`fa fa-star font-size-20 star-rating ${score == 3 ? "active" : ""}`}></span><br />
		                	Good
		                </button>

		                <button type="button" 
		                				className={`btn btn-default btn-stroke w-p20 ${score == 4 ? "btn-primary" : ""}`}
		                				onClick={this.handleScoreChange.bind(this, 4)}	>
		                	<span className={`fa fa-star font-size-20 star-rating ${score == 4 ? "active" : ""}`}></span><br />
		                	Very Good
		                </button>

		                <button type="button" 
		                				className={`btn btn-default btn-stroke w-p20 ${score == 5 ? "btn-primary" : ""}`}
		                				onClick={this.handleScoreChange.bind(this, 5)}	>
		                	<span className={`fa fa-star font-size-20 star-rating ${score == 5 ? "active" : ""}`}></span><br />
		                	Excellent
		                </button>
		              </div>
		            </div>

		            <div className="form-group">
		              <label>Comment</label>
		              <textarea name="note" 
		                        className="form-control w-p100 h-min-150"
		                        onChange={this.handleChange}
		                        value={note} ></textarea>
		            </div>

		          </form>

		          :

		          <div></div>
		        }

	        </Modal.Body>
	        
	        <Modal.Footer>
	        	<div className="d-flex justify-content-end">
	        		<BeatLoader
	        			className="m-10"
	              sizeUnit={"px"}
	              size={7}
	              color={"#0d75e0"}
	              loading={is_submit_loading}
	            />

	            { show_audit_form ?
		            <div>
		            	<Button className="btn btn-default btn-quirk" onClick={this.cancelEditAuditedResult}>
				            Cancel
				          </Button>
				          
				          <Button className="btn btn-primary btn-quirk" onClick={this.handleSubmit}>
				            Save Changes
				          </Button>
		            </div>
			        :
		            <Button className="btn btn-default btn-quirk" onClick={this.handleTasksMappingModelClose}>
			            Close
			          </Button>
			        }
	        	</div>
	          
	        </Modal.Footer>
	      
	      </Modal>

	  	</React.Fragment>
	  )
  }
}

const TasksMappingAuditResult = ({
	inspectable_tasks_mapping,
	handleEditResultClick}) => {

	return (
		<React.Fragment>
    	<div className="audit-result-box" style={{marginRight: 0, "position": "relative"}}>
    		
        <div className="result-item">
          <h4 className="sub-heading">COMMENT</h4>
          <p>{inspectable_tasks_mapping.note == null ? "Not Awailable" : inspectable_tasks_mapping.note}</p>
        </div>

        <ul className="panel-options">
          <li><span className="btn btn-link p-0" onClick={handleEditResultClick}>Edit Result</span></li>
        </ul>

        <div className="result-item">
          <h4 className="sub-heading">SCORE</h4>
          <div>
          	{_.times(inspectable_tasks_mapping.score, function(n){ 
          		return <i className="fa fa-star star-rating font-size-16 active" key={n}></i>
          	 }) }

          	{_.times( (5 - inspectable_tasks_mapping.score), function(n){ 
          		return <i className="fa fa-star star-rating font-size-16" key={n}></i>
          	 }) }

          </div>
        </div>

        <div className="result-item">
          <h4 className="sub-heading">PHOTOS</h4>
          <AttachmentsModel attachable_item={inspectable_tasks_mapping} />
        </div>
      </div>
    </React.Fragment>
	)
}

export default AuditTasksMappingItemForm