import React from 'react';
import { render } from 'react-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { setInspectableTasksMappings } from '../../../duck/actions';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';
import _ from 'underscore';

import InspectableTasksMappings from './inspectable_tasks_mappings';
import AuditTasksMappingItemForm from './audit_tasks_mapping_item_form';

import TasksMappingsLoadingPage from '../common/tasks_mappings_loading_page';

class InspectableTasksMappingsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inspection_id: this.props.match.params.inspection_id,
      query_params: {},
      audit_tasks_mapping_model_show: false,
      selected_tasks_mapping_item: {},
      is_loading: true,
    };

    //this.getInspectableTasksMappings = this.getInspectableTasksMappings.bind(this);
    this.setQueryStringsToState = this.setQueryStringsToState.bind(this);

    this.handleTasksMappingModelShow = this.handleTasksMappingModelShow.bind(this);
    this.handleTasksMappingModelClose = this.handleTasksMappingModelClose.bind(this);

  }

  componentDidMount = async () => {
    await this.setQueryStringsToState();
    //await this.getInspectableTasksMappings();
  }

  setQueryStringsToState = () => {
    const searchStrings = queryString.parse(this.props.location.search);

    this.setState({
      query_params: searchStrings
    })
  }

  handleTasksMappingModelClose = () => this.setState({ audit_tasks_mapping_model_show: false })
  
  handleTasksMappingModelShow = tasks_mapping_item => {

    this.setState({ 
      selected_tasks_mapping_item: tasks_mapping_item,
      audit_tasks_mapping_model_show: true 
    })
  }

  render () {
    if (this.state.is_loading && typeof inspection != undefined ){
      <TasksMappingsLoadingPage />
    }

    return (
      <React.Fragment>
        <InspectableTasksMappings
          {...this.state}
          {...this.props}
          updateSelectedSubSection={this.updateSelectedSubSection}
          handleTasksMappingModelShow={this.handleTasksMappingModelShow}
          />

        {this.state.audit_tasks_mapping_model_show &&
          <AuditTasksMappingItemForm
            {...this.props}
            selected_tasks_mapping_item={this.state.selected_tasks_mapping_item}
            handleTasksMappingModelShow={this.handleTasksMappingModelShow}
            handleTasksMappingModelClose={this.handleTasksMappingModelClose} />
        }
      </React.Fragment>
    )

  }
}

const mapStateToProps = state => {
  return {
    ...state.inspection,
  }
}

const mapDispatchToProps = dispatch => ({
  setInspectableTasksMappings: inspectable_items => dispatch(setInspectableTasksMappings(inspectable_items)),
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(InspectableTasksMappingsContainer);

