import React from 'react'
import { render } from 'react-dom'
import PropTypes from 'prop-types';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import queryString from 'query-string'

import { BeatLoader } from 'react-spinners'

class NewTaskTemplateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    	is_submit_loading: false,
      name: "",
      description: "",
      is_general: false,
      site_id: this.props.site_id,
    };

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.baseState = this.state
  }


  handleChange = event => {
    this.setState({
    	[event.target.name]: event.target.value
      
    });
  }

  handleSubmit = e => {

    const self = this
    const site_id = this.props.site_id
    const formData = new FormData();
    const { name, 
            description, 
            is_general } = this.state

    self.setState({ is_submit_loading: true })

    formData.append('tasks_template[name]', name )
    formData.append('tasks_template[description]', description )
    formData.append('tasks_template[is_general]', is_general )

    setupCSRFToken();

    axios.post(`/sites/${site_id}/tasks_templates`, formData)
    .then(response => {
      const tasks_template = response.data.tasks_template
      
      this.setState(this.baseState)
      self.props.updateTasksTemplates(tasks_template)

    })
    .catch(error => {
      console.log("error catched")
      console.log(error)

      toastr.error( "Please check the inputs and submit the form again.",
      							"An error occurred during the process")
 
    })

    self.setState({ is_submit_loading: false })
    e.preventDefault();
  }

  render(){

    const {
      is_submit_loading,
      is_general,
      name,
      description
    } = this.state
  
  	return(
	  	<React.Fragment>
        <div className="add-new-task-template">
          <h4 className="mt-0">Create New Task Template</h4>
          <form className="js_form"
                onSubmit={this.handleSubmit}>
                      
            <div className="form-group">
              <label className="form-control-label">Template Name</label>
                <input className="form-control" 
                      required="required" 
                      type="text" 
                      name="name"
                      onChange={this.handleChange}
		                  value={name} />
            </div>

            <div className="form-group">
              <label className="form-control-label">Description</label>
                <textarea className="form-control" 
                          name="description"
                          onChange={this.handleChange}
                          value={description}></textarea>
            </div>

            <div className="form-group">
              <label className="toggle">
                <input type="checkbox" 
                        name="is_general" 
                        className="toggle__input"
                        value={is_general}
                        checked={is_general}
                        onChange={(e) => {
                          this.handleChange({
                            target: {
                              name: e.target.name,
                              value: !is_general,
                            },
                          })
                         }} />
                <span className="toggle__label">
                  <span className="toggle__text">&nbsp; Add to Generel Specifications Report <br />
                    <span className="text-muted">&nbsp; This will be shown in Generel spec Report</span>
                  </span>
                </span>
              </label>
              
              
            </div>

            <button className="btn btn-primary btn-inline js_submit_button w-p100"
                    type="submit"
                    disabled={is_submit_loading}>Save</button>

          </form>
        </div>
      </React.Fragment>
	  )
  }
}

export default NewTaskTemplateForm
