import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';
import moment from 'moment'

import Documents from './documents';

class WorkOrderReportHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTitleEditable: false,
      work_order: this.props.work_order,
      documents: this.props.documents,
      current_user_id: this.props.current_user_id,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.baseState    = this.state
  }

  resetForm = () => {
    this.setState(this.baseState)

    this.toggleTitleEditable()
  }

  handleChange = event => {
    this.setState({
      work_order: {
        ...this.state.work_order,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const work_order = this.state.work_order

    console.log(work_order)

    setupCSRFToken();

    axios.put(`/work_orders/${work_order.id}`, { work_order: work_order })
    .then(response => {
      this.setState({
        work_order: response.data,
        isTitleEditable: false,
        isSVChanged: true,
      })

      this.baseState = this.state

      toastr.success("The report has been updated successfully!")
        
    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")
      
    })

  }

  toggleTitleEditable = () => {
    this.setState({
      isTitleEditable: !this.state.isTitleEditable
    })
  }

  render () {

    if (this.state.isTitleEditable) {
      return(
        <form name="WorkOrderHeaderForm" className="work-order-form" onSubmit={this.handleSubmit} >
          <section className="panel-report-header">
            <div className="report-title">
              <h4>Request Title</h4>
              <div className="form-group">
                <input className="form-control input-lg" 
                  type="text" 
                  value={this.state.work_order.title} 
                  onChange={this.handleChange}
                  name="title" />
              </div>
            </div>

            <div className="report-overview">
              <h4>Request Description</h4>
                <div className="form-group">
                  <textarea className="form-control w-p100" 
                    name="description"
                    value={this.state.work_order.description}
                    onChange={this.handleChange} />
                </div>
            </div>

            <div className="report-meta row">
            	<div className="col-md-6">
            		<h4>Priority Level</h4>
	              <div className="form-group">
	                <select name="priority_level" 
	                				value={this.state.work_order.priority_level} 
	                				onChange={this.handleChange}
	                				className="form-control">
				            <option value="low">Low</option>
				            <option value="medium">Medium</option>
				            <option value="high">High</option>
				          </select>
	              </div>
            	</div>

            	<div className="col-md-6">
            		<h4>Date Needed</h4>
	              <div className="form-group">
	                <input className="form-control" 
	                  type="date" 
	                  value={this.state.work_order.date_needed} 
	                  onChange={this.handleChange}
                    placeholder="dd/mm/yyyy"
	                  name="date_needed" />
	              </div>
            	</div>
              	
            </div>

            <div className="form-group mt-10">
              <input type="submit"
                className="pull-right btn btn-primary btn-quirk"
                value="SAVE CHANGES" />
              <button onClick={this.resetForm} className="pull-right btn btn-default btn-quirk mr-5">Cancel</button>
            </div>
          </section>
        </form>
      )
    }

    else {
      return (
        <section className="panel-report-header">
          <div className="report-title">
            <h4 className="text-primary">Report Title</h4>
            <div className="form-group">
              <h3>{this.state.work_order.title}</h3>
            </div>
          </div>

          <div className="report-overview">
            <h4 className="text-primary">Report Summary</h4>
              <div className="form-group">
                <p>{this.state.work_order.description}</p>
              </div>
          </div>

          <div className="report-meta d-flex">
            <div className="w-200">
            	<h4 className="text-primary">Priority Level</h4>
              <div className="form-group">
                <span className={`label label-default font-size-14 label-${this.state.work_order.priority_level}`}
                	style={{textTransform: 'uppercase', fontWeight: 'bold'}}>{this.state.work_order.priority_level}</span>

              </div>
            </div>

            <div>
            	<h4 className="text-primary">Date Needed</h4>
              <div className="form-group">
                <span>{moment(this.state.work_order.date_needed).format('MMMM Do YYYY')}</span>
              </div>
            </div>
          </div>

          <div className="report-attachments">
            <h4 className="text-primary">Attachments</h4>
            <div>
              <Documents
                work_order={this.state.work_order}
                documents={this.state.documents}
                current_user_id={this.props.current_user_id} />
            </div>
          </div>

          <ul className="panel-options" style={{top: '0px'}}>

            <li>
              {(this.state.work_order.user_id === this.props.current_user_id && 
                (this.state.work_order.status === "draft" || this.state.work_order.status === "under_review")) ?
                <a href="javascript:void(0)" onClick={this.toggleTitleEditable}>Edit Title</a>
                : <span className="text-muted">Edit feature is unavailable. Please add a comment instead.</span>
              }</li>
          </ul>
        </section>
      );
    }
    

  }
}

// Show.propTypes = {
//   site: PropTypes.object,
//   site_visit: PropTypes.object,
//   client: PropTypes.object
// };

export default WorkOrderReportHeader
