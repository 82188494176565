import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import { useTable, 
					useFilters, 
					useGlobalFilter, 
					useAsyncDebounce, 
					usePagination } from 'react-table'
import _ from 'underscore'


const  modifySiteDesignations = site_designations => {
  let final_dataset = []

	_.each(site_designations, (value, key) => {
		final_dataset.push({
			site_name: value.site.name,
			//site_address: value.site.full_address,
			//site_logo: value.site.client.avatar.thumb_100x.url,
			//designation_id: value.id,
			//custom_fields: value.custom_fields,
			//allocated_hours: value.allocated_hours,
			display_name: value.display_name,
			//note: value.note,

		})

		return final_dataset
	})

	return final_dataset;
}

// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {

  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <div className="page-search-options mb-20">

      <div className="d-flex justify-content-between">
        <div className="change-per-page">
          <select className="form-control w-100 per-page-select">
          	<option>10</option>
          </select>
        </div>

        <div className="search-options form-group mt-0">
        		
        	<input
		        value={value || ""}
		        onChange={e => {
		          setValue(e.target.value);
		          onChange(e.target.value);
		        }}
		        className="form-control w-200 search-params"
		        placeholder={`Search in ${count} records..`} />
          
        </div>
      </div>
    </div>
  )
}

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, 
  				headerGroups, 
  				rows, 
  				state,
  				prepareRow, 
  				preGlobalFilteredRows,
  				setGlobalFilter } = useTable({
    columns,
    data,
  }, useGlobalFilter)

  // Render the UI for your table
  return (
  	<React.Fragment>
	  	<GlobalFilter
	                preGlobalFilteredRows={preGlobalFilteredRows}
	                globalFilter={state.globalFilter}
	                setGlobalFilter={setGlobalFilter}
	              />
	    <div>Showing the first 20 results of {rows.length} rows</div>

	    <table className="table table-default table-striped table-hover"
	    			 {...getTableProps()}>
	      <thead>
	        {headerGroups.map(headerGroup => (
	          <tr {...headerGroup.getHeaderGroupProps()}>
	            {headerGroup.headers.map(column => (
	              <th {...column.getHeaderProps()}>
	                {column.render('Header')}
	              </th>
	            ))}
	          </tr>
	        ))}
	      </thead>
	      <tbody>
	        {rows.map((row, i) => {
	          prepareRow(row)
	          return (
	            <tr {...row.getRowProps()}>
	              {row.cells.map(cell => {
	                return (
	                  <td {...cell.getCellProps()}>
	                    {cell.render('Cell')}
	                  </td>
	                )
	              })}
	            </tr>
	          )
	        })}
	      </tbody>
	    </table>
	  </React.Fragment>
  )
}


const AssignedSites = ({
		site_designations,
	}) => {

 const columns = React.useMemo(
    () => [
      {	Header: 'Assigned Sites',
        columns: [
          {
            Header: 'Site Name',
            accessor: 'site_name',
          },
          {
            Header: 'Designation',
            accessor: 'display_name',
          },
        ],
      }
    ],
    []
  )

	const data = React.useMemo(() => modifySiteDesignations(site_designations), [])

	return (
    <div>
      <Table columns={columns} data={data} />
    </div>
  )
}


export default AssignedSites;
