import React from 'react'
import PropTypes from 'prop-types';
import toastr from 'toastr';


import ListItem from './_list_item';

class AuditableItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ohs_audit: this.props.ohs_audit,
      ohs_auditable_items: this.props.ohs_auditable_items,
    };

    //this.handleChange = this.handleChange.bind(this);
    //this.handleSubmit = this.handleSubmit.bind(this);
    //this.baseState    = this.state
  }

  // resetForm = () => {
  //   this.setState(this.baseState)

  //   this.toggleTitleEditable()
  // }

  // handleChange = event => {
  //   this.setState({
  //     ohs_audit: {
  //       ...this.state.ohs_audit,
  //       [event.target.name]: event.target.value
  //     }
  //   });
  // }

  handleSubmit = e => {
    e.preventDefault();

    // const site_visit = this.state.site_visit

    // console.log(site_visit)

    // setupCSRFToken();

    // axios.put(`/site_visits/${site_visit.id}`, { site_visit: site_visit })
    // .then(response => {
    //   this.setState({
    //     site_visit: response.data,
    //     isTitleEditable: false,
    //     isSVChanged: true,
    //   })

    //   this.baseState = this.state

    //   toastr.success("The report has been updated successfully!")
        
    // })
    // .catch(error => {
    //   console.log("error catched")
    //   console.log(error.response)

    //   toastr.error("An error occurred during the process")
    // })

  }

  // toggleTitleEditable = () => {
  //   this.setState({
  //     isTitleEditable: !this.state.isTitleEditable
  //   })
  // }

  render () {
  	const {
  		ohs_audit,
  		ohs_auditable_items
  	} = this.state

    return (
    	<React.Fragment>
    		<h4>Auditable Items</h4>
    		<table className="table table-default table-striped table-hover">
		      <thead>
		        <tr>
		          <th>DESCRIPTION</th>
		          <th>YES</th>
		          <th>NO</th>
		          <th>N/A</th>
		        </tr>
		      </thead>

		      <tbody>
		      	{ohs_auditable_items.map((item, index) => (
		      		<ListItem 
		      			item={item} 
		      			key={index} />

		      	)) }

		      </tbody>
		     </table>
	    </React.Fragment>
    )
    

  }
}

export default AuditableItems
