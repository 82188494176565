import React from "react";

const UserMoreDetails = ({
	user,
	handleChange,
	handleSubmit
}) => {
	return (
		<React.Fragment>
			<div className="text-right top-right-text-wrapper pt-20 pr-20">
        <p className="font-size-14">Already have an Account? <a href="/signin">Sign In</a></p>
      </div>

      <div className="p-20 reg-pane-header text-center">                         
        <h3 className="head-title">Fill More Details</h3>
        <h4 className="head-subtitle">You are just 4 simple steps away from account registration.</h4>
      </div>

      <form className="ohs-audit-header-form" onSubmit={handleSubmit} >
      	<div className="row pl-20 pr-20">
          <div className="col-md-6 form-check">
            <label>Gender</label>
            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" />

          </div>

          <div className="col-md-6 form-group">
            <label>Last Name</label>
            <div className="input-group mb-2">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="fa fa-user"></i>
                </div>
              </div>
              
              <input className="form-control" 
	                  type="text" 
	                  value={user.last_name || ""} 
	                  onChange={handleChange}
	                  name="last_name"
                    placeholder="Last Name" />
            </div>
          </div>
        </div>

        <div className="form-group p-20 text-center">
          <hr />
          <button onClick={handleSubmit} className="btn btn-primary btn-login pl-30 pr-30">Next</button>
        </div>
      </form>
			
		</React.Fragment>
	)
}

export default UserMoreDetails;