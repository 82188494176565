import React from 'react';
import PropTypes from 'prop-types';

// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'

const ToastAlert = ({closeToast, message, type, id}) => {

	const className = `alert alert-${type} alert-dismissible alert-top-right`

	return(
		<div className={className} role="alert">
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeToast}>
        <span aria-hidden="true">×</span>
      </button>
      {message}
    </div>
  )
}


class ToastifyFlashMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    	messages: props.messages[0]
    };
  }

  componentDidMount() {
    this.timer = setTimeout(
      this.props.onClose,
      this.props.timeout
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }
  

  render () {
  	const { type, id, message } = this.state.messages
    return(
      <ToastAlert message={message} type={type} id={id} />
    );
  }
}

ToastifyFlashMessages.propTypes = {
  messages: PropTypes.array.isRequired
};

ToastifyFlashMessages.defaultProps = {
  timeout: 5000
};
export default ToastifyFlashMessages