import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import AccountRegistrationParcial from './components/account_registration_partial';
import UserMoreDetails from './components/user_more_details';

class AccountRegistration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: false,
      user: {
      	first_name: "",
      	last_name: "",
      	middle_name: "",
      	email: "",
      	password: "",
      	password_confirmation: "",
      	preferred_name: "",
      	gender: "",
      	dob: "",
      	avatar: "",
      	phone: "",
      	address: "",
      	city: "",
      	state: "",
      	zip: "",
      	em_contact: "",
      	em_contact_relationship: "",
      	em_contact_phone: "",
      },
      current_page: "account_registration"
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }

  handleChange = event => {
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
    	current_page: "more_details"
    })
    console.log("handle Submit clicked")
    // const ohs_audit = this.state.ohs_audit
    // const self = this

    // setupCSRFToken();

    // axios.put(`/ohs_audits/${ohs_audit.id}`, { ohs_audit: ohs_audit })
    // .then(response => {
    //   self.setState({
    //     ohs_audit: response.data.ohs_audit,
    //     isHeaderEditable: false,
    //   })

    //   self.baseState = this.state

    //   toastr.success("The report has been updated successfully!")
        
    // })
    // .catch(error => {
    //   console.log("error catched")
    //   console.log(error.response)

    //   toastr.error("An error occurred during the process")
    // })

  }

  

  render() {

  	const {
  		is_loading,
  		user,
  		current_page
  	} = this.state


  	if (is_loading) {
  		return "Loading..."
  	} else if (current_page == "more_details") {
  		return <UserMoreDetails 
  							handleChange={this.handleChange}
  							handleSubmit={this.handleSubmit}
  							user={user} />
  	} else {
  		return <AccountRegistrationParcial 
  							handleChange={this.handleChange}
  							handleSubmit={this.handleSubmit}
  							user={user} />
  	}

  	// return (
  	// 	<React.Fragment>
  	// 		<div className="text-right top-right-text-wrapper pt-20 pr-20">
   //        <p className="font-size-14">Already have an Account? <a href="/signin">Sign In</a></p>
   //      </div>

   //      <div className="p-20 reg-pane-header text-center">                         
   //        <h3 className="head-title">Account Registration</h3>
   //        <h4 className="head-subtitle">You are just 5 simple steps away from account registration.</h4>
   //      </div>
				
  	// 	</React.Fragment>
	  // )

  }
}


export default AccountRegistration;
