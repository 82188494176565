import React, {useState, useEffect} from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import { Switch, Route } from 'react-router-dom'

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import moment from 'moment-timezone';
import toastr from 'toastr'

import AssignedUserCardItem from '../components/assigned_user_card_item'

const ScheduledJobPreview = ({
  history,
  location,
  match
}) => {

  const [scheduled_job_id, setScheduledJobId] = useState(match.params.scheduled_job_id)
  const [scheduled_job, setScheduledJob] = useState({})
  const [is_late_job, setIsLateJob] = useState({})
  const [site, setSite] = useState({})
  const [assigned_users, setAssignedUsers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchClientDetails()
  },[]);

  const fetchClientDetails = () => {
  
    axios.get(`/scheduled_jobs/${scheduled_job_id}.json`)
    .then(response => {
      setScheduledJob(response.data.scheduled_job)
      setAssignedUsers(response.data.assigned_users)
      setSite(response.data.site)
      setIsLateJob(response.data.is_late_job)
      setIsLoading(false)
      
    })
    .catch(error => {
      console.error(error.message)
    })  
  }

  const toggleJobStatus = status => {
    
    const formData = new FormData();

    formData.append('scheduled_job[status]', status )

    setupCSRFToken();

    axios.put(`/scheduled_jobs/${scheduled_job_id}/toggle_status`, formData)
    .then(response => {

      setScheduledJob(response.data.scheduled_job)
      setIsLateJob(response.data.is_late_job)

      window.location.reload()

    })
    .catch(error => {
      console.log("error catched")
      console.log(error)

      toastr.error("An error occurred during the process")
    
    })

  }

  if(isLoading) {
    return "..."
  } else {

    return (
        <React.Fragment>
          <div className="modal-header">
            <h5 className="modal-title">{scheduled_job.title}</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="model-sub-header p-20" style={{background: '#f6f8fa', border: '1px solid #ebeced'}}>
            <div className="media">
              <div className="media-left">

                <img src={site.client.logo} className="media-object w-100" />
              </div>
              <div className="media-body">
                <h4 className="media-heading font-size-20">{site.name}</h4>
                <p>{site.full_address}</p>
              </div>
            </div>
          </div>

          <div className="modal-body">
            
            <h4 className="mt-0 mb-10" style={{textTransform: 'capitalize'}}>{scheduled_job.job_type}</h4>

            <ul className="panel-options">
              <li>
                { scheduled_job.status == 'scheduled' ?
                  <button className="btn btn-success btn-quirk" onClick={e => toggleJobStatus('complete')}>Mark As Completed</button>
                :
                  <button className="btn btn-default btn-quirk" onClick={e => toggleJobStatus('schedule')}>Mark As Scheduled</button>
                }
              </li>
            </ul>

            <h2>{scheduled_job.title}</h2>

            <div>
              
              { is_late_job &&
                <span className="label label-danger mr-10 ">Due</span>
              }

              <span><strong>Due Date: </strong> {moment(scheduled_job.end_time).tz("Australia/Melbourne").format('LLL')}</span>
            </div>
            <hr style={{borderTop: '1px dotted #d8dae0'}} />

            <div>
              <h4>Additional Notes</h4>
              <p>{scheduled_job.note}</p>
            </div>

            <hr style={{borderTop: '1px dotted #d8dae0'}} />

            <div>
              <h4>Assigned To</h4>
              <ul id="selected_assignees" className="selected_assignees list-group">
                
                {assigned_users.map( (assignee, index) => (
                  <AssignedUserCardItem key={index} 
                                      assignee={assignee} />
                ))}

              </ul>
            </div>

            <hr style={{borderTop: '1px dotted #d8dae0'}} />

          </div>

          <div class="modal-footer" style={{background: '#ffffff', position: 'absolute', bottom: 0, width: '100%', borderTop: '1px solid #ebeced'}}>
        
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <a href={`/scheduled_jobs/${scheduled_job_id}`} className="btn btn-primary">View Full Details</a>
          </div>
        </React.Fragment>
     
      );
  }

}


export default ScheduledJobPreview;
