import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
// import setupCSRFToken from 'config/setupCSRFToken';
// import queryString from 'query-string';

class AdminWidgetsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      clients_summary: {},
      employees_summary: {},
      site_visits: {},
      inspections: {}
    };
  }

  componentDidMount = () => {
  	this.getWidgetDetails()
  }

  getWidgetDetails = () => {
    axios.get(`/admin_widgets_summary.json`)
    .then(response => {
      this.setState({
        clients_summary: response.data.clients_summary,
        employees_summary: response.data.employees_summary,
        inspections: response.data.inspections,
        site_visits: response.data.site_visits,
        is_loading: false,
      })
    })
    .catch(error => {
      console.error(error.message)
      this.setState({
        is_loading: false,
      })
    })
  }

  render() {

    const {
      is_loading,
      clients_summary,
      employees_summary,
      inspections,
      site_visits,
    } = this.state

    if (is_loading) {
      return ""

    } else {
      return (
        <div className="row row-sm mb-20">
          <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
            <div className="widget-card clients-card">
              <div className="d-flex justify-content-between">
                <div className="widget-body">
                  <h6 className="widget-title text-white">CLIENTS</h6>
                  <h4 className="font-size-30 font-weight-200 text-white">{clients_summary.clients}</h4>
                  <p className="mb-0 tx-12 text-white op-7">Total Sites: {clients_summary.total_sites}</p>
                </div>
                <div className="widget-thumb pt-10">
                  <img className="widget-icon w-60" src="/assets/icons/icons8-city.png" alt="" />
                </div>
              </div>

              <hr className="devider mb-20 p-0 "/>
              <div className="card-sub-info">
                <div className="d-flex justify-content-between">
                  <p>ACTIVE SITES:</p>
                  <p>{clients_summary.active_sites}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>ARCHIVED SITES:</p>
                  <p>{clients_summary.archived_sites}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>INSPECTIONS READY:</p>
                  <p>{clients_summary.inspections_ready_sites}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>G. SPECS READY:</p>
                  <p>{clients_summary.gspecs_ready_sites}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
            <div className="widget-card employees-card">
              <div className="d-flex justify-content-between">
                <div className="widget-body">
                  <h6 className="widget-title text-white">Employees</h6>
                  <h4 className="font-size-30 font-weight-200 text-white">{employees_summary.employees}</h4>
                  <p className="mb-0 tx-12 text-white op-7">Active Staff: {employees_summary.employees}</p>
                </div>
                <div className="widget-thumb pt-5">
                  <img className="widget-icon w-60" src="/assets/icons/icons-worker.png" alt="" />
                </div>
              </div>

              <hr className="devider mb-20 p-0 "/>
              <div className="card-sub-info">

                <div className="d-flex justify-content-between">
                  <p>SUPERVISORS:</p>
                  <p>{employees_summary.supervisors}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>CLEANERS:</p>
                  <p>{employees_summary.cleaners}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>CLIENT ACCOUNTS:</p>
                  <p>{employees_summary.client_accounts}</p>
                </div>
              </div>

            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
            <div className="widget-card inspection-card">
              <div className="d-flex justify-content-between">
                <div className="widget-body">
                  <h6 className="widget-title text-white">Inspections</h6>
                  <h4 className="font-size-30 font-weight-200 text-white">{inspections.total_inspections}</h4>
                  <p className="mb-0 tx-12 text-white op-7">Last Month: {inspections.last_month_inspections}</p>
                </div>
                <div className="widget-thumb pt-10">
                  
                  <img className="widget-icon w-60" src="/assets/icons/icons8-agreement.png" alt="" />
                </div>
              </div>


              <hr className="devider mb-20 p-0 "/>
              <div className="card-sub-info">
                <div className="d-flex justify-content-between">
                  <p>COMPLETED THIS MONTH:</p>
                  <p>{inspections.completed_this_month}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>SCHEDULED THIS MONTH:</p>
                  <p>{inspections.scheduled_inspections}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>AVG SCORE:</p>
                  <p>{inspections.avg_score}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-6 col-md-6 col-xm-12">
            <div className="widget-card site-visits-card">
              <div className="d-flex justify-content-between">
                <div className="widget-body">
                  <h6 className="widget-title text-white">Site Visits</h6>
                  <h4 className="font-size-30 font-weight-200 text-white">{site_visits.total_site_visits}</h4>
                  <p className="mb-0 tx-12 text-white op-7">Last Month: {site_visits.last_month_site_visits}</p>
                </div>
                <div className="widget-thumb pt-10">
                  <img className="widget-icon w-60" src="/assets/icons/icons8-inspection.png" alt="" />
                </div>
              </div>

              <hr className="devider mb-20 p-0 "/>
              <div className="card-sub-info">
                <div className="d-flex justify-content-between">
                  <p>COMPLETED THIS MONTH:</p>
                  <p>{site_visits.site_visits_this_month}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>SCHEDULED THIS MONTH:</p>
                  <p>{site_visits.scheduled_site_visits}</p>
                </div>

                <div className="d-flex justify-content-between">
                  <p>AVG. PER MONTH:</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

  	

  	
  }
}


export default AdminWidgetsSummary;
