import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Button, Modal } from 'react-bootstrap';


//import SectionSubSectionsList from './SectionShow'

class SectionsList extends React.Component {
	constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: true,
      section: {},
      model_show: false,
      result: gon.sections,
    };

   console.log(this.props)

    //this.editSection = this.editSection.bind(this);
    //this.deleteSection = this.deleteSection.bind(this);

  }

  componentDidMount() {
  	//this.getSiteSections();
  }

  // getSubSections(section){
  //   this.props.history.push(`${this.props.match.url}/${section.id}`)
  // };

  editSection(section_id){
    console.log(section_id)
  };

  deleteSection(section_id){
    console.log(section_id)
  };

  handleClose() {
    this.setState({ model_show: false });
  }

  handleShow() {
    this.setState({ model_show: true });
  }

  render() {
    const { error, isLoaded, result } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div className="spiner-wrapper"><div className="spinner"></div></div>;
    } else {
      return (
        <div className="sections-list-wrapper">
          <div className="list-header">
            <div className="d-flex justify-content-start">
              
              <h4>Site Sections</h4>
            </div>
          </div>
          <ul className="sections-list">
            {result.map(section => (
              <li key={section.id}>
                <Link key={section.id} to={'/sections/' + section.id + '/sub_sections'}>
                  <i className="fa fa-building"></i> {section.name}
                </Link>

                <div className="list-actions pull-right">
                  <span onClick={this.editSection.bind(this, section.id)} className="text-primary mr-5">Edit</span> |
                  <span onClick={this.deleteSection.bind(this, section.id)} className="text-danger ml-5">Delete</span>
                </div>
              </li>
              
            ))}
            
            <li>
              <div className="add-new-section">
                Create New Section or Building
                <button className="btn btn-primary btn-block" onClick={this.handleShow.bind(this)}><i className="fa fa-plus"></i> Add New</button>
              </div>

              <Modal show={this.state.model_show} onHide={this.handleClose.bind(this)}>
                <Modal.Header closeButton>
                  <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Text in a modal</h4>
                  
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.handleClose.bind(this)}>Close</Button>
                </Modal.Footer>
              </Modal>

            </li>
          </ul>
        </div>
     
      );
    }
  }
}

export default SectionsList;