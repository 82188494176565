import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";
import { Switch, Route } from 'react-router-dom'

import StaffSetup from './setup'
import StaffAssignedSites from './staff_assigned_sites'

const Staffs = () => {
	return (
		<React.Fragment>
			<Route path="/staffs/:desig_id/setup" component={StaffSetup} />
			<Route path="/staffs/:desig_id/assigned_sites" component={StaffAssignedSites} /> 
		</React.Fragment>
	)
}

export default Staffs;
