import React from 'react'

import ListLoader from 'components/common/list_loader'

import InspectableTasksMappings from '../tasks_mappings/inspectable_tasks_mappings_container'
import InspectableSubSectionItem from './inspectable_sub_section_item';
import CompleteLoadingPage from '../common/complete_loading_page';

const InspectableSubSections = props => {

	const {
    inspection,
    is_loading,
	  handleSubSectionItemClick,
    handleSectionItemClick,
    showCompleteSectionButton,
    is_section_completable,
    checkSectionCompletable,
    checkInspectableSectionAudited,
    handleMarkSectionAsCompletedButton
	} = props

  const {
    id,
    inspectable_sub_sections,
    selected_inspectable_section,
    selected_inspectable_sub_section
  } = inspection
  
  if (inspection == null || inspection == undefined){
    return <CompleteLoadingPage />
  }

  return (
    <React.Fragment>
      <h4 className="panel-title mb10">Inspection</h4>
      { selected_inspectable_sub_section.id > 0 &&
        <div className="row no-gutters mobile-header">
          <div className="col-md-12">
            <div className="list-header">
              <div className="d-flex justify-content-start">
                <div className="back-btn">
                  <button className="btn btn-default btn-sm" onClick={() => handleSectionItemClick(selected_inspectable_section)}>Back</button>
                </div>
                <div>
                  <h4 className="m-0 p-5"> Sub Sections</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      
      <div className="row no-gutters">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="panel panel-specifications col-md-12 h-min-500">
            <div className="row">
              <div className={`col-md-4 panel-sp-left pr-md-0 ${selected_inspectable_sub_section.id > 0 ? "hide-on-mobile " : " "} `}>
           
                <div className="sections-list-wrapper">
                  <div className="list-header">
                    <div className="d-flex justify-content-start">
                      <div className="back-btn">
                        <a className="btn btn-default btn-sm" href={`/inspections/${id}/wip`}>Back</a>
                      </div>
                      <div>
                        <h4 style={{margin: "2px"}}> Sub Sections</h4>
                        {selected_inspectable_section && 
                          <p style={{color: "#505b72", margin: 0}}>
                            {selected_inspectable_section.item_name}&nbsp;
                            <span className={`label label-default label-${selected_inspectable_section.status}`}>{selected_inspectable_section.status}</span>
                          </p>
                        }
                      </div>
                    </div>
                  </div>
                  <ul className="sections-list">
                    { is_loading &&
                      <ListLoader times={4}/>
                    }

                    {inspectable_sub_sections.map((inspectable_sub_section, index) => (
                        <SubSectionListItem key={index} 
                          inspectable_sub_section={inspectable_sub_section}
                          selected_inspectable_sub_section={selected_inspectable_sub_section}
                          handleSubSectionItemClick={handleSubSectionItemClick}
                          checkSectionCompletable={checkSectionCompletable}
                          checkInspectableSectionAudited={checkInspectableSectionAudited}
                          show_active_class={false} />

                         
                      ))
                    }
                  

                    {is_section_completable && selected_inspectable_section.status != "completed" &&
                      <li>
                        <p>Finished auditing this section? </p>
                        <button 
                          className="btn btn-primary btn-block btn-quirk"
                          onClick={ () => handleMarkSectionAsCompletedButton()}>Mark as Completed</button>
                      </li>
                    }
                  </ul>
                </div>

              </div>

              <div className="col-md-8 panel-sp-right">
                { selected_inspectable_sub_section &&
                  <InspectableSubSectionItem {...props} />
                }
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

}

const SubSectionListItem = ({
    inspectable_sub_section,
    selected_inspectable_sub_section,
    show_active_class,

    handleSubSectionItemClick }) => {
  
  if (selected_inspectable_sub_section && selected_inspectable_sub_section.id == inspectable_sub_section.id ){
    show_active_class = true
  }

  return (
    <li className={`list-group-x ${show_active_class ? "active" : ""} `}
        onClick={() => handleSubSectionItemClick(inspectable_sub_section) }>
                        
      {inspectable_sub_section.item_name}
      
      <span className={`label label-default label-${inspectable_sub_section.status} pull-right p-5 mr-10`}>{inspectable_sub_section.status}</span>
      <i className=" arrow-icon fa fa-chevron-right"></i>

    </li>
  )
}

export default InspectableSubSections;