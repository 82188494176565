import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import UserPanel from './components/user_panel';
import DocumentsPanel from './components/documents_panel'
import InductionPanel from './components/inductions_panel'
import AssignSitePanel from './components/assign_site_panel'

class NewStaff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      user: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }

  handleChange = event => {
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    const user = this.state.user

    //setupCSRFToken();

    // axios.post(`/registrations/`, { user: user })
    // .then(response => {
    //   this.setState({
    //     comment: response.data,
    //     isCommentEditable: false,
    //   })

    //   this.baseState = this.state

    //   toastr.success("The comment has been updated successfully!")
        
    // })
    // .catch(error => {
    //   console.log("error catched")
    //   console.log(error.response)

    //   toastr.error("An error occurred during the process")
    // })

  }


  render() {

  	return (
  		<React.Fragment>
  			<h4 className="panel-title mb10">Setup New Staff</h4>
				<div className="row">
					<div className="col-md-12 col-lg-10">

						<div className="panel">
							<UserPanel 
								{...this.props}
								user={this.user}
								handleChange={this.handleChange}
								handleSubmit={this.handleSubmit} />

							<DocumentsPanel 
								{...this.props} />

							<InductionPanel 
								{...this.props}/>

							<AssignSitePanel 
								{...this.props}/>

							
						</div>
					</div>
				</div>
  		</React.Fragment>
  		)
  
    
  }
}


export default NewStaff;
