import React, { useState, useEffect } from 'react';

import _ from "underscore";
import moment from "moment";
import DatePicker from "react-datepicker";

const weekDays = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
import ShiftPayVersionFormModel from "../shift_pay_details/form_model";

const ShiftForm = ({
  site_id,
  shiftItem,
  isEdit,
  shift_id,
  submitShiftForm
}) => {

  const weekDays = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"]
  const [shift, setShift]                   = useState(shiftItem);
  const [shiftFrequency, setShiftFrequency] = useState("daily");
  const [fInterval, setFInterval]           = useState(1);
  const [intervalText, setIntervalText]     = useState("Day(s)");
  const [iceCubeRuleType, setIceCubeRuleType] = useState("IceCube::DailyRule")
  const {recurring_days}                    = shiftItem
  const [shiftDays, setShiftDays]           = useState({
                                                monday: _.contains(recurring_days, "monday"),
                                                tuesday: _.contains(recurring_days, "tuesday"),
                                                wednesday: _.contains(recurring_days, "wednesday"),
                                                thursday: _.contains(recurring_days, "thursday"),
                                                friday: _.contains(recurring_days, "friday"),
                                                saturday: _.contains(recurring_days, "saturday"),
                                                sunday: _.contains(recurring_days, "sunday"),
                                              });
  const [noOfShifts, setNoOfShifts] = useState(1)

  useEffect(() => {
    setRecurring();
  }, [shiftDays, fInterval, shiftFrequency]);

  // useEffect( ()=> {
  //   const spv_data = shiftPayVersionData();
    
  // }, [])

  const handleFrequencyChange = value => {
    setShiftFrequency(value)

    if (value == "daily") {
      setIntervalText("Day(s)")
      setIceCubeRuleType("IceCube::DailyRule")
    } else {
      setIntervalText("Week(s)")
      setIceCubeRuleType("IceCube::WeeklyRule")
    }
  }

  const handleShiftDayClick = selectedDay => {

    setShiftDays({ 
      ...shiftDays, 
      [selectedDay]: !shiftDays[selectedDay]}
    )
  }

  const handleChange = event => {
    setShift({
      ...shift,
      [event.target.name]: event.target.value
    })
  }

  const handleRecurringEventItemChange = event => {
    setShift({
      ...shift,
      recurring: {
        ...shift.recurring,
        [event.target.name]: event.target.value
      }
    })
  }

  const setRecurring = () => {
    
    let recurring_days = []

    _.each(weekDays, (key, index) => {
      if (shiftDays[key]) {
        recurring_days.push(index)
      }
      
    })

    let recurring_event = {
                            interval: fInterval,
                            until: null,
                            count: null,
                            validations: {
                              day: recurring_days
                            },
                            rule_type: iceCubeRuleType,
                            week_start: "sunday"
                          }
                          
    setShift({
      ...shift,
      recurring: {
        ...recurring_event
      }
    })

  }

  const handleSubmit = e => {
    e.preventDefault();

    if(_.isEmpty(shift.name)) {
      return false;
    }

    if (isEdit) {
      submitShiftForm(shift)
    } else {
      submitShiftForm(shift, noOfShifts)
    }
  
  }

  const handleChangePayDataFormSubmit = paydata => {
    console.log(paydata)

  }


  if (shift == undefined) {
    <React.Fragment>Loading...</React.Fragment>
  } else {
    const {
      name,
      description,
      commencement_date,
      status,
      total_hours_per_week,
      recurring
    } = shift

    return (
      <React.Fragment>
        <div className="page-body">
          <form onSubmit={handleSubmit}
                id="NewShiftForm"
                method="post">
            <div className="form-inputs">
              <div className="form-group mb-20">
                <label className="form-label" htmlFor="shift_name">Name <span className="text-muted font-size-12">required</span></label>
                  <input className="form-control" 
                          type="text" 
                          name="name" 
                          value={name}
                          required="required"
                          onChange={ e => handleChange(e)}
                          id="shift_name" />
              </div>

              <div className="form-group mb-20">
                <label className="form-label" htmlFor="shift_description">Description</label>
                <textarea className="form-control" 
                          name="description" 
                          value={description}
                          onChange={ e => handleChange(e)}
                          id="shift_description"></textarea>
              </div>


              <div className="row mb-20">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label" htmlFor="shift_commencement_date">Commencement date</label>
                    <DatePicker selected={moment(commencement_date).toDate()} 
                                className="form-control"
                                name="commencement_date" 
                                onChange={(date) => {
                                  handleChange({
                                    target: {
                                      name: "commencement_date",
                                      value: date,
                                    },
                                  })
                                 }} />
                  </div>
                </div>
                <div className="col-md-6">
                  
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-20">
                    <label className="form-label" htmlFor="shift_total_hours_per_week">Total hours per week</label>
                    <input className="form-control" 
                            type="number" 
                            name="total_hours_per_week" 
                            value={total_hours_per_week}
                            onChange={ e => handleChange(e)}
                            id="shift_total_hours_per_week" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group  mb-20">
                    <label className="form-label" htmlFor="no_of_slots">Slots</label>
                    <input className="form-control" 
                            type="text"
                            defaultValue={noOfShifts}
                            disabled={isEdit}
                            value={noOfShifts}
                            onChange={ e => setNoOfShifts(e.target.value)} />
                  </div>
                </div>
              </div>

              <div className="row mb-20">
                <div className="col-md-6">
                  <div className="form-group mb-20 shift_status form-group-valid">
                    <label className="select optional form-label" htmlFor="shift_status">Status</label>
                    <select className="form-control" 
                            name="status" 
                            value={status}
                            onChange={ e => handleChange(e)}
                            id="shift_status">
                      <option value="open">Open</option>
                      <option value="closed">Closed</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="calendar form-group mb-20">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-20">
                      <label className="form-label">Frequency</label>
                      <select className="form-control"
                              defaultValue={shiftFrequency}
                              onChange={e => handleFrequencyChange(e.target.value)}>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">&nbsp;</label>
                    <div className="form-group mb-20 d-flex align-items-center">
                      <label className="form-check-label mr-10">Every</label>
                      <input className="form-control w-50px mr-10" 
                            type="text"
                            defaultValue={fInterval}
                            onChange={ e => setFInterval(e.target.value)} />
                      <label className="form-check-label">{intervalText}</label>
                    </div>
                  </div>
                </div>

                <div className="form-group mb-20">
                  <label className="form-label" htmlFor="shift_status">Select Shift Days</label>
                  <div className="date-grid fw-500">
                    {weekDays.map( (day, index) => {
                      return <span key={index} style={{borderWidth: '2px'}}
                            onClick={ e => handleShiftDayClick(day) }
                            className={`text-uppercase ${shiftDays[day] ? 'active' : ''}`}>{day.substring(0, 2)}</span>

                      } )}  
                  </div>
                  <p className="text-muted fs-12">You can set more details in the next step</p>
                </div>
              </div>

              <div>
                {/* { shift.recurring_days &&
                  <ShiftPayVersionFormModel 
                  shift={shift}
                  shift_pay_version={shiftPayVersionData} 
                  isEdit={true}
                  handleChangePayDataFormSubmit={handleChangePayDataFormSubmit} />
                } */}
              </div>

              <div className="form-actions">
                { isEdit ?
                  <input type="submit" name="commit" value="Save Changes" className="btn btn btn-primary mr-5 btn-quirk" data-disable-with="Save Changes" onClick={handleSubmit} />
                :
                  <input type="submit" name="commit" value="Create Shift" className="btn btn btn-primary mr-5 btn-quirk" data-disable-with="Create Shift" onClick={handleSubmit} />
                }
                <a className="btn btn-default btn-quirk" href={`/shifts?site_id=${site_id}`}>Cancel</a>
              </div>

            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
  
};

// const shiftPayVersionData = shift => {
//   let shift_pay_version = {
//     shift_id: null,
//     paydata: {}
//   }
//   let paydata = {}
//   let paydata_values = {
//                           allocated_hours: "",
//                           start_time: "",
//                           finish_time: "",
//                           unpaid_break: "",
//                           award_rate: "",
//                           shift_rate: ""
//                         }

//   _.each(weekDays, (day, index) => {
//     paydata[day] = paydata_values
//   });

//   shift_pay_version.paydata = paydata

//   return shift_pay_version;
// }

export default ShiftForm