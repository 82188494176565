import React from 'react'
import PropTypes from 'prop-types';
//import $ from 'jquery';
import _ from 'underscore';

// import toastr from 'toastr';
import {Button, Modal, Alert} from 'react-bootstrap';
import { BeatLoader } from 'react-spinners';

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken'
import serialize from 'form-serialize';

class NewCommentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: this.props.site,
      site_visit: this.props.site_visit,
      comment: {
        subject: "Overall Cleanliness",
        section_name: "",
        sub_section_name: "",
        message: "",
      },
      files: null,
      showNewCommentModel: false,
      submitLoading: false,
    };

    this.handleChange       = this.handleChange.bind(this);
    this.handleSubmit       = this.handleSubmit.bind(this);

    this.handleNewCommentModelShow = this.handleNewCommentModelShow.bind(this);
    this.handleNewCommentModelClose = this.handleNewCommentModelClose.bind(this);
  }

  handleNewCommentModelClose() {
    this.setState({ 
      showNewCommentModel: false,
      submitLoading: false,
    });
  }

  handleNewCommentModelShow() {
    this.setState({ showNewCommentModel: true });
  }

  handleChange = event => {
    this.setState({
      comment: {
        ...this.state.comment,
        [event.target.name]: event.target.value
      }
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      submitLoading: true
    })

    const site_visit = this.state.site_visit
    const formData = new FormData();
    const {subject, section_name, sub_section_name, message} = this.state.comment

    formData.append('comment[subject]', subject )
    formData.append('comment[section_name]', section_name )
    formData.append('comment[sub_section_name]', sub_section_name )
    formData.append('comment[message]', message )

    setupCSRFToken();

    axios.post(`/site_visits/${site_visit.id}/add_comment`, formData)
    .then(response => {
      this.props.setNewComment(response.data)

      toastr.success("The Comment has been added successfully!")
      
      this.setState({
        comment: {
          subject: "",
          section_name: "",
          sub_section_name: "",
          message: "",
        },
      })
      this.handleNewCommentModelClose();

    })
    .catch(error => {
      console.log("error catched")
      console.log(error.response)

      toastr.error("An error occurred during the process")

      this.setState({
        submitLoading: false
      })
    
    })
  }

  render () {

    return (
      <div className="comment new-comment">
        <Button variant="primary" className="btn btn-quirk" onClick={this.handleNewCommentModelShow}>
          Add New Comment
        </Button>

        <Modal show={this.state.showNewCommentModel} onHide={this.handleNewCommentModelClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form className="site-visit-form" 
              onSubmit={this.handleSubmit}
              id="NewCommentForm"
              multipart="true"
              encType="multipart/form-data" >

              <CommentSubject reportType={this.state.site_visit.report_type}
                              handleChange={this.handleChange}
                              subject={this.state.comment.subject} />

              <div className="row">
                <div className="form-group col-md-6">
                  <label >Section <span className="text-muted">(Optional)</span></label>
                  <input type="text" 
                          className="form-control" 
                          name="section_name" 
                          placeholder="Eg. Administration Building"
                          onChange={this.handleChange}
                          value={this.state.comment.section_name} />
                </div>

                <div className="form-group col-md-6">
                  <label>Sub Section <span className="text-muted">(Optional)</span></label>
                  <input type="text" 
                          name="sub_section_name" 
                          className="form-control" 
                          placeholder="Eg. Toilets"
                          onChange={this.handleChange}
                          value={this.state.comment.sub_section_name} />
                </div>
              </div>

              <div className="form-group">
                <label>Comment</label>
                <textarea name="message" 
                          className="form-control w-p100 h-min-150"
                          onChange={this.handleChange}
                          value={this.state.comment.message} ></textarea>
              </div>
            </form>
            

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleNewCommentModelClose}>
              Back to Report
            </Button>

            { this.state.submitLoading && !this.state.showCommentAtcUpload ?
              <Button className="pull-right btn btn-primary w-150"
                    disabled>
                <BeatLoader
                  sizeUnit={"px"}
                  size={7}
                  color={'#ffffff'}
                  loading={this.state.submitLoading}
                />
              </Button>

            :
              <Button className="pull-right btn btn-primary w-150" onClick={this.handleSubmit}>
              SAVE CHANGES
              </Button>
            }

            
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const CommentSubject = ({reportType, handleChange, subject}) => {

  if (reportType == "periodical_cleaning_report") {
    return (
      <div className="form-group">
        <label>Task Name</label>
        <input type="text" 
                name="subject" 
                className="form-control" 
                placeholder="External Glass Cleaning"
                onChange={handleChange}
                value={subject} />
      </div>
    )
  }

  else {
    return (
      <div className="form-group">
        <label>Select Issue</label>
        <select className="form-control" name="subject" onChange={handleChange} selected={subject}>
          <option value="Overall Cleanliness">Overall Cleanliness</option>
          <option value="Daily Cleaning Issues Identified">Daily Cleaning Issues Identified</option>
          <option value="Scheduled Follow Up">Scheduled Follow Up</option>
          <option value="Security Issues">Security Issues</option>
          <option value="Maintenance Issues/Fault Reporting">Maintenance Issues/Fault Reporting</option>
          <option value="Service Improvements/ Detail Cleaning">Service Improvements/ Detail Cleaning</option>
          <option value="Cleaning Operative Roster Updates">Cleaning Operative Roster Updates</option>
          <option value="Extra Job">Extra Job</option>
        </select>
      </div>
    )
  }
}

export default NewCommentForm
