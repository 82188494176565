import React from 'react'
import PropTypes from 'prop-types';

import ToolboxMeetingReportHeader from './components/toolbox_meeting_report_header'
import ToolboxMeetingComments from './components/toolbox_meeting_comments'
import ToolboxMeetingAssignees from './components/toolbox_meeting_assignees'

class Show extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      site: this.props.site,
      client: this.props.client,
      toolbox_meeting: this.props.toolbox_meeting,
      comments: this.props.comments,
    };
  }

  render () {
    return (
      <div>
        <div className="panel-body">
          <ToolboxMeetingReportHeader 
            {...this.props} />
        </div>
        <hr />

        <div className="panel-body">
          <ToolboxMeetingAssignees 
            {...this.props} />
        </div>
        <hr />

        <div className="panel-body">
          <ToolboxMeetingComments
            {...this.props} />
        </div>
      </div>
   
    );

  }
}

Show.propTypes = {
  site: PropTypes.object,
  toolbox_meeting: PropTypes.object,
  client: PropTypes.object
};

export default Show
