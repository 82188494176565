import React from 'react'

const DaysInWeeks = (props) => {

  const frequencies = _.isNull(props.frequencies) ? {} : props.frequencies

  const { sunday, monday, tuesday, 
          wednesday, thursday, friday, 
          saturday} = frequencies

  return(
      <span>
        <span className={sunday == '1' ? 'task-frequency active' : 'task-frequency'}>S</span>
        <span className={monday == '1' ? 'task-frequency active' : 'task-frequency'}>M</span>
        <span className={tuesday == '1' ? 'task-frequency active' : 'task-frequency'}>T</span>
        <span className={wednesday == '1' ? 'task-frequency active' : 'task-frequency'}>W</span>
        <span className={thursday == '1' ? 'task-frequency active' : 'task-frequency'}>T</span>
        <span className={friday == '1' ? 'task-frequency active' : 'task-frequency'}>F</span>
        <span className={saturday == '1' ? 'task-frequency active' : 'task-frequency'}>S</span>
      </span>
    )
}

export default DaysInWeeks;