import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

class DocumentsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      user: this.props.user,
    };
    
  }

  componentDidMount = () => {
  	//this.getOhsAudits();
  }

  // getOhsAudits = () => {
  //   const ohs_audit_id = this.props.match.params.ohs_audit_id
    
  //   if (ohs_audit_id != undefined){
  //     axios.get(`/ohs_audits/${ohs_audit_id}/edit.json`)
  //     .then(response => {
  //       this.setState({
  //         ohs_audit: response.data.ohs_audit,
  //         site: response.data.site,
  //         client: response.data.client,
  //         ohs_auditable_items: response.data.ohs_auditable_items,
  //         is_loading: false,
  //       })
        
  //     })
  //     .catch(error => {
  //       console.error(error.message)
  //       this.setState({
  //         is_loading: false,
  //       })
  //     })
  //   }

  // }

  render() {

  	return (
  		<React.Fragment>
  			<hr className="m-0" />
					<div className="panel-heading">
						<div className="d-flex">
							<span className="icon-button font-weight-bold mt-5">2</span>
							<div className="ml-10">
								<h3 className="m-0">Setup Required Documents</h3>
								<p>Set up a list of required documents.</p>

							</div>
						</div>
					</div>
  		</React.Fragment>
  		)
  
    
  }
}


export default DocumentsPanel;
