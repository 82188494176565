import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import axios from 'axios';
import setupCSRFToken from 'config/setupCSRFToken';
import queryString from 'query-string';

import ListLoader from 'components/common/list_loader';

import WorkOrderReportHeader from './components/work_order_report_header'
import WorkOrderComments from './components/work_order_comments'

class WorkOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      work_order: {},
      documents: [],
      current_user_id: null,
      site: {},
      client: {},
      comments: []
    };
    
  }

  componentDidMount = () => {
  	this.getWorkOrder();
  }

  getWorkOrder = () => {
    const work_order_id = this.props.match.params.work_order_id
    
    if (work_order_id != undefined){
      axios.get(`/work_orders/${work_order_id}.json`)
      .then(response => {
        this.setState({
          work_order: response.data.work_order,
          documents: response.data.documents,
          site: response.data.site,
          client: response.data.client,
          comments: response.data.comments,
          current_user_id: response.data.current_user_id,
          is_loading: false,
        })
        
      })
      .catch(error => {
        console.error(error.message)
        this.setState({
          is_loading: false,
        })
      })
    }

  }

  render() {
  	const {
  		work_order,
      documents,
  		site,
  		client,
      current_user_id,
  		comments
  	} = this.state

  	if (this.state.is_loading){
  		return (
  			<div className="panel-body">
  				<ul style={{listStyleType: "none"}}>
  					<ListLoader times={4} />
  				</ul>
  			</div>
  		)
  	}
  	else{
  		return (
	      <div>
	        <div className="panel-body">
	          <WorkOrderReportHeader 
	            {...this.props}
	            work_order={work_order}
              documents={documents}
              current_user_id={current_user_id} />
	        </div>
	        <hr />

	        <div className="panel-body">
	          <WorkOrderComments
	              {...this.props}
	              comments = {comments}
					      site = {site}
					      work_order={work_order}
                current_user_id={current_user_id} />
	        </div>
	      </div>
	   
	    );
  	}
  
    
  }
}


export default WorkOrder;
