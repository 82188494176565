import React from "react";
import { render } from "react-dom";
import PropTypes from "prop-types";

import Chart from 'chart.js';
//import moment from 'moment';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

//import InspectionsBySupervisors from './inspections_by_supervisors';
import axios from 'axios';
import moment from 'moment';

// import setupCSRFToken from 'config/setupCSRFToken';
// import queryString from 'query-string';

class ReportsSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
 			inspections_data: [],
 			inspections_chart_data_set: [],
 			site_visits_chart_data_set: [],
 			site_visits_data: [],
 			sorted_date_range: []
    };
   
  }

  componentDidMount = async () => {
  	await this.fetchReportsData();
  	await this.setChartDataSet();
  	await this.createChart();
  }

  fetchReportsData = async () => {
  	const response = await axios.get(`/reports_summary.json`)

    this.setState({
      inspections_data: response.data.inspections,
      site_visits_data: response.data.site_visits,
      is_loading: false,
    })

  }

  setChartDataSet = () => {
  	const {inspections_data, site_visits_data } = this.state

  	let _sorted_date_range = []
  	let inspections_chart_data_set = []
  	let site_visits_chart_data_set = []

  	inspections_data && inspections_data.forEach( (inspecItem, i) => {
			_sorted_date_range.push( moment(inspecItem.month_name).format('MMMM YYYY'))
			inspections_chart_data_set.push(inspecItem.number_of_inspections)
		})

		site_visits_data && site_visits_data.forEach( (SiteVisitItem, i) => {
			site_visits_chart_data_set.push(SiteVisitItem.number_of_site_vists)
		})

		this.setState({
			inspections_chart_data_set: inspections_chart_data_set,
			site_visits_chart_data_set: site_visits_chart_data_set,
			sorted_date_range: _sorted_date_range,
		})

  }

  createChart = () => {
		const ctx = document.getElementById('reports_summary_chart');

		const {
			sorted_date_range,
			inspections_chart_data_set,
			site_visits_chart_data_set
		} = this.state

		console.log(sorted_date_range)

		const timeTrachking1 = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: sorted_date_range,
					datasets: [
						{
							label: 'Inspections',
	            data: inspections_chart_data_set,
	            backgroundColor: '#046fe7',
	            borderColor: '#046fe7',
	            borderWidth: 1,
	            barThickness: 8
						},

						{
							label: 'Site Visits',
	            data: site_visits_chart_data_set,
	            backgroundColor: '#f93a5a',
	            borderColor: '#f93a5a',
	            borderWidth: 1,
	            barThickness: 8
						}
					],
				},
				//plugins: [ChartAnnotation],
				options: {
					responsive: true,
					plugins: {
			      legend: {
			        position: 'top',
			        labels: {
						        	boxWidth: 10,
						        	boxHeight: 10,
						        }
			      }
			    },
					scales: {
						yAxes: [{
							
							scaleLabel: {
								display: true,
								labelString: 'Total Reports'
							},
							ticks: {
								beginAtZero: true,
								//max: this.state.chart_max_value,
                min: 0,
                stepSize: 5,
							},
						}],
						xAxes: [{
							scaleLabel: {
								display: true,
								//labelString: 'Months'
							},
						}]
					},
					title: {
			      display: false,
			      //text: 'Graphical presentation of Time Tracking details'
			    },
				},
				
		});
	}

  render() {

  	return (
  		<div className="panel">
			  <div className="panel-heading">
			    <h4 className="panel-title">Reports Summary</h4> 
			    <p className="tx-12 text-muted mb-0">Inspection & Site Visit Reports summary for last 12 months.</p>
			  </div>

			  <div className="panel-body">
			  	<canvas id="reports_summary_chart" className="w-p100"></canvas>
			  </div>
			</div>
		)

  	
  }
}


export default ReportsSummary;
